import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  Container,
  Input,
  Spinner
} from "reactstrap";
import React from "react";
import DataTabelComponent from "../../components/tables";
import { useState } from "react";
import { useEffect } from "react";
import classnames from "classnames";
import DelIcon from "../../images/editDelete/del.svg";
import EditIcon from "../../images/editDelete/edit.svg";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { getOrdersFunc } from "../../ApIs/orders";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import "./css/createOrder.css";
import moment from "moment";

const InprogressOrders = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("assign");
  const [selectedOrders, setSelectedOrders] = useState();
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static4, setModal_static4] = useState(false);
  const [modal_static5, setModal_static5] = useState(false);
  const [modal_static6, setModal_static6] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [modal_static8, setModal_static8] = useState(false);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [orderId, setOrderId] = useState("");
  const token = localStorage.getItem("token");
  const [ordersDate, setOrdersDate] = React.useState("");


  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px"
    },
    {
      name: "Created Date",
      selector: (row, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{row?.createdAt?.split("T")[0]}</span>
          <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>
          <span>{moment(row?.createdAt).fromNow()}</span>
        </div>
      ),
    },
    {
      name: "Order Id",
      selector: (row) => row.orderId,
    },
    {
      name: "Internal Reference No",
      selector: (row) => row.internalReferenceNumber,
    },
    {
      name: "Name",
      selector: (row) => row.contactName,
    },
    {
      name: "Item Type",
      selector: (row) => row.itemType,
    },
    {
      name: "Item No.",
      selector: (row) => row.itemCount,
    },
    {
      name: "Phone",
      selector: (row) => row.contactPhoneNumber,
    },
    {
      name: "Email",
      selector: (row) => row.contactEmail,
    },
    {
      name: "Address",
      selector: (row) => row?.address?.street,
    },
    {
      name: "Suburb",
      selector: (row) => row?.address?.suburb,
    },
    {
      name: "State",
      selector: (row) => row?.address?.state,
    },
    {
      name: "Post Code",
      selector: (row) => row?.address?.postalCode,
    },
    {
      name: "Description",
      selector: (row) => row.year,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Link to={`/edit_order/${row?._id}`}>
            {" "}
            <img src={EditIcon} />
          </Link>
          <span
            onClick={() => {
              setModal_static8(true);
              setOrderId(row?._id);
            }}
          >
            <img src={DelIcon} />
          </span>
        </>
      ),
    },
  ];

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };
  const tog_static5 = () => {
    setModal_static5(!modal_static5);
    removeBodyCss();
  };

  const tog_static6 = () => {
    setModal_static6(!modal_static6);
    removeBodyCss();
  };

  const tog_static8 = () => {
    setModal_static8(!modal_static8);
    removeBodyCss();
  };

  const durationOptions = [
    { value: '2-3', label: "2-3 Hours" },
    { value: '3-4', label: "3-4 Hours" },
    { value: '4-5', label: "4-5 Hours" },
  ];

  const timeOptions = [
    { value: "08:00 A.M", label: "08:00 A.M" },
    { value: "09:00 A.M", label: "09:00 A.M" },
    { value: "10:00 A.M", label: "10:00 A.M" },
    { value: "11:00 A.M", label: "11:00 A.M" },
    { value: "12:00 P.M", label: "12:00 P.M" },
    { value: "13:00 P.M", label: "13:00 P.M" },
    { value: "14:00 P.M", label: "14:00 P.M" },

  ];

  const actionOptions = [
    { value: "delete", label: "Delete Selected" },
  ]

  const actionsChangeFunc = (e) => {
    if(e.value === "delete"){
      setModal_static5(true);
    } else if (e.value === "assign"){
      setModal_static6(true);
    }
  }

  // *************
  const AllOrders = useQuery(["AllOrders", status, ordersDate], () => getOrdersFunc(token, status, ordersDate));
  const AllOrdersData = AllOrders?.data?.data;
  // *************

  useEffect(() => {
    queryClient.invalidateQueries("AllOrders");
  }, [])

  const [createShiftLoading, setCreateShiftLoading] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [duration, setDuration] = React.useState("");
  const [date, setDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("");

  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  const handleChange = (state ) => {
    const orders = state?.selectedRows?.map((order) => order._id)
    setSelectedRows(orders);
  };


  const CreateShiftFunc = () => {
    setCreateShiftLoading(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/createShift`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        orders: selectedRows,
        address: {
          postalCode: 2125,
          state: "NSW",
          suburb: "West Pennant Hills",
          street: "16 Royal Oak Pl"
        },
        duration: duration,
        startTime: startTime,
        date: date
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          setCreateShiftLoading(false);
          addToast("Shift Created Successfully", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static8(false);
          setModal_static4(false)
          // navigate("/manage_shifts");
          window.location.href = "/#/manage_shifts";
          queryClient.invalidateQueries("AllShifts");

        } else if (result3.status === 204) {
          setCreateShiftLoading(false);
          addToast("Shift Created Successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllShifts");
        } else {
          setCreateShiftLoading(false);
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) => {
        setCreateShiftLoading(false);
        setModal_static8(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      }
      );
  };


  const DelOrderFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/${orderId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static8(false);
          // queryClient.invalidateQueries("AllOrders");
          setTimeout(() => {
            window.location.reload(false);
          }, 500)

        } else if (result3.status === 204) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const DeleteOrdersFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/deleteOrders`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        orders: selectedRows,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          // queryClient.invalidateQueries("AllOrders");
          
          addToast("Orders Successfully Deleted, orders will be removed", {
            appearance: "success",
            autoDismiss: true,
          });
          
          setModal_static5(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 500)
        } else if (result3.status === 204) {
          // addToast("Orders Successfully Deleted", {
          //   appearance: "success",
          //   autoDismiss: true,
          // });
          setModal_static5(false);
          queryClient.invalidateQueries("AllOrders");
        } else {
          queryClient.invalidateQueries("AllOrders");
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static5(false);
        }
      })
      .catch((error) =>{
        setModal_static5(false)
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
        queryClient.invalidateQueries("AllOrders");
      }
      );
  };

  useEffect(() => {
    const result = AllOrdersData?.orders?.filter((e) => {
      return (
        e?.orderId?.toLowerCase().match(search.toLowerCase()) ||
        e?.contactName?.toLowerCase().match(search.toLowerCase()) ||
        e?.internalReferenceNumber?.toLowerCase().match(search.toLowerCase())
        // e?.internalReferenceNumber?.match(search)
      );
    });
    setFilterTable(result);
  }, [search]);

  const dateOptions = [
    { value: "25hrs", label: "Last 24 Hours" },
    { value: "3days", label: "Last 3 Days" },
    { value: "7days", label: "Last 7 Days" },
    { value: "15days", label: "Last 15 Days" },
    { value: "30days", label: "Last 30 Hours" },
    // { value: "custom", label: "Custom Date Range" },
  ]

  return (
    <div>
       {
        modal_static8 && (
        <div className="modalMask">
          <div className="modalWrapper-delete">
            <div className="circle">!</div>
            <div className="delete-modal-body">
              <h3>Are You Sure?</h3>
              <p>If you proceed, you will lose all your personal data. Are you sure you want to delete this order.</p>
            </div>
            <div className="delete-modal-btns-wrapper">
              <button onClick={DelOrderFunc}>Confirm</button>
              <button onClick={() => setModal_static8(false)}>Not Now</button>
            </div>
          </div>
        </div>
        )
        }
      <div className="p-4">
        <DataTabelComponent
          title=""
          columns={columns}
          progressPending={AllOrders.isLoading}
          data={filterTable ? filterTable : AllOrdersData?.orders}
          pagination={true}
          fixedHeader={false}
          fixedHeaderScrollHeight="1000px"
          selectableRows={true}
          onSelectedRowsChange={handleChange}
          clearSelectedRows={toggledClearRows}
          selectableRowsHighlight={true}
          highlightOnHover={true}
          subHeader={true}
          subHeaderComponent={
            <div className="w-100">
              <Row className="mb-2 mt-2">
              
              <Col lg={4} md={4} sm={4}>
                <span className="">
                  <Select
                    className="c_o_select_css2 mx-2"
                    options={dateOptions}
                    placeholder="Choose Date Range"
                    onChange={(e) => setOrdersDate(e.value)}
                  ></Select>
                </span>
              </Col>
                <Col lg={3} md={3} sm={3} style={{ display: "flex" }}>
                  
                  <span className="px-2">
                    <Select
                      isDisabled={selectedRows.length === 0}
                      className="c_o_select_css2 mx-auto"
                      options={actionOptions}
                      placeholder="Bulk Actions"
                      onChange={(e) => actionsChangeFunc(e)}
                    ></Select>
                  </span>
                </Col>
                <Col lg={3} md={3} sm={3}>
                <span>
                  <input
                    type="text"
                    placeholder="search by id | name | phone"
                    className="form-control"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </span>
              </Col>
                 
              </Row>
            </div>
          }
        />

        <div>
          <Modal isOpen={modal_static4} toggle={tog_static4} centered={true}>
            <ModalHeader >
              <Container fluid>
                <Row>
                  <Col md={12}>
                    {" "}
                    <p className="mt-3">Create Shift</p>
                  </Col>
                </Row>
              </Container>
            </ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <Select
                      options={durationOptions}
                      className="c_o_select_css"
                      placeholder="Choose Shift Duration"
                      onChange={(e) => setDuration(e.value)}
                    ></Select>
                  </Col>
                </Row>
                {/* <Row className="mt-3">
                  <Col md={12}>
                    <Select
                      options={timeOptions}
                      className="c_o_select_css"
                      placeholder="Customize Shift Duration"
                    ></Select>
                  </Col>
                </Row> */}
                <Row className="mt-3">
                  <Col md={6}>
                    <Input
                      className="c_o_select_css"
                      type="date"
                      min={new Date().toISOString().split('T')[0]}
                      placeholder="Select Date"
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      options={timeOptions}
                      className="c_o_select_css"
                      placeholder="Starting Time"
                      onChange={(e) => setStartTime(e.value)}

                    ></Select>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col md={3}>
                    <Button
                      disabled={createShiftLoading || selectedRows.length === 0 ||  !startTime ||!date || !duration}
                      color="danger"
                      className="w-100"
                      onClick={() => CreateShiftFunc()}
                      // onClick={() => {
                      //   window.location.reload(true);
                      // }}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      {
                            createShiftLoading ? <Spinner color="light" size="sm" /> : "Create"
                      }
                        
                      </p>
                    </Button>
                  </Col>
                  <Col md={3}></Col>
                  <Col md={3}></Col>
                  <Col md={3}>
                    <Button
                      color="danger"
                      outline
                      className="w-100 "
                      onClick={() => setModal_static4(false)}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Cancel
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div>
        <Modal isOpen={modal_static5} toggle={tog_static5} centered={true}>
        <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">Do you really want to delete {selectedRows.length} orders?</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <div className="suspend-driver-popup">
              <div className="suspend-driver-btns-wrapper">
               
                  <Button
                    color="success"
                    outline
                    className="c_o_btns skip_shift_btn"
                    onClick={DeleteOrdersFunc}
                  >
                    Yes
                  </Button>
             
                  <Button
                    color="success"
                    outline
                    className="c_o_btn1 skip_shift_btn"
                    onClick={() => setModal_static5(false)}
                  >
                    Go Back
                  </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal_static6} toggle={tog_static6} centered={true}>
        <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">Assign Orders to shifts</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <div className="suspend-driver-popup">
              <div className="suspend-driver-btns-wrapper">
                <Link to="/create_shift">
                  <Button
                    color="success"
                    outline
                    className="c_o_btns skip_shift_btn"
                  >
                    Create New Shifts
                  </Button>
                </Link>
              
                  <Button
                    color="success"
                    outline
                    className="c_o_btn1 skip_shift_btn"
                  >
                    Select From Existing Shifts
                  </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default InprogressOrders;
