import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";

import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import {firebaseDB} from "../../firebase";
import {set, ref, onValue} from "firebase/database";

import { getShiftFunc } from "../../ApIs/shifts";

import TrackingIcon from "../../images/icons/tracking.png"; 
import WearHouseIcon from "../../images/icons/wearhouse.png"; 


const token = localStorage.getItem("token");


function Map() {
  const [driverLocation, setDriverLocation] = useState([]);
  const [driverAvailable, setDriverAvailable] = useState(false);

  const params = useParams();
  const Shift = useQuery(["singleShifts"], () => getShiftFunc(token, params.id));



  // useEffect(() => {
  //   if (Shift.data) {
  //     set(ref(firebaseDB, `shifts/${Shift?.data?.data?.assignedTo}`), {
  //       lat: '-33.738071',
  //       lng: '150.782944',
  //     });
  //   }
  // }, [Shift])

  useEffect(() => {
    onValue(ref(firebaseDB, `shifts/${Shift?.data?.data?.assignedTo}`), (snapshot) => {
      const data = snapshot.val();
      if(data !== null){
        setDriverLocation([data.lat, data.lng]);
        setDriverAvailable(true);
      }else{
        setDriverAvailable(false);
      }
    });
  }, [Shift?.data?.data?.assignedTo]);
  

  useEffect(() => {
  }, [])

  const markers = Shift?.data?.data?.orders?.map((order) => {
    return {
      id: order?._id,
      status: order?.status === "delivered" ? true : false,
      name:
        order?.address.street +
        " " +
        order?.address.suburb +
        "," +
        order?.address.state,
      position: {
        lat: order?.address?.coordinates  ? order?.address?.coordinates[1] : 0,
        lng: order?.address?.coordinates ? order?.address?.coordinates[0] : 0
      },
    };
  });

  const center = markers ? markers[0]?.position : {};


  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach(({ position }) => bounds.extend(position));
    map?.fitBounds(bounds);
  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100vw", height: "60vh" }}
      zoom={13}
      // center={!center ? center : {lat: +(driverLocation[0]), lng: +(driverLocation[1])}}

      // center={!driverLocation ? {lat: -33.738071, lng: 150.782944} : {lat: +(driverLocation[0]), lng: +(driverLocation[1])}}
      center={driverLocation.length === 0 ? {lat: -33.738071, lng: 150.782944} : {lat: +(driverLocation[0]), lng: +(driverLocation[1])}}
      options={{
        gestureHandling: "greedy"
      }}
    >
      {markers?.map(({ id, name, position, status }, index) => (
        <MarkerF
          key={id}
          position={position}
          // label={index.toString()}
          onClick={() => handleActiveMarker(id)}
          icon={status ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png" : "http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindow>
          ) : null}
        </MarkerF>
      ))}
      {
        driverAvailable ? (
          <MarkerF
            position={{ lat: +(driverLocation[0]), lng: +(driverLocation[1]) }}
            icon={TrackingIcon}
          />
        ) : null
      }
      {
       markers ? (
          <MarkerF
            position={{ lat: Shift?.data?.data?.homeCoordinates[1], lng: Shift?.data?.data?.homeCoordinates[0] }}
            icon={WearHouseIcon}
          />
        ) : null
      }
      
    </GoogleMap>
  );
}

export default React.memo(Map);
