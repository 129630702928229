import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  Input,
  Container,
  Card,
  Label,
  Spinner,
} from "reactstrap";
import React from "react";
import { useState } from "react";
import EditIorder_box_emptycon from "../../images/icons/order_box_empty.svg";
import UploadBtn from "../../images/icons/fileUpload.png";
import { useToasts } from "react-toast-notifications";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import "./css/createOrder.css";
import { useQueryClient } from "react-query";
import { useEffect } from "react";

const CreateOrder = () => {
  const queryClient = useQueryClient();
  const [createOrderLoading, setCreateOrderLoading] = useState(false);
  const [modal_static4, setModal_static4] = useState(false);
  const [modal_static5, setModal_static5] = useState(false);
  const [modal_static6, setModal_static6] = useState(false);
  const [modal_static7, setModal_static7] = useState(false);
  const [createOrderName, setCreateOrderName] = useState("");
  const [createOrderPhoneNumber, setCreateOrderPhoneNumber] = useState(null);
  const [createOrderEmail, setCreateOrderEmail] = useState("");
  const [createOrderStreet, setCreateOrderStreet] = useState("");
  const [createOrderPostalCode, setCreateOrderPostalCode] = useState(null);
  const [createOrderSuburb, setCreateOrderSuburb] = useState("");
  const [createOrderState, setCreateOrderState] = useState("");
  const [items, setItems] = useState([]);
  const [fileName, setFileName] = useState("");
  const { addToast } = useToasts();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static5 = () => {
    setModal_static5(!modal_static5);
    removeBodyCss();
  };
  const tog_static6 = () => {
    setModal_static6(!modal_static6);
    removeBodyCss();
  };

  const tog_static7 = () => {
    setModal_static7(!modal_static7);
    removeBodyCss();
  };

  const createOrdersFunc = () => {
    setCreateOrderLoading(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/createOrders`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(items),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          
          // navigate('/recent_unassigned_orders', { state: result?.data})
          
          setTimeout(() => {
            setCreateOrderLoading(false);
            window.location.href = "/#/recent_unassigned_orders";
            queryClient.invalidateQueries("AllOrders");

          }, 5000)
          // window("/mannage_all_orders");
        } else {
          setCreateOrderLoading(false);
          // addToast(result3?.error, {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
        }
      })
      .catch((error) => {
        setModal_static4(false);
        addToast("Error: ", error, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, {
          header: [
            "item1",
            "item2",
            "item3",
            "item4",
            "item5",
            "item6",
            "item7",
            "item8",
            "item9",
            "item10",
            "item11",
            "item12",
            "item13",
            "item14",
            "item15",
            "item16",
            "item17",
            "item18",
          ],
          range: 1,
        });

        resolve(data);
        setFileName(file);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      const data = d.map((item) => {
        return {
          contactName: item.item1,
          contactEmail: item.item15,
          contactPhoneNumber: item.item8,
          description: item.item14,
          notes: item.item12,
          internalReferenceNumber: item.item11,
          itemType: item.item9,
          itemCount: item.item10,
          address: {
            street: item.item4,
            suburb: item.item5,
            state: item.item6,
            postalCode: item.item7,
          },
        };
      });
      setItems(data);
    });
  };


  const createManualOrdersFunc = () => {
    const apiObject = {
      contactPhoneNumber: Number(createOrderPhoneNumber),
      contactEmail: `${createOrderEmail}`,
      contactName: `${createOrderName}`,
      address: {
        postalCode: createOrderPostalCode,
        state: `${createOrderState}`,
        suburb: `${createOrderSuburb}`,
        street: `${createOrderStreet}`,
      },
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/createOrder`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObject),
    })
      .then((response) => response.json())
      .then((result3) => {
        if (result3?.message === "Order created successfully") {
          addToast("Successfully Created", {
            appearance: "success",
            autoDismiss: true,
          });
          queryClient.invalidateQueries("AllOrders");
          setModal_static5(false);
          window.location.href = "/#/recent_unassigned_orders";
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        setModal_static5(false);
        addToast("Error: ", error, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div className="orders-wrapper">
      <div className="add-orders-main">
        <div className="add-orders-wrapper">
          <div className="order-image-wrapper">
            <img src={EditIorder_box_emptycon} alt="order-img" />
          </div>
          <h1>Add New Orders</h1>
          <h5>Choose any of the below option to add new order/orders.</h5>
          <div className="add-orders-btns-wrapper">
          <Button
              color="success"
              outline
              className="c_o_btns"
              onClick={tog_static5}
            >
              Create Manually
            </Button>
            <Button
              color="success"
              outline
              className="c_o_btn1"
              onClick={tog_static6}
            >
              Import CSV File
            </Button>

            <Button
              color="success"
              outline
              className="c_o_btns"
              onClick={tog_static7}
              disabled
            >
              Get From Web
            </Button>

        </div>
        </div>
      </div>
      <div>
        <Modal isOpen={modal_static5} toggle={tog_static5} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3 m_d_popup_header_font">Create Manually</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <p className="m_d_popup_font" style={{ color: " #817B7B" }}>
                    Name
                  </p>
                  <Input
                    
                    onChange={(e) => {
                      setCreateOrderName(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    Phone Number
                  </p>
                  <Input
                    type="number"
                    
                    onChange={(e) => {
                      setCreateOrderPhoneNumber(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    Email
                  </p>
                  <Input
                    type="email"
                    
                    onChange={(e) => {
                      setCreateOrderEmail(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    State
                  </p>
                  <Input
                    
                    onChange={(e) => {
                      setCreateOrderState(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    Suburb
                  </p>
                  <Input
                    
                    onChange={(e) => {
                      setCreateOrderSuburb(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    Postal Code
                  </p>
                  <Input
                    type="number"
                    
                    onChange={(e) => {
                      setCreateOrderPostalCode(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    Street
                  </p>
                  <Input
                    
                    onChange={(e) => {
                      setCreateOrderStreet(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <Button
                    color="danger"
                    className="w-100"
                    onClick={createManualOrdersFunc}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Save
                    </p>
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    // color=""
                    outline
                    className="w-100 "
                    onClick={() => setModal_static5(false)}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Cancel
                    </p>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal_static6} toggle={tog_static6} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">Upload</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <p className=" mt-3">{`Import Excel file   :  ${
                    fileName.name ? fileName.name : "No File Attached"
                  }`}</p>
                  <div class="wrapper">
                    <img
                      src={UploadBtn}
                      className="btnimg"
                      alt="fileupload-img"
                    ></img>

                    <Input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                      className="input-file"
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        readExcel(file);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mt-4 mb-1">
                <Col md={3}>
                  <Button
                    disabled={createOrderLoading || fileName === ""}
                    color="danger"
                    className="w-100"
                    onClick={createOrdersFunc}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      {createOrderLoading ? (
                        <Spinner color="light" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </p>
                  </Button>
                </Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <Button
                    color="danger"
                    outline
                    className="w-100 "
                    onClick={() => {
                      setModal_static6(false);
                      setFileName("");
                    }}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Cancel
                    </p>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal_static7} toggle={tog_static7} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3 m_d_popup_header_font">Get From Web</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <p className="m_d_popup_font mt-3">Import From Web</p>
                  <Input placeholder="Enter Driver Adress" />
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md={3}>
                  <Button
                    disabled={createOrderLoading}
                    color="danger"
                    className="w-100"
                    onClick={() => {
                      window.location.reload(true);
                    }}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      {createOrderLoading ? (
                        <Spinner color="light" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </p>
                  </Button>
                </Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <Button
                    color="danger"
                    outline
                    className="w-100 "
                    onClick={() => setModal_static7(false)}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Cancel
                    </p>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default CreateOrder;
