import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  Container,
  Input,
  Spinner,
} from "reactstrap";
import React from "react";
import DataTabelComponent from "../../components/tables";
import { useState } from "react";
import { useEffect } from "react";
import classnames from "classnames";
import DelIcon from "../../images/editDelete/del.svg";
import EditIcon from "../../images/editDelete/edit.svg";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { getOrdersFunc } from "../../ApIs/orders";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import "./css/createOrder.css";
import moment from "moment";

const AssignedOrders = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("assign");
  const [selectedOrders, setSelectedOrders] = useState();
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static4, setModal_static4] = useState(false);
  const [modal_static5, setModal_static5] = useState(false);
  const [modal_static6, setModal_static6] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [modal_static8, setModal_static8] = useState(false);
  const [ordersDate, setOrdersDate] = React.useState("");

  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [orderId, setOrderId] = useState("");
  const token = localStorage.getItem("token");

  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Created Date",
      selector: (row, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{row?.createdAt?.split("T")[0]}</span>
          <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>
          <span>{moment(row?.createdAt).fromNow()}</span>
        </div>
      ),
    },
    {
      name: "Order Id",
      selector: (row) => row.orderId,
    },
    {
      name: "Internal Reference No",
      selector: (row) => row.internalReferenceNumber,
    },
    {
      name: "Name",
      selector: (row) => row.contactName,
    },
    {
      name: "Item Type",
      selector: (row) => row.itemType,
    },
    {
      name: "Number of Item",
      selector: (row) => row.itemCount,
    },
    {
      name: "Address",
      selector: (row) => row?.address?.street,
    },
    {
      name: "Suburb",
      selector: (row) => row?.address?.suburb,
    },
    {
      name: "State",
      selector: (row) => row?.address?.state,
    },
    {
      name: "Post Code",
      selector: (row) => row?.address?.postalCode,
    },
    {
      name: "Status",
      selector: (row) => (row.status === "assign" ? "Assigned" : "Unassigned"),
    },
    {
      name: "Description",
      selector: (row) => row.year,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Link to={`/edit_order/${row?._id}`}>
            {" "}
            <img src={EditIcon} />
          </Link>
          <span
            onClick={() => {
              setModal_static8(true);
              setOrderId(row?._id);
            }}
          >
            <img src={DelIcon} />
          </span>
        </>
      ),
    },
  ];

  const dateOptions = [
    { value: "1hrs", label: "Last Hour" },
    { value: "24hrs", label: "Last 24 Hours" },
    { value: "3days", label: "Last 3 Days" },
    { value: "7days", label: "Last 7 Days" },
    { value: "15days", label: "Last 15 Days" },
    { value: "30days", label: "Last 30 Days" },
    // { value: "custom", label: "Custom Date Range" },
  ];

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };

  const tog_static5 = () => {
    setModal_static5(!modal_static5);
    removeBodyCss();
  };

  const tog_static6 = () => {
    setModal_static6(!modal_static6);
    removeBodyCss();
  };

  const tog_static8 = () => {
    setModal_static8(!modal_static8);
    removeBodyCss();
  };

  // *************
  const AllOrders = useQuery(["AllOrders", status, ordersDate], () =>
    getOrdersFunc(token, status, ordersDate)
  );
  const AllOrdersData = AllOrders?.data?.data;
  // *************

  useEffect(() => {
    queryClient.invalidateQueries("AllOrders");
  }, []);

  const [createShiftLoading, setCreateShiftLoading] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [duration, setDuration] = React.useState("");
  const [date, setDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("");

  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  const handleChange = (state) => {
    const orders = state?.selectedRows?.map((order) => order._id);
    setSelectedRows(orders);
  };

  const DelOrderFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/${orderId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static8(false);
          // queryClient.invalidateQueries("AllOrders");
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else if (result3.status === 204) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const DeleteOrdersFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/deleteOrders`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        orders: selectedRows,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          addToast("Orders Successfully Deleted, orders will be removed", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static5(false);
          // queryClient.invalidateQueries("AllOrders");
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else if (result3.status === 204) {
          addToast("Orders Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static5(false);
          // queryClient.invalidateQueries("AllOrders");
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static5(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) => {
        setModal_static5(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        });
        queryClient.invalidateQueries("AllOrders");
      });
  };

  const RemoveOrdersFromShiftFunc = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/orders/shift/removeFromShift`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          orders: selectedRows,
        }),
      }
    )
      .then((result3) => {
        if (result3.status === 200) {
          addToast("Orders Successfully Removed", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static6(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else if (result3.status === 204) {
          addToast("Orders Successfully Removed", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static6(false);
          queryClient.invalidateQueries("AllOrders");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static6(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) => {
        setModal_static5(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    const result = AllOrdersData?.orders?.filter((e) => {
      return (
        e?.orderId?.toLowerCase().match(search.toLowerCase()) ||
        e?.contactName?.toLowerCase().match(search.toLowerCase()) ||
        e?.internalReferenceNumber?.toLowerCase().match(search.toLowerCase())
        // e?.internalReferenceNumber?.match(search)
      );
    });
    setFilterTable(result);
  }, [search]);

  const actionOptions = [
    { value: "delete", label: "Delete Selected" },
    // { value: "detach", label: "Detach from Shifts" },
  ];

  const actionsChangeFunc = (e) => {
    if (e.value === "delete") {
      setModal_static5(true);
    } else if (e.value === "detach") {
      setModal_static6(true);
    }
  };

  return (
    <div>
      {modal_static8 && (
        <div className="modalMask">
          <div className="modalWrapper-delete">
            <div className="circle">!</div>
            <div className="delete-modal-body">
              <h3>Are You Sure?</h3>
              <p>
                If you proceed, you will lose all your personal data. Are you
                sure you want to delete this order.
              </p>
            </div>
            <div className="delete-modal-btns-wrapper">
              <button onClick={DelOrderFunc}>Confirm</button>
              <button onClick={() => setModal_static8(false)}>Not Now</button>
            </div>
          </div>
        </div>
      )}
      <div className="p-4">
        <DataTabelComponent
          title=""
          columns={columns}
          progressPending={AllOrders.isLoading}
          data={filterTable ? filterTable : AllOrdersData?.orders}
          pagination={true}
          fixedHeader={false}
          fixedHeaderScrollHeight="1000px"
          selectableRows={true}
          onSelectedRowsChange={handleChange}
          clearSelectedRows={toggledClearRows}
          selectableRowsHighlight={true}
          highlightOnHover={true}
          subHeader={true}
          subHeaderComponent={
            <div className="w-100">
              <Row className="mb-2 mt-2">
                <Col lg={4} md={4} sm={4}>
                  <span className="">
                    <Select
                      className="c_o_select_css2 mx-2"
                      options={dateOptions}
                      placeholder="Choose Date Range"
                      onChange={(e) => setOrdersDate(e.value)}
                    ></Select>
                  </span>
                </Col>
                <Col lg={3} md={3} sm={3} style={{ display: "flex" }}>
                  <span className="px-2">
                    <Select
                      isDisabled={selectedRows.length === 0}
                      className="c_o_select_css2 mx-auto"
                      options={actionOptions}
                      placeholder="Bulk Actions"
                      onChange={(e) => actionsChangeFunc(e)}
                    ></Select>
                  </span>
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <span>
                    <input
                      type="text"
                      placeholder="search by id | name | phone"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </span>
                </Col>
              </Row>
            </div>
          }
        />
      </div>
      <div>
        <Modal isOpen={modal_static5} toggle={tog_static5} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">
                    Do you really want to delete {selectedRows.length} orders?
                  </p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <div className="suspend-driver-popup">
              <div className="suspend-driver-btns-wrapper">
                <Button
                  color="success"
                  outline
                  className="c_o_btns skip_shift_btn"
                  onClick={DeleteOrdersFunc}
                >
                  Yes
                </Button>

                <Button
                  color="success"
                  outline
                  className="c_o_btn1 skip_shift_btn"
                  onClick={() => setModal_static5(false)}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal_static6} toggle={tog_static6} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">
                    Do you really want to detach {selectedRows.length} orders
                    from shift?
                  </p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <div className="suspend-driver-popup">
              <div className="suspend-driver-btns-wrapper">
                <Button
                  color="success"
                  outline
                  className="c_o_btns skip_shift_btn"
                  onClick={RemoveOrdersFromShiftFunc}
                >
                  Yes
                </Button>

                <Button
                  color="success"
                  outline
                  className="c_o_btn1 skip_shift_btn"
                  onClick={() => setModal_static6(false)}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default AssignedOrders;
