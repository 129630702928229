import React from 'react'
import modalImage1 from "../../../images/modals/packagedetail.png";
import { VscChromeClose } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";


const EditReportDetail = ({ reportPopup, setReportPopup }) => {

    const navigate = useNavigate();

    const continueFunction = () => {

        localStorage.removeItem("editShipmentId");

        setReportPopup(false);
        navigate("/orders");

    } 


    const downloadPdfFunction = () => {

        const id = JSON.parse(localStorage.getItem("editShipmentId"));

        const apiUrl = `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/pdf/${id}`;
        window.open(apiUrl, '_blank');



    }
    return (
        <> {reportPopup ? <div className="modalMask">
            <div style={{ width: "495px", borderRadius: "13px" }} className="userModalWrapper">
                <div className="modal__body">
                    <div>
                        <div className="usermodal__body modal__body">
                            <div className="flex justify-end">

                                <button
                                    className="close-button"
                                    onClick={
                                        () => setReportPopup(false)
                                    }
                                    style={{margin: "0"}}
                                >
                                    <VscChromeClose />
                                </button>
                            </div>
                            <div className='flex justify-center'>

                                <img src={modalImage1} alt="" />
                            </div>
                            <div className='flex justify-center'>
                                <div className="please-enter-the-req-report">
                                    Awesome!
                                    This Shipment has succcessfully updated and assigned

                                </div>

                            </div>
                            <div className='flex justify-center'>

                                <button onClick={downloadPdfFunction} className='print-pdf-button'>
                                    Print as PDF
                                </button>
                            </div>
                            <div className='flex justify-center'>

                                <button onClick={continueFunction} className='print-pdf-button-blue'>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > : ""
        }</>
    )
}

export default EditReportDetail

