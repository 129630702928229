import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import deliveries_completed from "../../images/dashboard/deliveries_completed.svg";
// import in_progress from "../../images/dashboard/in_progress.svg";
// import total_deliveries from "../../images/dashboard/total_deliveries.svg";
// import shifts from "../../images/dashboard/shift.svg";
import { useQuery, useQueryClient } from "react-query";
import { getshiftsFunc } from "../../../ApIs/shifts";
import { getOrdersFunc } from "../../../ApIs/orders";
import { getStatsFunc, getRiderStatsFunc } from "../../../ApIs/statistics";
import totalDeliveries from "../../../images/dashboard/totalDeliveries.svg";
import totalShifts_icon from "../../../images/dashboard/totalShifts.svg";
import totalRuns from "../../../images/dashboard/totalRuns.svg";
import earnings_icon from "../../../images/dashboard/earnings.svg";
import worked from "../../../images/dashboard/worked.svg";

import "./css/analytics.css";

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";
import moment from "moment";

const token = localStorage.getItem("token");

const Analytics = () => {
  const [date, setDate] = React.useState("year");
  const location = useLocation();

  const ridersStats =  useQuery([date], () => getRiderStatsFunc(token, location.state.id, date));
  
  const AllStats = useQuery([date], () => getStatsFunc(token, date));
  const weekStats = useQuery([date], () => getStatsFunc(token, "week"));
  

  const AllShifts = useQuery(["AllShifts"], () => getshiftsFunc(token, "all"));

  // *************
  const AllOrdersProgress = useQuery(["AllOrdersProgress"], () =>
    getOrdersFunc(token, "assign")
  );
  const AllOrdersProgressData = AllOrdersProgress?.data?.data;
  // *************

  // *************
  const AllOrdersDelivery = useQuery(["AllOrdersPending"], () =>
    getOrdersFunc(token, "delivered")
  );
  const AllOrdersDeliverdData = AllOrdersDelivery?.data?.data;
  // *************

  const AllOrders = useQuery(["AllOrders"], () => getOrdersFunc(token, "all"));

  
  const users = AllStats?.data?.data?.data?.users;
  const shifts = AllStats?.data?.data?.data?.shifts;
  const orders = AllStats?.data?.data?.data?.orders;
  const runs = AllStats?.data?.data?.data?.runs;
  const totalDistance = ridersStats?.data?.data?.data.totalDistance;
  const earnings = ridersStats?.data?.data?.data.totalEarning;
  const totalShifts = ridersStats?.data?.data?.data.totalShiftCompleted;
  const totalOrders = ridersStats?.data?.data?.data.totalOrdersCompleted;
  const hoursWorked = ridersStats?.data?.data?.data.totalHours;
  
  const weekUsers = weekStats?.data?.data?.data?.users;
  const weekShifts = weekStats?.data?.data?.data?.shifts;
  const weekOrders = weekStats?.data?.data?.data?.orders;
  const weekRuns = weekStats?.data?.data?.data?.runs;

  const weekData = [
    {
      name: "Users",
      value: weekUsers,
    },
    {
      name: "Shifts",
      value: weekShifts,
    },
    {
      name: "Orders",
      value: weekOrders,
    },
    {
      name: "Runs",
      value: weekRuns,
    },
  ]
  
  const months = {
    December: 0,
    November: 0,
    October: 0,
    September: 0,
    Augest: 0,
    July: 0,
    June: 0,
    May: 0,
    April: 0,
    March: 0,
    February: 0,
    January: 0,
  };

  const databymonth = AllOrders?.data?.data?.orders
    ? AllOrders?.data?.data?.orders?.map((data) => {
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "December"
        ) {
          months.December += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "November"
        ) {
          months.November += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "October"
        ) {
          months.October += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "September"
        ) {
          months.September += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "Augest"
        ) {
          months.Augest += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "July"
        ) {
          months.July += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "June"
        ) {
          months.June += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "May"
        ) {
          months.May += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "April"
        ) {
          months.April += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "March"
        ) {
          months.March += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "February"
        ) {
          months.February += 1;
        }
        if (
          moment(data.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            /* eslint-disable-next-line  */
            "MMMM"
          ) === "January"
        ) {
          months.January += 1;
        }
      })
    : [];

  const data = [
    {
      name: "January",
      earning: 0,
    },
    {
      name: "February",
      earning: 0,
    },
    {
      name: "March",
      earning: 0,
    },
    {
      name: "April",
      earning: 0,
    },
    {
      name: "May",
      earning: 0,
    },
    {
      name: "June",
      earning: 0,
    },
    {
      name: "July",
      earning: 0,
    },
    {
      name: "Augest",
      earning: 0,
    },
    {
      name: "September",
      earning: 0,
    },
    {
      name: "October",
      earning: 0,
    },
    {
      name: "November",
      earning: 0,
    },
    {
      name: "December",
      earning: 0,
    },
  ];

  const updateDate = (date) => {
    setDate(date);
  };

  const pieData = [
    { name: "Completed", value: 400 },
    { name: "Shifts", value: 300 },
    { name: "Kilometers", value: 300 },
    { name: "Pending", value: 200 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <>
      <div className="analytics-main">
        <div className="analytics-header">
          <div className="analytics-header-left">
            <h3>Analytics Overview,</h3>
            <h6>This Week</h6>
          </div>
          <div className="analytics-header-right">
            <button
              className={
                date === "week" ? "analytics-active-btn" : "analytics-btn"
              }
              onClick={() => updateDate("week")}
            >
              {" "}
              This Week
            </button>
            <button
              className={
                date === "month" ? "analytics-active-btn" : "analytics-btn"
              }
              onClick={() => updateDate("month")}
            >
              Last Month
            </button>
            <button
              className={
                date === "3months" ? "analytics-active-btn" : "analytics-btn"
              }
              onClick={() => updateDate("3months")}
            >
              3 Months
            </button>
            <button
              className={
                date === "year" ? "analytics-active-btn" : "analytics-btn"
              }
              onClick={() => updateDate("year")}
            >
              Last Year
            </button>
          </div>
        </div>
        <div className="analytics-body">
          <div className="analytics-body-left">
            <div className="jobs-heading">
              <span>Earnings</span>
            </div>
            <ComposedChart width={500} height={340} data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
              <Bar dataKey="earning" barSize={63} fill="#08CD98" />
              {/* <Line dataKey="uv" stroke="#ff7300" /> */}
            </ComposedChart>
          </div>
          <div className="rider-analytics-body-right">
            <h3>Today</h3>
            <div className="bar-chart">
              {/* <PieChart width={230} height={400}>
                <Pie
                  data={weekData}
                  cx={120}
                  cy={200}
                  innerRadius={50}
                  outerRadius={100}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey="value"
                >
                  {weekData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart> */}
              <div className="stats__wrap">
                <div className="stats__wrap__left">
                  <span className="dot dot__g"></span>
                  <p className="stat__title">Deliveries Completed</p>
                </div>
                <p className="stats__wrap__right">{totalOrders}</p>
              </div>
              <div className="stats__wrap">
                <div className="stats__wrap__left">
                  <span className="dot dot__p"></span>
                  <p className="stat__title">Distance Travelled</p>
                </div>
                <p className="stats__wrap__right">{totalDistance} Km</p>
              </div>
              <div className="stats__wrap">
                <div className="stats__wrap__left">
                  <span className="dot dot__y"></span>
                  <p className="stat__title">Shifts Completed</p>
                </div>
                <p className="stats__wrap__right">{totalShifts}</p>
              </div>
              <div className="stats__wrap">
                <div className="stats__wrap__left">
                  <span className="dot dot__r"></span>
                  <p className="stat__title">Hours Worked</p>
                </div>
                <p className="stats__wrap__right">{hoursWorked} Hrs</p>
              </div>
            </div>
          </div>
        </div>
        <div className="analytics-footer">
          <div className="analytics-footer-card">
            <img src={totalRuns} alt="totalDeliveries" />
            <div></div>
            <p className="dashboard-item">Distance Travelled by Drivers</p>
            <span>{totalDistance} Km</span>
          </div>
          <div className="analytics-footer-card">
            <img src={earnings_icon} alt="totalDeliveries" />
            <div></div>
            <p className="dashboard-item">Earnings</p>
            <span>{earnings} $</span>
          </div>
          <div className="analytics-footer-card">
            <img src={totalShifts_icon} alt="totalDeliveries" />
            <div></div>
            <p className="dashboard-item">Shifts Completed</p>
            <span>{totalShifts}</span>
          </div>
          <div className="analytics-footer-card">
            <img src={totalDeliveries} alt="totalDeliveries" />
            <div></div>
            <p className="dashboard-item">Total Orders Delivered</p>
            <span>{totalOrders}</span>
          </div>
          <div className="analytics-footer-card">
            <img src={worked} alt="totalDeliveries" />
            <div></div>
            <p className="dashboard-item">Hours Worked</p>
            <span>{hoursWorked}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
