import React, { useState, useEffect } from "react";
import cross from "../../../images/icons/cross-white.svg";
import DataTable from "react-data-table-component";
import { useToasts } from "react-toast-notifications";
import { VscChromeClose } from "react-icons/vsc";
import modalImage1 from "../../../images/modals/customer.png";

import table_search from "../../../images/icons/table_search.svg";

const EditDriverModal = ({
  setDriverModal,
  driverSelectedRow,
  setDriverSelectedRow,
  data,
  startTime,
  totalDistance,
  estimationTime,
  estimationToll,
  setReportPopup,
  directionData,
  createShipment,
  updateShipment
}) => {
  const { addToast } = useToasts();
  const handleRowSelected = (row) => {
    setDriverSelectedRow(row);
  };
  const [search, setSearch] = useState("")
  const [searchError, setSearchError] = React.useState(false);
  const [filterTable, setFilterTable] = useState([]);
  const columns = [
    {
      name: "",
      width: "50px",
      selector: (row) => {
        return (
          <div>
            <input
              type="checkbox"
              checked={driverSelectedRow?._id === row._id}
              onChange={() => handleRowSelected(row)}
            />
          </div>
        );
      },
    },
    {
      name: "Driver Name",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>{row?.fullName ? row?.fullName : row?.firstName + " " + row?.lastName}</div>
        </div>
      ),
    },
    {
      name: "Phone No.",
      selector: (row) => row?.mobileNumber,
    },
    {
      name: "Email",
      width: "115px",
      selector: (row) => row?.email,
    },
    {
      name: "Vehicle Info",
      selector: (row) => row.vehical_Info,
    },
    {
      name: "Status",
      selector: (row) => (row.status ? row?.status : ""),
    },
  ];

  function mutateCreateShipment() {
    
    const shipmentData = JSON.parse(
      localStorage.getItem("editData")
    )

    const shipmentDetails = shipmentData?.shipmentDetails;
    const pickupDetails = shipmentData?.pickupDetails;
    const receiverDetails = shipmentData?.receiverDetails;
    const packages = shipmentData?.packages;
    const routeAssingTo = localStorage.getItem("routeAssingTo");
    const selectedTruck = shipmentData?.selectedTruck;
    const selectedDriver = localStorage.getItem("selectedDriver");



    if (selectedDriver && selectedTruck && routeAssingTo) {
      delete shipmentDetails?.customer;
      delete shipmentDetails?.customerId;
      updateShipment(shipmentData?._id, {...shipmentData, routeAssingTo: routeAssingTo,
        selectedTruck: selectedTruck, selectedDriver: selectedDriver});

      // createShipment({
      //   shipmentDetails: {
      //     ...shipmentDetails,
      //     assignTo:
      //       shipmentDetails?.assignTo?.value === "own_driver" && selectedDriver,
      //     customerObj: {
      //       name: shipmentDetails?.customerObj?.firstName + " " + shipmentDetails?.customerObj?.lastName,
      //       _id: shipmentDetails?.customerObj?._id,
      //     },
      //     direction: directionData
      //   },
      //   estimationStartTime: startTime,
      //   estimationTime: estimationTime,
      //   estimationToll: estimationToll,
      //   totalDistance: totalDistance,
      //   pickupDetails,
      //   receiverDetails,
      //   packages,
      //   routeAssingTo,
      //   selectedTruck,
      //   selectedDriver,
      // });
      addToast("Assign to Driver in background", {
        appearance: "info",
        autoDismiss: true,
      });
    } else {
      if (!selectedDriver) {
        addToast("Please Provide a Contractor", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      if (!selectedTruck) {
        addToast("Please select a Truck", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      if (!routeAssingTo) {
        addToast("Please select a Route", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  }

  const onSearch = (e) => {
    e.preventDefault();
    const result = data?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.mobileNumber?.toString() === (search)
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  };

  useEffect(() => {
    const result = data?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.vat?.toString() === (search)
      );
    });
    setFilterTable(result);
  }, []);

  const customStyles = {
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
  }
  };

  let debounceTimeout;
  const debounce = (func, key, delay) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
          func(key);
      }, delay);
  };

  const onDelaySearch = (search) => {
    const result = data?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.mobileNumber?.toString() === (search)
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value)
    const search = () => onDelaySearch(e.target.value);
    debounce(search, 300);
  };

  return (
    <div className="modalMask">
      <div className="userModalWrapper">
        <div className="modal__body">
          <div>
            <div className="usermodal__body modal__body">
              <div className="flex justify-flex">
                <img src={modalImage1} alt="" />
                <button
                  className="close-button"
                  onClick={() => setDriverModal(false)}
                >
                  <VscChromeClose />
                </button>
              </div>
              <div className="customer-details">List of Drivers!</div>
              <div className="flex justify-flex">
                <div className="please-enter-the-req-modal">
                  Please select or search the driver here. <br /> Thanks!
                </div>
                <form onSubmit={onSearch}>
                  <div className="table__Search__modal">
                    <button type="submit" className="input_search__modal">
                      <img
                        src={table_search}
                        alt=""
                        onClick={() => onSearch()}
                      />
                    </button>
                    <input
                      type="text"
                      placeholder="Search by name/email/license number"
                      value={search}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: "0px 0px 30px 0px" }}>
          <div style={{ height: "200px", overflow: "auto" }}>
            <DataTable 
              columns={columns} 
              data={filterTable ? filterTable : data} 
              customStyles={customStyles}
            />
          </div>
          <div className="content_btn_center">
            <button className="assign_btn">
              <div
                className="inner_text_btn"
                onClick={() => {
                  if (driverSelectedRow?._id) {
                    setDriverModal(false);
                    localStorage.setItem(
                      "selectedDriver",
                      driverSelectedRow?._id
                    );
                    mutateCreateShipment();
                  }
                }}
              >
                Assign
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDriverModal;
