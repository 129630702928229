import DataTable, { createTheme } from "react-data-table-component";

const DataTabelComponent = (prop) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "65px", // override the row height
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: '#F2F5F5',
        background: "#06A57A",
        height: "29px",
        // color: '#7E7878',
        color: "#FFFFFF",

        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "106.68%",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        // height: '83px',
        height: "auto",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
        borderRadius: "29px 29px 0px 0px",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  //theme specific
  createTheme(
    "solarized",
    {
      text: {
        primary: "#443E3E",
        secondary: "#443E3E",
      },
      background: {
        default: "#FFFFFF",
      },
      // context: {
      //   background: '#cb4b16',
      //   text: '#FFFFFF',
      // },
      // divider: {
      // //   default: '#073642',
      // },
      // action: {
      //   button: 'rgba(0,0,0,.54)',
      //   hover: 'rgba(0,0,0,.08)',
      //   disabled: 'rgba(0,0,0,.12)',
      // },
    },
    "light"
  );

  return (
    <DataTable
      title={prop.title}
      columns={prop.columns}
      data={prop.data}
      pagination={prop.pagination}
      fixedHeader={prop.fixedHeader}
      fixedHeaderScrollHeight={prop.fixedHeaderScrollHeight}
      selectableRows={prop.selectableRows}
      responsive={true}
      selectableRowsHighlight={prop.selectableRowsHighlight}
      highlightOnHover={prop.highlightOnHover}
      subHeader={prop.subHeader}
      subHeaderComponent={prop.subHeaderComponent}
      selectableRowsComponent={prop.selectableRowsComponent}
      selectableRowsComponentProps={prop?.selectableRowsComponentProps}
      selectableRowSelected={prop?.selectableRowSelected}
      onRowSelected={prop.onRowSelected}
      subHeaderAlign={prop.subHeaderAlign}
      customStyles={prop.customStyles ? prop.customStyles : customStyles}
      theme="solarized"
      subHeaderWrap={prop.subHeaderWrap}
      onSelectedRowsChange={prop.onSelectedRowsChange}
      clearSelectedRows={prop.clearSelectedRows}
      selectableRowsSingle={prop.selectableRowsSingle}
      progressPending={prop.progressPending}
      onRowClicked={prop.rowClicking}
      // maxHeight='520px' maxWidth='520px'
    />
  );
};

export default DataTabelComponent;
