// ****** shifts get Api******
export const getshiftsFunc = async (token, status) => {
    // return await (
      const res = 
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/allShifts?status=${status}`, {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
  
      if (!res.ok) {
        if(res.status === 401){
        //   history.push("/login");
          throw new Error(resJson.error.message);
       
      }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      
      const data = await res.json();
      
      return {
        data
      };
    // ).json();
  };

// ****** shift get Api******
export const getShiftFunc = async (token,id) => {
  // return await (
    const res = 
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/getShift/${id}`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    if (!res.ok) {
      if(res.status === 401){
      //   history.push("/login");
        throw new Error(resJson.error.message);
     
    }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
    
    return {
      data
    };
  // ).json();
};


// ****** shift get Api******
export const getPendingShiftFunc = async (token,id) => {
  // return await (
    const res = 
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/getPendingShifts`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    if (!res.ok) {
      if(res.status === 401){
      //   history.push("/login");
        throw new Error(resJson.error.message);
     
    }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
    
    return {
      data
    };
  // ).json();
};