// ****** contractors get Api******

export const getTrucksFunc = async (token) => {
  // return await (
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/get-trucks`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
};
