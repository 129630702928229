import React, { useState, useEffect } from "react";

import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  Input,
  Container,
  Spinner,
} from "reactstrap";
import DataTabelComponent from "../../components/tables";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { getshiftsFunc } from "../../ApIs/shifts";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import "./css/manageShifts.css";
import { getdriversFunc } from "../../ApIs/drivers";
import CustomDropdown from "../../components/Dropdown/index";
import moment from "moment";

const AssignedShifts = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [search, setSearch] = useState("");
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static4, setModal_static4] = useState(false);
  const [modal_static5, setModal_static5] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [shiftId, setShiftId] = useState("");
  const token = localStorage.getItem("token");
  const [modal_static8, setModal_static8] = useState(false);
  const [status, setStatus] = useState("pending-approval");

  const [deleteShift, setDeleteShift] = useState(false);

  const actionOptions = [
    { value: "Edit", label: "Edit" },
    { value: "Delete", label: "Delete" },
    { value: "Re-Assign", label: "Re Assign" },
    { value: "View-Map", label: "View Map" },
  ];

  const onChangeSelect = (e, row) => {
    if (e.value === "Delete") {
      setModal_static8(true);
      setShiftId(row?._id);
    } else if (e.value === "Edit") {
      navigate("/edit_shift", { state: row });
    } else if (e.value === "Re-Assign") {
      setShiftId(row?._id);
      tog_static4();
    } else if (e.value === "View-Map") {
      routeToMap(row?._id);
    }
  };

  const columns = [
    {
      name: "Sr#",
      width: "40px",
      selector: (row, index) => index + 1,
    },
    {
      name: "Created Date",
      selector: (row, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{row?.createdAt?.split("T")[0]}</span>
          <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>
          <span>{moment(row?.createdAt).fromNow()}</span>
        </div>
      ),
    },
    {
      name: "Shift Id",
      width: "90px",
      selector: (row) => row?.shiftId,
    },
    {
      name: "Delivery Date",
      selector: (row) => row?.date?.split("T")[0],
      sortable: true,
    },
    {
      name: "Duration (hour)",
      selector: (row) => Number(row?.duration)?.toFixed(2) + " " + "hours",
    },
    {
      name: "Minimum Earning By Rider",
      selector: (row) => Number(row?.minimumEarning)?.toFixed(2),
    },
    {
      name: "Starting Time",
      selector: (row) => row?.startTime,
    },
    {
      name: "Jobs",
      width: "70px",
      selector: (row) => row?.orders?.length,
    },
    {
      name: "Kilometers",
      width: "90px",
      selector: (row) => (row?.totalDistance ? row?.totalDistance : 0),
    },
    {
      name: "Driver",
      selector: (row) => row?.assignedTo?.name,
    },
    {
      name: "",
      selector: (row) =>
        row?.assignedTo?.profilePhoto ? (
          <img
            src={row?.assignedTo?.profilePhoto}
            alt="driver-img"
            className="user-image"
          />
        ) : (
          ""
        ),
    },

    {
      name: "Actions",
      width: "150px",

      cell: (row) => (
        <>
          <span className="">
            <Select
              className="c_o_select_css2 mx-auto"
              options={actionOptions}
              placeholder="Acions"
              value=''
              menuPortalTarget={document.body}
              onChange={(e) => onChangeSelect(e, row)}
            ></Select>
          </span>
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={
            row.status === "unassigned" ? "text-danger" : "text-success"
          }
        >
          {row.status === "unassigned"
            ? "UNASSIGNED"
            : row.status === "completed"
            ? "COMPLETED"
            : row.status === "progress"
            ? "ASSIGNED"
            : "Pending From Driver"}
        </span>
      ),
    },
  ];

  const driverColumns = [
    {
      name: "Driver Name",
      selector: (row) =>
        row?.profilePhoto ? (
          <>
            <img
              src={row?.profilePhoto}
              alt="driver-img"
              className="user-image-2"
            />
            <span></span>

            <span>{row?.name}</span>
          </>
        ) : (
          ""
        ),
      // selector: (row) => row?.name,
    },
    {
      name: "Contact Number",
      selector: (row) => row?.mobileNumber,
    },
    {
      name: "Address",
      selector: (row) => row?.address,
    },
    {
      name: "Vehicle Information",
      selector: (row) => row?.vehicleType,
    },
  ];

  const timeOptions = [
    { value: 0, label: "12:00 A.M" },
    { value: 30, label: "12:30 A.M" },
  ];

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };
  const tog_static5 = () => {
    setModal_static5(!modal_static5);
    removeBodyCss();
  };
  const tog_static8 = () => {
    setModal_static8(!modal_static8);
    removeBodyCss();
  };

  const routeToMap = (id) => {
    navigate(`/shift-map/${id}`);
  };

  // *******All Shifts******
  const AllShifts = useQuery(["AllShifts", status], () =>
    getshiftsFunc(token, status)
  );
  const AllShiftsData = AllShifts?.data;
  // *************

  // *************
  const AllDrivers = useQuery(["AllDrivers"], () =>
    getdriversFunc(token, "approved")
  );
  const AllDriversData = AllDrivers?.data?.data;
  // *************

  const DelShiftFunc = () => {
    setDeleteShift(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/${shiftId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((result3) => {
        setDeleteShift(false);
        queryClient.invalidateQueries("AllShifts");
        if (result3.success) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static8(false);
          // queryClient.invalidateQueries("AllShifts");
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else {
          setDeleteShift(false);
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllShifts");
        }
      })
      .catch((error) => {
        setDeleteShift(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const AssignShiftFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/assignShift`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        riderId: selectedRows,
        shiftId: shiftId,
      }),
    })
      .then((res) => res.json())
      .then((result3) => {
        queryClient.invalidateQueries("AllShifts");
        if (result3.success) {
          addToast("Successfully Assign", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static5(false);
          // queryClient.invalidateQueries("AllShifts");
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else {
          addToast("Assign Shift Failed", {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static5(false);
          setModal_static8(false);
          queryClient.invalidateQueries("AllShifts");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const handleChange = (state) => {
    const riders = state?.selectedRows?.map((rider) => rider._id);
    setSelectedRows(riders[0]);
  };

  useEffect(() => {
    const result = AllShiftsData?.data?.filter((e) => {
      return e?.shiftId?.toLowerCase().match(search.toLowerCase());
    });
    setFilterTable(result);
  }, [search]);

  return (
    <div className="pt-4">
      {modal_static8 && (
        <div className="modalMask">
          <div className="modalWrapper-delete-red">
            <div className="circle-red">X</div>
            <div className="delete-modal-body">
              <h3>Are You Sure?</h3>
              <p>
              If you proceed, you will lose all your shift data. Are you sure you want to delete this shift.
              </p>
            </div>
            <div className="delete-modal-btns-wrapper-red">
              <button onClick={DelShiftFunc} disabled={deleteShift}>
                {deleteShift ? <Spinner color="dark" size="sm" /> : "Confirm"}
              </button>
              <button
                onClick={() => setModal_static8(false)}
                disabled={deleteShift}
              >
                Not Now
              </button>
            </div>
          </div>
        </div>
      )}
       {modal_static5 && (
        <div className="modalMask">
          <div className="modalWrapper-delete-red">
            <div className="circle-red">!</div>
            <div className="modal-body-reassign">
              <p>
                Are you sure you want to reassign this shift?
              </p>
            </div>
            <div className="delete-modal-btns-wrapper-red">
              <button  onClick={() => AssignShiftFunc()}>
                Yes
              </button>
              <button
                onClick={() => setModal_static5(false)}
              >
                Not Now
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="p-4">
        <DataTabelComponent
          title=""
          columns={columns}
          progressPending={AllShifts.isLoading}
          data={filterTable ? filterTable : AllShiftsData?.data}
          pagination={true}
          fixedHeader={false}
          fixedHeaderScrollHeight="1000px"
          // selectableRows={true}
          // selectableRowsHighlight={true}
          highlightOnHover={true}
          subHeader={false}
        />
        <div>
          <Modal
            size="lg"
            style={{ maxWidth: "700px", width: "100%" }}
            isOpen={modal_static4}
            toggle={tog_static4}
            centered={true}
          >
            <ModalHeader className=" m_d_popup_header">
              <Container fluid>
                <Row>
                  <Col md={12}>
                    {" "}
                    <p className="mt-3 m_d_popup_header_font">Assign Driver</p>
                  </Col>
                </Row>
              </Container>
            </ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row>
                  <DataTabelComponent
                    columns={driverColumns}
                    data={AllDriversData?.riders}
                    pagination={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight="200px"
                    selectableRows={true}
                    selectableRowsHighlight={true}
                    highlightOnHover={true}
                    subHeader={true}
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={toggledClearRows}
                    selectableRowsSingle={true}
                  />
                </Row>

                <Row className="mt-5">
                  <Col md={3}>
                    <Button
                      color="danger"
                      className="w-100"
                      onClick={() => {
                        setModal_static4(false);
                        setModal_static5(true);
                      }}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Assign
                      </p>
                    </Button>
                  </Col>
                  <Col md={3}></Col>
                  <Col md={3}></Col>
                  <Col md={3}>
                    <Button
                      color="danger"
                      outline
                      className="w-100 "
                      onClick={() => setModal_static4(false)}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Cancel
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AssignedShifts;
