import Analytics from "../pages/Analytics";
import CreateOrder from "../pages/CreateOrder";
import EditOrder from "../pages/CreateOrder/editOrder";
import MannageAllOrders from "../pages/CreateOrder/mannageAllOrders";
import Dashboard from "../pages/dashboard";
import ManageDrivers from "../pages/ManageDrivers/ManageDrivers";
import EditDrivers from "../pages/ManageDrivers/EditDrivers";
import ManageShifts from "../pages/ManageShifts/ManageShifts";
import EditShift from "../pages/ManageShifts/editShift";
import ShiftMaps from "../pages/ManageShifts/shiftDetail";
import ShiftDetailDriver from "../pages/ManageShifts/shiftDetailDriver";
import AssignedOrders from "../pages/CreateOrder/AssignedOrders"
import UnassignedOrders from "../pages/CreateOrder/UnassignedOrders";
import RecentUnassignedOrders from "../pages/CreateOrder/RecentUnassignedOrders";
import TrackOrder from "../pages/CreateOrder/TrackOrder";
import CompletedOrders from "../pages/CreateOrder/CompletedOrders";
import ShiftCompletedOrders from "../pages/CreateOrder/ShiftCompletedOrders";
import InprogressOrders from "../pages/CreateOrder/InprogressOrders";
import CreateShift from "../pages/ManageShifts/CreateShift";
import UnassignedShifts from "../pages/ManageShifts/UnassignedShifts";
import InprogressShifts from "../pages/ManageShifts/InprogressShifts";
import CompletedShifts from "../pages/ManageShifts/CompletedShifts";
import TrackShift from "../pages/ManageShifts/TrackShift";
import AssignedShifts from "../pages/ManageShifts/AssignedShifts";
import ApprovedDrivers from "../pages/ManageDrivers/ApprovedDrivers";
import UnapprovedDrivers from "../pages/ManageDrivers/UnapprovedDrivers";
import SuspendedDrivers from "../pages/ManageDrivers/SuspendedDrivers";
import Bills from "../pages/Bills";
import RiderBills from "../pages/RiderBillss";

import DriversAnalytics from "../pages/ManageDrivers/Analytics";
import Profile from "../pages/Profile";
import Shipping from "../pages/Shipping";
import RoutePage from '../pages/Shipping/RoutePage';
import EditRoutePage from '../pages/Shipping/EditRoutePage';
import Driver from "../pages/Driver";
import Customers from "../pages/Customers";
import Contractor from "../pages/Contractor";
import Tracking from "../pages/Tracking";
import Shipment from "../pages/Shipment";
import Orders from "../pages/Orders";
import Invoices from "../pages/Orders/invoices";
import OrderDetail from "../pages/Orders/OrderDetail.jsx";
import Admins from "../pages/Admins";


const Pages = [
  {
    id: "1",
    path: "/admins",
    element: <Admins />,
  },
  {
    id: "2",
    path: "/dashboard",
    element: <Dashboard />,
  },
 
  {
    id: "3",
    path: "manage_drivers",
    element: <ManageDrivers />,
  },
  {
    id: "4",
    path: "analytics",
    element: <Analytics />,
  },
  {
    id: "5",
    path: "manage_shifts",
    element: <ManageShifts />,
  },
  {
    id: "6",
    path: "create_order",
    element: <CreateOrder />,
  },
  {
    id: "7",
    path: "edit_drivers",
    element: <EditDrivers />,
  },
  {
    id:"8",
    path:"edit_order/:id",
    element:<EditOrder />,
  },{
    id:"9",
    path:"edit_shift",
    element:<EditShift />
  },
  {
    id:"10",
    path:"mannage_all_orders",
    element:<MannageAllOrders />
  },
  {
    id:"8",
    path:"shift-map/:id",
    element:<ShiftMaps />,
  },
  {
    id:"12",
    path:"assigned_orders",
    element: <AssignedOrders />,
  },
  {
    id:"13",
    path:"unassigned_orders",
    element: <UnassignedOrders />,
  },
  {
    id:"14",
    path:"track_order",
    element: <TrackOrder />,
  },
  {
    id:"15",
    path:"completed_orders",
    element: <CompletedOrders />,
  },
  {
    id:"16",
    path:"inprogress_orders",
    element: <InprogressOrders />,
  },
  {
    id:"17",
    path:"create_shift",
    element: <CreateShift />,
  },
  {
    id:"18",
    path:"assigned_shifts",
    element: <AssignedShifts />,
  },
  {
    id:"19",
    path:"unassigned_shifts",
    element: <UnassignedShifts />,
  },
  {
    id:"20",
    path:"inprogress_shifts",
    element: <InprogressShifts />,
  },
  {
    id:"21",
    path:"completed_shifts",
    element: <CompletedShifts />,
  },
  {
    id:"22",
    path:"track_shift",
    element: <TrackShift />,
  },
  {
    id:"24",
    path:"approved_drivers",
    element: <ApprovedDrivers />,
  },
  {
    id:"25",
    path:"unapproved_drivers",
    element: <UnapprovedDrivers />,
  },
  {
    id:"26",
    path:"suspended_drivers",
    element: <SuspendedDrivers />,
  },
  {
    id: "27",
    path: "bills",
    element: <Bills />,
  }, 
  {
    id: "28",
    path: "riderbills",
    element: <RiderBills />,
  },
  {
    id:"29",
    path:"shift-map-driver/:id",
    element:<ShiftDetailDriver />,
  },
  {
    id:"30",
    path:"shift-orders/:id",
    element:<ShiftCompletedOrders />,
  },
  {
    id:"31",
    path:"recent_unassigned_orders",
    element: <RecentUnassignedOrders/>,
  },
  {
    id: "32",
    path:"driver-analytics/:id",
    element: <DriversAnalytics/>
  },
  {
    id: "33",
    path:"settings",
    element: <Profile />
  },
  {
    id: "34",
    path:"shipping",
    element: <Shipping />
  },
  {
    id: "35",
    path:"shipping/:id",
    element: <RoutePage />
  },
  {
    id: "36",
    path:"driver",
    element: <Driver />
  },
  {
    id: "37",
    path:"customers",
    element: <Customers />
  },
  {
    id: "38",
    path:"contractor",
    element: <Contractor />
  },  
  {
    id: "39",
    path:"tracking",
    element: <Tracking />
  },
  {
    id: "40",
    path:"shipment",
    element: <Shipment />
  },
  {
    id: "41",
    path:"orders",
    element: <Orders />
  },
  {
    id: "42",
    path:"order/:id",
    element: <OrderDetail />
  },
  {
    id: "43",
    path:"profile",
    element: <Profile />
  },
  {
    id: "44",
    path:"edit-shipping/:id",
    element: <EditRoutePage />
  },
  {
    id: "41",
    path:"invoices",
    element: <Invoices />
  },
];  


export default Pages;
