import {useState} from 'react';
import { Container, Button, Alert} from "reactstrap";
import { useLoadScript } from "@react-google-maps/api";
import Map from "./shiftMap.js";
import { useNavigate } from "react-router-dom";


// import "./css/dashboard.css";

const ShiftMaps = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY, // Add your API key
  });

  const center = {
    lat: -33.74492,
    lng: 151.0202,
  };

  

  return (
      <>
      <Container fluid style={{marginTop: "50px"}}>
        {isLoaded ? (
          <div className="maps-box" style={{backgroundColor: "#fff", padding: "0px 0"}}>
            <Map centerPoints={center}/>
          </div>
        ) : null}
        <Button
              color="success"
              outline
              className="c_o_btn1"
              style={{marginTop: "20px", zIndex: 1}}
              onClick={() => navigate(-1)}
            >
              <p>Back</p>
            </Button>
      </Container>
    </>
  );
};

export default ShiftMaps;
