import React from "react";
import Select from "react-select";


import "./imageSelect.css";


const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} className="custom-option">
    <div className="option__container">
      <p>{label}</p>
    </div>
  </div>
);

const CustomValue = ({ innerProps, label, data }) => (
  <div {...innerProps} className="custom-value">
    {data.label}
  </div>
);

const customStyles = {
  control: (provided, { menuIsOpen }) => ({
    ...provided,
    border: `0px solid ${menuIsOpen ? "" : ""} !important`,
    borderBottom: `${menuIsOpen ? "" : ""
      } !important`,
    boxShadow: "none",
    outline: "none",
    "&:hover": {
      border: "",
    },
    minHeight: "36px",
    background: "#F4F6FA",
    borderRadius: `${menuIsOpen ? "7px 7px 0 0" : "7px"} !important`,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0",
    border: "1px solid #354FAA",
    borderTop: "none",
    borderRadius: "0 0 7px 7px !important",
    boxShadow: "none",
    outline: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid lightgray",
    backgroundColor: state.isFocused ? "lightgray" : "transparent",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  }),
};

const IndicatorSeparator = () => null;

const ImageSelect = ({ options, selectedOption, setSelectedOption }) => {
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const mapOptions = [];
  if (options) {
    for (let i = 0; i < options.length; i++) {
      mapOptions.push({
        value: options[i]?._id,
        label: options[i]?.name,
        weight: options[i]?.weight
      });
    }
  }

  return (
    <Select
      options={mapOptions}
      value={selectedOption}
      onChange={handleSelectChange}
      components={{
        Option: CustomOption,
        singleValue: CustomValue,
        IndicatorSeparator,
      }}
      styles={customStyles}
    />
  );
};

export default ImageSelect;
