// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBvYQH1b8Qbj-eFzSM3Rqw1jOtqB4-ciYo",
  authDomain: "gotruckingnew-d3860.firebaseapp.com",
  projectId: "gotruckingnew-d3860",
  storageBucket: "gotruckingnew-d3860.appspot.com",
  messagingSenderId: "341130354387",
  appId: "1:341130354387:web:bca990973912fdfed23e26",
  measurementId: "G-Y1RWV6DJ4L"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseDB = getDatabase(app);