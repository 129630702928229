
// ****** Admin Invoices get Api******
export const getAdminInvoiceFunc = async (token,id) => {
    // return await (
      const res = 
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/adminInvoices`, {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
  
      if (!res.ok) {
        if(res.status === 401){
        //   history.push("/login");
          throw new Error(resJson.error.message);
       
      }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const data = await res.json();
      
      return {
        data
      };
    // ).json();
  };


// ****** Rider Invoices get Api******
export const getRiderInvoiceFunc = async (token,id) => {
    // return await (
      const res = 
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/riderInvoices`, {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
  
      if (!res.ok) {
        if(res.status === 401){
        //   history.push("/login");
          throw new Error(resJson.error.message);
       
      }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const data = await res.json();
      
      return {
        data
      };
    // ).json();
  };