import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  Container,
  Input,
  Spinner,
} from "reactstrap";
import React from "react";
import DataTabelComponent from "../../components/tables";
import { useState } from "react";
import { useEffect } from "react";
import classnames from "classnames";
import DelIcon from "../../images/editDelete/del.svg";
import EditIcon from "../../images/editDelete/edit.svg";
import shiftLoader from "../../images/shiftLoader.gif";
import createShiftPopup from "../../images/dashboard/createShiftPopup.svg";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { getOrdersFunc } from "../../ApIs/orders";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import "./css/createOrder.css";
import TimeKeeper from "react-timekeeper";
import moment from "moment";
import DurationPicker from "react-duration-picker";

const UnassignedOrders = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("pending");
  const [selectedOrders, setSelectedOrders] = useState();
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static4, setModal_static4] = useState(false);
  const [modal_static5, setModal_static5] = useState(false);
  const [modal_static6, setModal_static6] = useState(false);
  const [modal_static7, setModal_static7] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [modal_static8, setModal_static8] = useState(false);
  const [modal_static9, setModal_static9] = useState(false);
  const [modal_static10, setModal_static10] = useState(false);
  const [modal_static11, setModal_static11] = useState(false);
  const [ordersDate, setOrdersDate] = React.useState("");

  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [orderId, setOrderId] = useState("");
  const token = localStorage.getItem("token");

  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Created Date",
      selector: (row, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{row?.createdAt?.split("T")[0]}</span>
          <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>
          <span>{moment(row?.createdAt).fromNow()}</span>
        </div>
      ),
    },
    {
      name: "Order Id",
      selector: (row) => row.orderId,
    },
    {
      name: "Internal Reference No",
      selector: (row) => row.internalReferenceNumber,
    },
    {
      name: "Name",
      selector: (row) => row.contactName,
    },
    {
      name: "Item Type",
      selector: (row) => row.itemType,
    },
    {
      name: "Item No.",
      selector: (row) => row.itemCount,
    },
    {
      name: "Phone",
      selector: (row) => row.contactPhoneNumber,
    },
    {
      name: "Email",
      selector: (row) => row.contactEmail,
    },
    {
      name: "Address",
      selector: (row) => row?.address?.street,
    },
    {
      name: "Suburb",
      selector: (row) => row?.address?.suburb,
    },
    {
      name: "State",
      selector: (row) => row?.address?.state,
    },
    {
      name: "Post Code",
      selector: (row) => row?.address?.postalCode,
    },
    {
      name: "Description",
      selector: (row) => row.year,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Link to={`/edit_order/${row?._id}`}>
            {" "}
            <img src={EditIcon} />
          </Link>
          <span
            onClick={() => {
              setModal_static8(true);
              setOrderId(row?._id);
            }}
          >
            <img src={DelIcon} />
          </span>
        </>
      ),
    },
  ];

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };
  const tog_static5 = () => {
    setModal_static5(!modal_static5);
    removeBodyCss();
  };

  const tog_static6 = () => {
    setModal_static6(!modal_static6);
    removeBodyCss();
  };

  const tog_static7 = () => {
    setModal_static7(!modal_static7);
    removeBodyCss();
  };

  const tog_static8 = () => {
    setModal_static8(!modal_static8);
    removeBodyCss();
  };

  const tog_static9 = () => {
    setModal_static9(!modal_static9);
    removeBodyCss();
  };


  const actionOptions = [
    { value: "delete", label: "Delete Selected" },
    { value: "assign", label: "Assign to Shift" },
  ];

  const actionsChangeFunc = (e) => {
    if (e.value === "delete") {
      setModal_static5(true);
    } else if (e.value === "assign") {
      setModal_static4(true);
    }
  };

  // *************
  const AllOrders = useQuery(["AllOrders", status, ordersDate], () =>
    getOrdersFunc(token, status, ordersDate)
  );
  const AllOrdersData = AllOrders?.data?.data;
  // *************

  useEffect(() => {
    queryClient.invalidateQueries("AllOrders");
  }, []);

  const [createShiftLoading, setCreateShiftLoading] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [date, setDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [showTime, setShowTime] = useState(false);
  const [showDuration, setShowDuration] = useState(false);
  const [time, setTime] = useState("12:34pm");


  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [duration, setDuration] = useState("");
  const [durationPrint, setDurationPrint] = useState("");



  const handleChange = (state) => {
    const orders = state?.selectedRows?.map((order) => order._id);
    setSelectedRows(orders);
  };

  const CreateShiftFunc = () => {
    setCreateShiftLoading(true);
    setModal_static4(false);
    setModal_static10(false);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/createShift`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        orders: selectedRows,
        address: {
          postalCode: 2125,
          state: "NSW",
          suburb: "West Pennant Hills",
          street: "16 Royal Oak Pl",
        },
        duration: duration === "" ? "24" : duration,
        startTime: startTime,
        date: date,
      }),
    })
      .then((result3) => {
        setDuration("");
        setStartTime("");
        setSelectedRows([]);
        if (result3.status === 200) {
          addToast("Shift Created Successfully", {
            appearance: "success",
            autoDismiss: true,
          });

          // navigate("/manage_shifts");
          setTimeout(() => {
            setCreateShiftLoading(false);
            setModal_static8(false);
            setModal_static4(false);
            setModal_static10(false);
            setModal_static11(true);
          }, 20000);
        } else if (result3.status === 204) {
          addToast("Shift Created Successfully", {
            appearance: "success",
            autoDismiss: true,
          });

          setTimeout(() => {
            setCreateShiftLoading(false);
            setModal_static8(false);
            queryClient.invalidateQueries("AllShifts");
            setModal_static11(true);
          }, 20000);
        } else {
          addToast("Shift Created Successfully", {
            appearance: "success",
            autoDismiss: true,
          });

          setTimeout(() => {
            setCreateShiftLoading(false);
            setModal_static8(false);
            setModal_static11(true);
          }, 20000);
        }
      })
      .catch((error) => {
        // setCreateShiftLoading(false);
        setDuration("");
        setStartTime("");
        setSelectedRows([]);
        addToast("Shift Created Successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        setTimeout(() => {
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
          setModal_static11(true);
        }, 20000);
      });
  };

  const DelOrderFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/${orderId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static8(false);
          // queryClient.invalidateQueries("AllOrders");
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else if (result3.status === 204) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const DeleteOrdersFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/deleteOrders`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        orders: selectedRows,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          addToast("Orders Successfully Deleted, orders will be removed", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static5(false);
          // queryClient.invalidateQueries("AllOrders");
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else if (result3.status === 204) {
          // addToast("Orders Successfully Deleted, orders will be removed", {
          //   appearance: "success",
          //   autoDismiss: true,
          // });
          setModal_static5(false);
          queryClient.invalidateQueries("AllOrders");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static5(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) => {
        setModal_static5(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        });
        queryClient.invalidateQueries("AllOrders");
      });
  };

  useEffect(() => {
    const result = AllOrdersData?.orders?.filter((e) => {
      return (
        e?.orderId?.toLowerCase().match(search.toLowerCase()) ||
        e?.contactName?.toLowerCase().match(search.toLowerCase()) ||
        e?.internalReferenceNumber?.toLowerCase().match(search.toLowerCase())
        // e?.internalReferenceNumber?.match(search)
      );
    });
    setFilterTable(result);
  }, [search]);

  const dateOptions = [
    { value: "25hrs", label: "Last 24 Hours" },
    { value: "3days", label: "Last 3 Days" },
    { value: "7days", label: "Last 7 Days" },
    { value: "15days", label: "Last 15 Days" },
    { value: "30days", label: "Last 30 Hours" },
    // { value: "custom", label: "Custom Date Range" },
  ];

  const onChange = (duration) => {
    const res = (duration?.minutes / 60) + duration?.hours;
    setDurationPrint(`${duration.hours}.${duration.minutes}`)
    setDuration(res.toFixed(2).toString());
  };

  return (
    <div>
      {/* ------ Custom Modals ------ */}
      {createShiftLoading && (
        <div className="modalMask">
          <div className="modalWrapper">
            <img src={shiftLoader} alt="loader" />
          </div>
        </div>
      )}
      {modal_static8 && (
        <div className="modalMask">
          <div className="modalWrapper-delete">
            <div className="circle">!</div>
            <div className="delete-modal-body">
              <h3>Are You Sure?</h3>
              <p>
                If you proceed, you will lose all your personal data. Are you
                sure you want to delete this order.
              </p>
            </div>
            <div className="delete-modal-btns-wrapper">
              <button onClick={DelOrderFunc}>Confirm</button>
              <button onClick={() => setModal_static8(false)}>Not Now</button>
            </div>
          </div>
        </div>
      )}
      {modal_static4 && (
        <div className="modalMask">
          <div className="modalWrapper-shift">
            <div className="circle-shift"></div>
            <div className="box-hide"></div>
            <img
              src={createShiftPopup}
              alt="round-shift"
              className="round-image"
            />
            <span
              onClick={() => {
                setDuration("");
                setStartTime("");
                setModal_static4(false)
              }}
              className="close-shift-popup"
            >
              X
            </span>
            <div className="shift-popup-body">
              <h1>CREATE SHIFT</h1>
              <div className="dropdowns-wrapper">
                  <button className="duration-btn" onClick={() => setShowDuration(true)}>
                    {
                      durationPrint && duration ? `${durationPrint?.split('.')[0]} hours and ${durationPrint?.split('.')[1]} minutes` : "Set Duration"
                    }
                  </button>
                <div className="dropdown-second">
                  <Input
                    className="date-select"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="Select Date"
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <button onClick={() => setShowTime(true)}>
                    {startTime ? startTime : "Set Time"}
                  </button>
                </div>
              </div>
              <Button
                disabled={
                  createShiftLoading ||
                  selectedRows.length === 0 ||
                  !startTime ||
                  !date ||
                  !duration
                }
                color="success"
                className="create-shift-btn"
                onClick={() => CreateShiftFunc()}
              >
                <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                  {createShiftLoading ? (
                    <Spinner color="light" size="sm" />
                  ) : (
                    "Create"
                  )}
                </p>
              </Button>
            </div>
          </div>
        </div>
      )}
      {modal_static10 && (
        <div className="modalMask">
          <div className="modalWrapper-shift">
            <div className="circle-shift"></div>
            <div className="box-hide"></div>
            <img
              src={createShiftPopup}
              alt="round-shift"
              className="round-image"
            />
            <span
              onClick={() => {setModal_static10(false); setStartTime("")}}
              className="close-shift-popup"
            >
              X
            </span>
            <div className="shift-popup-body">
              <h1>CREATE SHIFT</h1>
              <div className="dropdowns-wrapper">
                <div className="dropdown-second">
                  <Input
                    className="date-select"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="Select Date"
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <button onClick={() => setShowTime(true)}>
                    {startTime ? startTime : "Set Time"}
                  </button>
                </div>
              </div>
              <Button
                disabled={
                  createShiftLoading ||
                  selectedRows.length === 0 ||
                  !startTime ||
                  !date
                }
                color="success"
                className="create-shift-btn"
                onClick={() => {
                  setDuration("");
                  CreateShiftFunc();
                }}
              >
                <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                  Create
                </p>
              </Button>
            </div>
          </div>
        </div>
      )}
      {modal_static11 && (
        <div className="modalMask">
          <div className="modalWrapper-shift">
            <span
              onClick={() => {window.location.reload(true); setModal_static11(false);}}
              className="close-shift-popup"
            >
              X
            </span>
            <div className="shift-success-popup">
              <h1>SHIFT SUCCESSFULLY CREATED</h1>
              <button onClick={() => window.location.href = "/#/unassigned_shifts"}>Assign Shift/Shifts to Driver</button>
              <button onClick={() => {window.location.reload(true); setModal_static11(false);setDuration("");setStartTime("")}}>Create new Shift</button>
              <button onClick={() => window.location.href = "/#/dashboard" }>Go to Dashboard</button>
            </div>
          </div>
        </div>
      )}
      {showTime && (
        <div className="modalMask">
          <div className="modalWrapper">
            <TimeKeeper
              time={time}
              onChange={(newTime) => setStartTime(newTime.formatted12)}
              onDoneClick={() => setShowTime(false)}
              switchToMinuteOnHourSelect
            />
          </div>
        </div>
      )}
      {showDuration && (
        <div className="modalMask-duration">
          <div className="modalWrapper-duration" style={{padding: "30px"}}>
            <div className="duration-picker-wrapper">
              <DurationPicker
                onChange={(data) => onChange(data)}
                initialDuration={{ hours: 2, minutes: 2, seconds: 3 }}
                maxHours={24}
                value={duration}
                // seconds={0}
              />
              <button onClick={() => setShowDuration(false)}>Done</button>
            </div>
          </div>
        </div>
      )}

      <div className="p-4">
        <DataTabelComponent
          title=""
          columns={columns}
          progressPending={AllOrders.isLoading}
          data={filterTable ? filterTable : AllOrdersData?.orders}
          pagination={true}
          fixedHeader={false}
          fixedHeaderScrollHeight="1000px"
          selectableRows={status === "pending" ? true : false}
          onSelectedRowsChange={handleChange}
          clearSelectedRows={toggledClearRows}
          selectableRowsHighlight={true}
          highlightOnHover={true}
          subHeader={true}
          subHeaderComponent={
            <div className="w-100">
              <Row className="mb-2 mt-2">
                <Col lg={2} md={2} sm={2}>
                  <div className="">
                    <div className="manage-order-btns-wrapper">
                      <button
                        style={{ fontSize: "14px" }}
                        disabled={selectedRows.length === 0}
                        onClick={() => setModal_static9(true)}
                      >
                        Create Shifts
                      </button>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <span className="">
                    <Select
                      className="c_o_select_css2 mx-2"
                      options={dateOptions}
                      placeholder="Choose Date Range"
                      onChange={(e) => setOrdersDate(e.value)}
                    ></Select>
                  </span>
                </Col>
                <Col lg={3} md={3} sm={3} style={{ display: "flex" }}>
                  <span className="px-2">
                    <Select
                      isDisabled={selectedRows.length === 0}
                      className="c_o_select_css2 mx-auto"
                      options={actionOptions}
                      placeholder="Bulk Actions"
                      onChange={(e) => actionsChangeFunc(e)}
                    ></Select>
                  </span>
                </Col>
                <Col lg={3} md={3} sm={3}>
                  <span>
                    <input
                      type="text"
                      placeholder="search by id | name | phone"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </span>
                </Col>
              </Row>
            </div>
          }
        />

        <div className="manage-order-footer-wrapper">
          <div className="manage-order-btns-wrapper">
            <Link to="/create_order">
              <button>Add More</button>
            </Link>
            <button
              disabled={selectedRows.length === 0}
              onClick={() => setModal_static9(true)}
            >
              Create Shifts
            </button>
          </div>
          <p className="skip-shift-link" onClick={() => setModal_static7(true)}>
            Skip shift creations
          </p>
        </div>

        <div>
          <Modal isOpen={modal_static5} toggle={tog_static7} centered={true}>
            <ModalHeader className=" m_d_popup_header">
              <Container fluid>
                <Row>
                  <Col md={12}>
                    {" "}
                    <p className="mt-3">Skip Shift Creations</p>
                  </Col>
                </Row>
              </Container>
            </ModalHeader>
            <ModalBody>
              <div className="skip_shift-btns-wrapper">
                <Link to="/create_order">
                  <Button
                    color="success"
                    outline
                    className="c_o_btns skip_shift_btn"
                  >
                    Create More Orders
                  </Button>
                </Link>
                <Link to="/dashboard">
                  <Button
                    color="success"
                    outline
                    className="c_o_btn1 skip_shift_btn"
                  >
                    Go Back to Dashboard
                  </Button>
                </Link>
                <Link
                  to="/mannage_all_orders"
                  onClick={() => setModal_static7(false)}
                >
                  <Button
                    color="success"
                    outline
                    className="c_o_btns skip_shift_btn"
                  >
                    Manage All Orders
                  </Button>
                </Link>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div>
        <Modal isOpen={modal_static5} toggle={tog_static5} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">
                    Do you really want to delete {selectedRows.length} orders?
                  </p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <div className="suspend-driver-popup">
              <div className="suspend-driver-btns-wrapper">
                <Button
                  color="success"
                  outline
                  className="c_o_btns skip_shift_btn"
                  onClick={DeleteOrdersFunc}
                >
                  Yes
                </Button>

                <Button
                  color="success"
                  outline
                  className="c_o_btn1 skip_shift_btn"
                  onClick={() => setModal_static5(false)}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal_static6} toggle={tog_static6} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">Assign Orders to shifts</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <div className="suspend-driver-popup">
              <div className="suspend-driver-btns-wrapper">
                <Link to="/create_shift">
                  <Button
                    color="success"
                    outline
                    className="c_o_btns skip_shift_btn"
                  >
                    Create New Shifts
                  </Button>
                </Link>

                <Button
                  color="success"
                  outline
                  className="c_o_btn1 skip_shift_btn"
                >
                  Select From Existing Shifts
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal_static9} toggle={tog_static9} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3">How would you like to proceed?</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <div className="suspend-driver-popup">
              {/* <p>Driver will be available for shifts after aprroved.</p> */}
              <div className="suspend-driver-btns-wrapper">
                <Button
                  color="success"
                  outline
                  className="c_o_btns skip_shift_btn"
                  onClick={() => {
                    setModal_static10(true);
                    setModal_static9(false);
                  }}
                >
                  Create Shift Manually
                </Button>

                <Button
                  color="success"
                  outline
                  className="c_o_btn1 skip_shift_btn"
                  onClick={() => {
                    setModal_static4(true);
                    setModal_static9(false);
                  }}
                >
                  Create Shifts Automatically
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default UnassignedOrders;
