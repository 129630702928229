import React, { useState } from 'react'
import breadcrumb_logo from '../../images/icons/breadcrumb_logo.svg'
import shipment_1 from '../../images/shipment/shipment_1.svg'
import shipment_2 from '../../images/shipment/shipment_2.svg'
import './css/shipment.css'
import { getNameToken } from '../../helpers/localStorage'
import { useNavigate } from 'react-router-dom'

const Shipment = () => {

  const [active, setActive] = useState();
  const userName = getNameToken();
  const navigate = useNavigate();

  const redirect = (to) => {
    setActive(to);
    setActive("")
    if(to === "create-shipment") {
      navigate("/shipping");
    } else {
      navigate("/orders");
      // TODO
    }
  }

  const clearStorage = () => {
    localStorage.removeItem('shipmentDetails');
    localStorage.removeItem('receiverDetails');
    localStorage.removeItem('pickupDetails');
    localStorage.removeItem('packages');
    localStorage.removeItem('shipmentId');
  }

  return (
    <div>
      <div className="breadcrumb">
        <img src={breadcrumb_logo} alt="truck-logo"/>
        <span>/</span>
        <p>Shipping</p>
      </div>
      <div className='shipment_main'>
        <div className="shipment_card">
          <h3>Hey! {userName}</h3>
          <h4>Select your desired option.</h4>
          <div className="shipment_options">
            <div className='shipment_option' onClick={() => redirect("all-shipments")}>
              <div className={active === "all-shipments" ? "active ship_image_holder" : "ship_image_holder"}>
                <img src={shipment_1} alt="" />
              </div>
              <h5 className={active === "all-shipments" ? "active-text option-text" : "option-text"}>View Previous Shipments</h5>
            </div>
            <div className='shipment_option' onClick={() => {
              clearStorage();
              redirect("create-shipment")
            }}>
              <div className={active === "create-shipment" ? "active ship_image_holder" : "ship_image_holder"}>
                <img src={shipment_2} alt="" />
              </div>
              <h5 className={active === "create-shipment" ? "active-text option-text" : "option-text"}>Create New Shipment</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Shipment