import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  Input,
  Container,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import DataTabelComponent from "../../components/tables";
import { useEffect, useState } from "react";
import EyeIcon from "../../images/editDelete/eye.svg";


import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { getdriversFunc } from "../../ApIs/drivers";
import { useQuery, useQueryClient } from "react-query";
import "./css/manageDriver.css";


const ManageDrivers = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token");
  const [modal_static4, setModal_static4] = useState(false);
  const [modal_static5, setModal_static5] = useState(false);
  const [modal_static6, setModal_static6] = useState(false);
  const [modal_static7, setModal_static7] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [driverName, setDriverName] = useState("");
  const [driverEmail, setDriverEmail] = useState("");
  const [driverPhoneNumber, setDriverPhoneNumber] = useState("");
  const [driverId, setDriverId] = useState("");
  const [status, setStatus] = useState("all");
  const [divers, setDrivers] = useState([]);
  const [deleteDriver, setDeleteDriver] = useState(false);
  
  const { addToast } = useToasts();

  const actionOptions = [
    { value: "Approve", label: "Approve" },
    { value: "Edit", label: "Edit" },
    { value: "Suspend", label: "Suspend" },
    { value: "Remove", label: "Remove" },
  ];

  const onChangeSelect = (e, row) => {
    if(e.value === "Remove") {
      setModal_static5(true);
      setDriverId(row?._id);
    }else if(e.value === "Edit") {
      navigate('/edit_drivers', {state: row})
    }else if(e.value === "Approve"){
      if(!row.userVerifed){

        setModal_static6(true);
        setDriverId(row?._id);
      }
    }else if(e.value === "Suspend"){
      if(!row.isUserSuspensed){
        setModal_static7(true);
        setDriverId(row?._id);

      }
    }
  }

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Phone Number",
      selector: (row) => row?.mobileNumber,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Status",
      selector: (row) => <span className={row.userVerifed ? 'text-success' : 'text-danger'}>{row.isUserSuspensed ? "Suspended" : row.isUserRejected ? "Rejected" : row.userVerifed ? "Approved" : "Unapproved"}</span>,

    },
    {
      name: "View",
      cell: (row) => (
        <>
          <span onClick={() => {
            navigate('/edit_drivers', {state: row})
          }}>
            <img onClick={() => {navigate('/edit_drivers', {state: row})}} alt="eye-icon" src={EyeIcon} style={{width: "17px", marginRight: "5px", cursor: "pointer"}} />  
          </span>
         
        </>
      ),
    },
    {
      name: "Action",
      width: "150px",
      cell: (row) => (
        <>
          <span className="">
            <Select
              className="c_o_select_css2 mx-auto"
              options={actionOptions}
              placeholder="Select"
              value=''
              menuPortalTarget={document.body}
              onChange={ (e) => onChangeSelect(e, row)}
            ></Select>
          </span>
        </>
      ),
    }
  ];

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };



  const tableValue = (value) => {
    // queryClient.invalidateQueries("AllDrivers");
    setStatus(value);
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };

  const tog_static5 = () => {
    setModal_static5(!modal_static5);
    removeBodyCss();
  };
  const tog_static6 = () => {
    setModal_static6(!modal_static6);
    removeBodyCss();
  };
  const tog_static7 = () => {
    setModal_static7(!modal_static7);
    removeBodyCss();
  };

  const DelDriversFunc = () => {
    setDeleteDriver(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/users/${driverId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        setDeleteDriver(false);
        if (result3.status === 200) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static5(false);
          // queryClient.invalidateQueries("AllDrivers");
          setTimeout(() => {
            window.location.reload(false);
          }, 500)

        } else if (result3.status === 204) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static5(false);
          queryClient.invalidateQueries("AllDrivers");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static5(false);
          queryClient.invalidateQueries("AllDrivers");
        }
      })
      .catch((error) => {
        setDeleteDriver(false)
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      }
      );
  };


  const UpdateDriversStatusFunc = (riderStatus, suspend) => {
    // let body = riderData
    
    fetch(`${process.env.REACT_APP_BASE_URL}/api/users/updateRiderStatus/${driverId}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ status: riderStatus, suspened: suspend }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          // navigate("/manage_drivers")
          addToast("Successfully Updated", {
            appearance: "success",
            autoDismiss: true,
          });

          // queryClient.invalidateQueries("AllDrivers");
          setTimeout(() => {
            window.location.reload(false);
          }, 500)

        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          queryClient.invalidateQueries("AllDrivers");
        }
      })
      .catch((error) =>{
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
        queryClient.invalidateQueries("AllDrivers");

      }

      );
  };

  
  // *************
  const AllDrivers = useQuery(["AllDrivers", status], () => getdriversFunc(token, status));
  const AllDriversData = AllDrivers?.data?.data;
  // *************

  useEffect(() => {
    const AllDriversData = AllDrivers?.data?.data;
    setDrivers(AllDriversData)
  }, [AllDrivers?.data?.data, status])

  const createDriversFunc = () => {
    const apiObject = {
      mobileNumber: `${driverPhoneNumber}`,
      email: `${driverEmail}`,
      name: `${driverName}`,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/users/register`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObject),
    })
      .then((response) => response.json())
      .then((result3) => {
        if (result3?.success) {
          addToast("Successfully Created", {
            appearance: "success",
            autoDismiss: true,
          });
          queryClient.invalidateQueries("AllDrivers");
          setModal_static4(false);
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        setModal_static4(false);
        addToast("Error: ", error, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div className="p-4">
       {modal_static7 && (
        <div className="modalMask">
          <div className="modalWrapper-delete">
            <div className="circle">!</div>
            <div className="delete-modal-body">
              <h3>Are You Sure?</h3>
              <p>
                You cannot assign shifts to this driver if you suspend.
              </p>
            </div>
            <div className="delete-modal-btns-wrapper">
              <button onClick={() => {setModal_static7(false); UpdateDriversStatusFunc(false, true)}}>Confirm</button>
              <button onClick={() => setModal_static7(false)}>Not Now</button>
            </div>
          </div>
        </div>
      )}
       {modal_static5 && (
        <div className="modalMask">
          <div className="modalWrapper-delete">
            <div className="circle">!</div>
            <div className="delete-modal-body">
              <h3>Are You Sure?</h3>
              <p>
              If you proceed, you will lose all your driver data. Are you sure you want to delete this all Drivers.
              </p>
            </div>
            <div className="delete-modal-btns-wrapper">
              <button onClick={DelDriversFunc} disabled={deleteDriver}>
                {deleteDriver ? <Spinner color="dark" size="sm" /> : "Confirm"}
              </button>
              <button onClick={() => setModal_static5(false)} disabled={deleteDriver}>Not Now</button>
            </div>
          </div>
        </div>
      )}
      <DataTabelComponent
        title=""
        columns={columns}
        progressPending={AllDrivers.isLoading}
        data={AllDriversData?.riders}
        pagination={true}
        fixedHeader={false}
        fixedHeaderScrollHeight="450px"
        selectableRows={true}
        selectableRowsHighlight={false}
        highlightOnHover={true}
        subHeader={true}
        subHeaderComponent={
          <div className="w-100">
            <Row className="pt-4 pb-4">
              <Col lg={4} md={4} sm={4}>
                {" "}
              </Col>
              {/* <Col lg={5} md={5} sm={5}>
                  <input
                    type="text"
                    placeholder="search by name | phone"
                    className="form-control w-70"
                    // value={search}
                    // onChange={(e) => setSearch(e.target.value)}
                  />
                </Col> */}
              <Col lg={3} md={3} sm={3}>
                <Button
                  color="success"
                  onClick={() => setModal_static4(true)}
                  className="form-control w-100"
                >
                  {" "}
                  Add New Driver
                </Button>
              </Col>
            </Row>
          </div>
        }
      />

      <div>
        <Modal isOpen={modal_static4} toggle={tog_static4} centered={true}>
          <ModalHeader className=" m_d_popup_header">
            <Container fluid>
              <Row>
                <Col md={12}>
                  {" "}
                  <p className="mt-3 m_d_popup_header_font">Add New Driver</p>
                </Col>
              </Row>
            </Container>
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <p className="m_d_popup_font" style={{ color: "#817B7B" }}>
                    Name
                  </p>
                  <Input
                    placeholder="Enter Driver Name"
                    onChange={(e) => setDriverName(e?.target?.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    Phone Number
                  </p>
                  <Input
                    placeholder="Enter Driver Number"
                    onChange={(e) => setDriverPhoneNumber(e?.target?.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p
                    className="m_d_popup_font mt-3"
                    style={{ color: " #817B7B" }}
                  >
                    Email
                  </p>
                  <Input
                    placeholder="Enter Email Adress"
                    onChange={(e) => setDriverEmail(e?.target?.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <Button
                    color="danger"
                    className="w-100"
                    onClick={createDriversFunc}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Save
                    </p>
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    color="danger"
                    outline
                    className="w-100 "
                    onClick={() => setModal_static4(false)}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Cancel
                    </p>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal_static6} toggle={tog_static6} centered={true}>
          <ModalBody className="diverdelmodel">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <p className="m_d_popup_font">Are You Sure?</p>
                  <p>
                    If You Proceed, Driver will available for sifts. Are you sure you want to activate this Driver.
                  </p>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={6}>
                  <Button
                    color="light"
                    className="w-100"
                    onClick={() => { setModal_static6(false); UpdateDriversStatusFunc(true) }}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      
                    Confirm
                    </p>
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    color="light"
                    outline
                    className="w-100 "
                    onClick={() => setModal_static6(false)}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Not Now
                    </p>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default ManageDrivers;
