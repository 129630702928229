import React, { useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import modalImage1 from "../../../images/modals/customer.png";
import cross from "../../../images/icons/cross-white.svg";
import DataTable from "react-data-table-component";
import car1 from "../../../images/icons/car1.png";
import car2 from "../../../images/icons/car2.png";
import car3 from "../../../images/icons/car3.png";
import car4 from "../../../images/icons/car4.png";

// const data = [
//   {
//     check_box: true,
//     model_No: {
//       img: car1,
//       title: "Cargo Van",
//     },
//     tracker_code: "#0273527",
//     capacity: "200 Kg",
//     status: "not applicable ",
//   },
//   {
//     check_box: true,
//     model_No: {
//       img: car2,
//       title: "Isuzu Mini- Dump Truck",
//     },
//     tracker_code: "#0273525",
//     capacity: "200 Kg",
//     status: "not applicable ",
//   },
//   {
//     check_box: true,
//     model_No: {
//       img: car3,
//       title: "Red Cargo Van",
//     },
//     tracker_code: "#027352",
//     capacity: "200 Kg",
//     status: "not applicable ",
//   },
//   {
//     check_box: true,
//     model_No: {
//       img: car4,
//       title: "Isuzu Mini- Dump Truck",
//     },
//     tracker_code: "#02735221",
//     capacity: "200 Kg",
//     status: "not applicable ",
//   },
// ];

const AssignToModal = ({
  setAssignVehicleModal,
  data,
  truckSelectedRow,
  setTruckSelectedRow,
  setSelectedOption,
}) => {

  const handleRowSelected = (row) => {
    setTruckSelectedRow(row);
  };

  const columns = [
    {
      name: "Select",
      width: "85px",
      center: true,
      selector: (row) => {
        return (
          <div>
            <input
              type="checkbox"
              checked={truckSelectedRow?._id === row._id}
              onChange={() => handleRowSelected(row)}
            />
          </div>
        );
      },
    },
    {
      name: "Type",
      width: "125px",
      center: true,
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img src={row?.model_No?.img} alt="" />
          <div>{row.name}</div>
        </div>
      ),
    },
    {
      name: "Unladen weight(t)",
      width: "135px",
      center: true,
      selector: (row) => row?.weight ? row?.weight/1000 : "",
    },
    {
      name: "Size (H x W x L) (m)",
      width: "145px",
      selector: (row, item) => {
        return `${row?.height} × ${row?.width} × ${row?.length}`;
      },
    },
    {
      name: "Status",
      center: true,
      width: "115px",
      selector: (row) => {
        return row.status ? "applicable" : "not applicable";
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    // <div className="modalMask">
    //   <div className="userModalWrapper">
    //     <div className="modal__justify">
    //       <h1 className="edit_heading">Assign Vehicle</h1>
    //       <div onClick={() => setAssignVehicleModal(false)}>
    //         <img src={cross} alt="" />
    //       </div>
    //     </div>
    //     <div className="modal__body">
    //       <div style={{ marginBottom: "10px" }} className="head_assign_vehicle">
    //         List Of Vehicles
    //       </div>
    //       <div className="">
    //         <DataTable columns={columns} data={data?.data?.trucks} />
    //       </div>
    //       <div className="content_btn_center">
    //         <button className="assign_btn">
    //           <div
    //             className="inner_text_btn"
    //             onClick={() => {
    //               setAssignVehicleModal(false);
    //               setSelectedOption({
    //                 value: truckSelectedRow?._id,
    //                 label: truckSelectedRow?.name,
    //               });
    //             }}
    //           >
    //             Next
    //           </div>
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="modalMask">
      <div className="userModalWrapper">
        <div className="modal__body">
          <div>
            <div className="usermodal__body modal__body">
              <div className="flex justify-flex">
                <img src={modalImage1} alt="" />
                <button
                  className="close-button"
                  onClick={() => setAssignVehicleModal(false)}
                >
                  <VscChromeClose />
                </button>
              </div>
              <div className="customer-details">Assign Vehicle!</div>
              <div className="please-enter-the-req">
                Please Select the required Vehicle.<br /> Thanks!
              </div>


            </div>
          </div>
        </div>
        <div style={{ margin: "0px 0px 30px 0px" }}>
          <DataTable 
            columns={columns} 
            data={data?.data?.trucks} 
            customStyles={customStyles}
          />
          <div className="content_btn_center">
            <button className="assign_btn">
              <div
                className="inner_text_btn"
                onClick={() => {
                  setAssignVehicleModal(false);
                  setSelectedOption({
                    value: truckSelectedRow?._id,
                    label: truckSelectedRow?.name,
                    weight: truckSelectedRow?.weight
                  });
                }}
              >
                Next
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignToModal;
