import React, {useState} from "react";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";



const EditShift = () => {
  const token = localStorage.getItem("token");
  
  const {state} = useLocation();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  
  const [startTime, setStartTime] = React.useState("");
  const [date, setDate] = React.useState(state.date);

  const timeOptions = [
    { value: "08:00 A.M", label: "08:00 A.M" },
    { value: "09:00 A.M", label: "09:00 A.M" },
    { value: "10:00 A.M", label: "10:00 A.M" },
    { value: "11:00 A.M", label: "11:00 A.M" },
    { value: "12:00 P.M", label: "12:00 P.M" },
    { value: "13:00 P.M", label: "13:00 P.M" },
    { value: "14:00 P.M", label: "14:00 P.M" },

  ];

  const UpdateShift = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/updateShiftInfo/${state._id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        date: date,
        startTime: startTime
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          addToast("Shift Updated", {
            appearance: "success",
            autoDismiss: true,
          });
          // window.location.href = "/assigned_shifts"
          navigate(-1)

        } else if (result3.status === 204) {
          addToast("Shift Updated", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast("Shift Updatation Error", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) =>{
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      }
      );
  };

  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <Card className="e_d_header p-4">
              <Row>
                <Col md={3}>
                  <p>Shift ID </p>
                </Col>
                <Col md={9}></Col>
              </Row>

              <Row>
                <Col md={3}>
                  <p style={{fontWeight: "bold"}}>#{state.shiftId} </p>
                </Col>
                <Col md={9}></Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="mt-5 e_d_v_title_card">
              <p className="e_d_v_title">Edit</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="e_d_body p-4">
              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Delivery Date</p>
                </Col>
                <Col md={3}>
                <Input onChange={(e) => setDate(e.target.value)} name="expireDate" value={date} type="date" className="e_d_input" placeholder="22/05/24" />
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Duration</p>
                </Col>
                <Col md={3}>
                 <p style={{fontWeight: "bold"}}>{Number(state?.duration).toFixed(2) + " " + "hours"}</p>
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Starting Time</p>
                </Col>
                <Col md={3}>
                <Select
                      defaultInputValue={state.startTime}
                      options={timeOptions}
                      className="c_o_select_css"
                      placeholder="Starting Time"
                      onChange={(e) => setStartTime(e.value)}

                    ></Select>
                </Col>
                <Col md={1}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Orders</p>
                </Col>
                <Col md={3}>
                <p style={{fontWeight: "bold"}}>{state.orders.length}</p>
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={5}></Col>
                <Col md={3}></Col>
                <Col md={2}>
                  <Button onClick={() => UpdateShift()} color="danger" className=" w-100">
                    {" "}
                    Save
                  </Button>
                </Col>
                <Col md={2}>
                  <Button onClick={() => navigate(-1)} color="danger" outline className=" w-100">
                    {" "}
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditShift;
