import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import { getOrderFunc } from "../../../ApIs/orders";
import { useQuery, useQueryClient } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const EditOrder = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [ orderDataObj, setOrderDataObj]=useState({})

    // *************one Order
    const OneOrders = useQuery(["OneOrders",id], () => getOrderFunc(token,id));
    const OneOrdersData = OneOrders?.data?.data;

    // *************

    const editOrdersFunc = () => {
      const apiObject = {
        itemType: `${orderDataObj?.itemType === undefined?OneOrdersData?.itemType :orderDataObj?.itemType} `,
        // internalReferenceNumber: `${createOrderEmail}`,
        contactPhoneNumber: `${orderDataObj?.contactPhoneNumber === undefined ? OneOrdersData?.contactPhoneNumber: orderDataObj?.contactPhoneNumber }`,
        contactName:`${orderDataObj?.contactName  === undefined ? OneOrdersData?.contactName: orderDataObj?.contactName }`,
        contactEmail:`${orderDataObj?.contactEmail  === undefined ? OneOrdersData?.contactEmail: orderDataObj?.contactEmail }`,
        description:`${orderDataObj?.description  === undefined ? OneOrdersData?.description: orderDataObj?.description  }`,
        itemCount:`${orderDataObj?.itemCount  === undefined ? OneOrdersData?.itemCount:orderDataObj?.itemCount }`,
        address: {
          postalCode: orderDataObj?.postalCode  === undefined ? OneOrdersData?.address?.postalCode : orderDataObj?.postalCode ,
          state: `${orderDataObj?.state  === undefined ? OneOrdersData?.address?.state : orderDataObj?.state  }`,
          suburb: `${ orderDataObj?.suburb  === undefined ? OneOrdersData?.address?.suburb : orderDataObj?.suburb }`,
          street: `${ orderDataObj?.street  === undefined ?  OneOrdersData?.address?.street :orderDataObj?.street }`
      },
  
      };
      fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/${id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
  
        body: JSON.stringify(apiObject),
      })
        // .then((response) => response.json())
        .then((result3) => {
          if (result3?.status === 200) {
            addToast("Successfully Updated, Go back if don't want to update more.", {
              appearance: "success",
              autoDismiss: true,
            });
            queryClient.invalidateQueries("OneOrders")
            // setModal_static5(false)
            // navigate("/mannage_all_orders");
          } else {
            addToast(result3?.error, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          // setModal_static5(false)
          addToast("Error: ", error, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    };


  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <Card className="e_d_header p-4">
              <Row>
                <Col md={3}>
                  <p>Order ID </p>
                </Col>
                <Col md={9}></Col>
              </Row>

              <Row>
                <Col md={3}>
                  <p style={{fontWeight: "bold"}}>{OneOrdersData.orderId}</p>
                </Col>
                <Col md={9}></Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="mt-5 e_d_v_title_card">
              <p className="e_d_v_title">Edit</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="e_d_body p-4">
              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Name</p>
                </Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.contactName} onChange={(e)=>setOrderDataObj({...orderDataObj,contactName:e?.target?.value})}/>
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Phone</p>
                </Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.contactPhoneNumber}
                  onChange={(e)=>setOrderDataObj({...orderDataObj,contactPhoneNumber:e?.target?.value})}
                   />
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Email</p>
                </Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.contactEmail}
                   onChange={(e)=>setOrderDataObj({...orderDataObj,contactEmail:e?.target?.value})}
                  />
                </Col>
                <Col md={1}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Street Address</p>
                </Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.address?.street} 
                  onChange={(e)=>setOrderDataObj({...orderDataObj,street:e?.target?.value})}
                  />
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Subrub</p>
                </Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.address?.suburb}
                   onChange={(e)=>setOrderDataObj({...orderDataObj,suburb:e?.target?.value})}
                  />
                </Col>
                <Col md={2}></Col>
                <Col md={1} className="e_d_title2">State</Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.address?.state} 
                   onChange={(e)=>setOrderDataObj({...orderDataObj,state:e?.target?.value})}
                  />
                </Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Item Type</p>
                </Col>
                <Col md={3}>
                  <Input className="e_d_input_row"  defaultValue={OneOrdersData?.itemType}
                  onChange={(e)=>setOrderDataObj({...orderDataObj,itemType:e?.target?.value})}
                  />
                </Col>
                <Col md={2}></Col>
                <Col md={1} className="e_d_title2">Item No.</Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.itemCount}
                   onChange={(e)=>setOrderDataObj({...orderDataObj,itemCount:e?.target?.value})}
                  />
                </Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Post Code</p>
                </Col>
                <Col md={3}>
                  <Input className="e_d_input_row" defaultValue={OneOrdersData?.address?.postalCode}
                  onChange={(e)=>setOrderDataObj({...orderDataObj,postalCode:e?.target?.value})}
                  />
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />
              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Description</p>
                </Col>
                <Col md={9}>
                  <Input type="textarea" className="e_d_input_row"   defaultValue={OneOrdersData?.description}
                     onChange={(e)=>setOrderDataObj({...orderDataObj,description:e?.target?.value})}
                  />
                </Col>
                {/* <Col md={4}></Col>
                <Col md={3}></Col> */}
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={5}></Col>
                <Col md={3}></Col>
                <Col md={2}>
                  <Button color="danger" className=" w-100"
                  onClick={editOrdersFunc}
                  >
                    {" "}
                    Save
                  </Button>
                </Col>
                <Col md={2}>
                  <Button onClick={() => navigate(-1)} color="danger" outline className=" w-100">
                    {" "}
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditOrder;
