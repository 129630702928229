import React, {useState} from "react";
import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  Container,
} from "reactstrap";
import deliveries_completed from "../../images/dashboard/deliveries_completed.svg";
import in_progress from "../../images/dashboard/in_progress.svg";
import total_deliveries from "../../images/dashboard/total_deliveries.svg";
import shifts from "../../images/dashboard/shift.svg";
import { useQuery, useQueryClient } from "react-query";
import { getshiftsFunc } from "../../ApIs/shifts";
import { getOrdersFunc } from "../../ApIs/orders";
import { getBillStatsFunc } from "../../ApIs/statistics";
import { getAdminInvoiceFunc } from "../../ApIs/invoices";
import DataTabelComponent from "../../components/tables";
import paymentIcon from "../../images/dashboard/Vector.svg";
import Select from "react-select";

import "./css/analytics.css";
import BarChart from "./BarChart";

const token = localStorage.getItem("token");

const Bills = () => {
  const actionOptions = [
    { value: "Assign-Shift", label: "Assign Shift" },
    { value: "Edit", label: "Edit" },
    { value: "Suspend", label: "Suspend" },
    { value: "Remove", label: "Remove" },
  ];

  const [modal_static6, setModal_static6] = useState(false);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const tog_static6 = () => {
    setModal_static6(!modal_static6);
    removeBodyCss();
  };

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Created Date",
      selector: (row, index) => (
        <div style={{display: "flex", flexDirection: "column"}}>
        <span>{row?.createdAt?.split("T")[0]}</span>
        <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>

        </div>
      ),
      
    },
    {
      name: "Invoice No.",
      selector: (row) => row.ledgerId,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Shift Id",
      selector: (row) => row.shiftId,
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          className={row.status === "paid" ? "text-success" : "text-danger"}
        >
          {row.status === "paid" ? "Paid" : "Unpaid"}
        </span>
      ),
    },
    // {
    //   name: "Action",
    //   selector: (row) => (
    //     <>
    //     <button
    //       className="export-btn"
    //       onClick={() => setModal_static6(true)}
    //     >
    //       Export
    //     </button>
    //     <button
    //     className="export-btn"
    //     onClick={() => setModal_static6(true)}
    //   >
    //     Pay
    //   </button>
    //   </>
    //   ),
    //   width: "200px",
    // },
    // {
    //   name: "Action",
    //   width: "150px",
    //   cell: (row) => (
    //     <>
    //       <span className="">
    //         <Select
    //           className="c_o_select_css2 mx-auto"
    //           options={actionOptions}
    //           placeholder="Select"
    //           // onChange={ (e) => onChangeSelect(e, row)}
    //         ></Select>
    //       </span>
    //     </>
    //   ),
    // },
  ];

  const [date, setDate] = React.useState("week");

  const BillsStats = useQuery([date], () => getBillStatsFunc(token, date));

  const adminInvoices = useQuery(["adminInvoices"], () =>
    getAdminInvoiceFunc(token)
  );

  const AllShifts = useQuery(["AllShifts"], () => getshiftsFunc(token, "all"));

  // *************
  const AllOrdersProgress = useQuery(["AllOrdersProgress"], () =>
    getOrdersFunc(token, "assign")
  );
  const AllOrdersProgressData = AllOrdersProgress?.data?.data;
  // *************

  // *************
  const AllOrdersDelivery = useQuery(["AllOrdersPending"], () =>
    getOrdersFunc(token, "delivered")
  );
  const AllOrdersDeliverdData = AllOrdersDelivery?.data?.data;
  // *************

  const AllOrders = useQuery(["AllOrders"], () => getOrdersFunc(token, "all"));

  const paid = BillsStats?.data?.data?.data?.paidInvoices?.toFixed(2);
  const unpaid = BillsStats?.data?.data?.data?.unpaidInvoices?.toFixed(2);
  const shifts = BillsStats?.data?.data?.data?.shifts;
  const orders = BillsStats?.data?.data?.data?.orders;

  const adminInvoicesData = adminInvoices?.data?.data?.invoices;

  const updateDate = (date) => {
    setDate(date);
  };

  return (
    <>
      {/* <Container>
        
        <Row>
          <Col md={3} xl={3} lg={3} sm={3} xs={12}>
            <Card className="db_cardpadding p-3 mt-3">
              <p className="dashboard-item">Week Shifts</p>
              <span>
              {shifts}
              </span>
            </Card>
          </Col>
          <Col md={3} xl={3} lg={3} sm={3} xs={12}>
            <Card className="db_cardpadding p-3 mt-3">
              <p className="dashboard-item">Week deliveries</p>
              <span>{orders}</span>
            </Card>
          </Col>
          <Col md={3} xl={3} lg={3} sm={3} xs={12}>
            <Card className="db_cardpadding p-3 mt-3">
              <p className="dashboard-item">Week Paid</p>
              <span>{paid}</span>
            </Card>
          </Col>
          
          <Col md={3} xl={3} lg={3} sm={3} xs={12}>
            <Card className="db_cardpadding p-3 mt-3">
              <p className="dashboard-item">Week Un-Paid</p>
              <span>{unpaid}</span>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container fluid>

      

      <Row style={{marginTop: "30px"}}>
        <div>
        <DataTabelComponent
          title=""
          columns={columns}
          progressPending={adminInvoices?.isLoading}
          data={adminInvoicesData}
          pagination={true}
          fixedHeader={false}
          fixedHeaderScrollHeight="450px"
          selectableRows={true}
          selectableRowsHighlight={false}
          highlightOnHover={true}
          subHeader={true}
          // subHeaderComponent={
          //   <div className="w-100">
          //     <Row className="pt-4 pb-4">
          //       <Col lg={7} md={7} sm={7}>
          //         {" "}
          //       </Col>
          //       <Col lg={5} md={5} sm={5}>
          //           <input
          //             type="text"
          //             placeholder="search by name | phone"
          //             className="form-control w-70"
          //             // value={search}
          //             // onChange={(e) => setSearch(e.target.value)}
          //           />
          //         </Col>
          //     </Row>
          //   </div>
          // }
        />
        </div>
      </Row>
      
      </Container> */}
      <Container style={{ paddingTop: "10px", background: "#f7f7f8" }}>
        <div style={{ width: "100%" }}>
          <div
            className="box-sd"
            style={{
              width: "100%",
              backgroundColor: "#ffffff",
              padding: "20px",
            }}
          >
            <span
              style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '25px',
                lineHeight: '45px',
                color: '#363333',
                
              }}
            >
              Billing Details
            </span>
          </div>
          <Container>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                // alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="card-css">
                    <div
                      className="payment-info"
                      style={{ padding: "25px 0px 0px 25px", color: "white" }}
                    >
                      UnPaid Invoices
                    </div>
                    <div className="payment-price">$ {unpaid}</div>
                    {/* <div className="payment-date ">Due Date : Aug 2, 2023</div> */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "19px 25px 0px 25px ",
                      }}
                    >
                      <button className="payment-btn">View Details</button>
                      <button className="payment-btn">Pay Now</button>
                    </div> */}
                  </div>
                  <div className="card-css2">
                    <div
                      className="payment-info"
                      style={{
                        padding: "25px 0px 0px 25px",
                        color: "#4C535D",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Paid invoices</span>
                      <span>
                        <img
                          style={{ paddingRight: "15px" }}
                          alt="icon"
                          src={paymentIcon}
                        />
                      </span>
                    </div>
                    <div className="payment-price" style={{ color: "#4C535D" }}>
                      $ {paid}
                    </div>
                    {/* <div className="payment-date " style={{ color: "#4C535D" }}>
                      Due Date : Aug 2, 2023
                    </div> */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "19px 25px 0px 25px ",
                      }}
                    >
                      <button className="payment-btn">View Details</button>
                    </div> */}
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div>
                    <DataTabelComponent
                      title=""
                      columns={columns}
                      progressPending={adminInvoices?.isLoading}
                      data={adminInvoicesData}
                      pagination={true}
                      fixedHeader={false}
                      // fixedHeaderScrollHeight="450px"
                      // selectableRows={true}
                      selectableRowsHighlight={false}
                      highlightOnHover={true}
                      subHeader={true}
                      subHeaderComponent={
                        <div className="w-100">
                          <Row className="pt-2 pb-2 bill-table">
                            Invoices Summary
                            {/* <Col lg={7} md={7} sm={7}>
                              {" "}
                            </Col>
                            <Col lg={5} md={5} sm={5}>
                              <input
                                type="text"
                                placeholder="search by name | phone"
                                className="form-control w-70"
                                // value={search}
                                // onChange={(e) => setSearch(e.target.value)}
                              />
                            </Col> */}
                          </Row>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                {/* <BarChart /> */}
              </div>
            </div>
          </Container>
        </div>
      </Container>
      <div>
          <Modal isOpen={modal_static6} toggle={tog_static6} centered={true}>
          <ModalHeader className=" m_d_popup_header">
              <Container fluid>
                <div className="export-modal-header">
                  <h3>Export As</h3>
                  <span onClick={() => setModal_static6(false)}>X</span>
                </div>
              </Container>
            </ModalHeader>
            <ModalBody>
              <div className="suspend-driver-popup">
                <div className="bill-modal-btns-wrapper">
                    <Button
                      color="success"
                      outline
                      className="c_o_btns skip_shift_btn"
                      style={{borderRadius: "29px", width: "357px"}}
                      // onClick={() => window.print()}
                    >
                      Export as CSV File
                    </Button>
                  
                    <Button
                      color="success"
                      outline
                      className="c_o_btn1 skip_shift_btn"
                      style={{borderRadius: "29px", width: "357px"}}
                    >
                      Export as PDF File
                    </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
    </>
  );
};

export default Bills;
