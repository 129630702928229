/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getOrdersFunc,
} from "../../ApIs/orders";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { validationSchema, driverSchema } from "./ordersSchemas";
import "./css/driver.css";
import moment from "moment";
import { useFormik } from "formik";
import Table from "../../components/tables/Table";
import { RiSearch2Line, RiShoppingBasketLine } from "react-icons/ri";
import * as yup from "yup";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDots } from "react-icons/bs";
import edit_confirm from "../../images/modals/confirm.png";
import delete_confirm from "../../images/modals/delete.png";
import success from "../../images/modals/success.png";
import FormModal from "./FormModal";
import { VscChromeClose } from "react-icons/vsc";
import error_image from "../../images/modals/error-image.png";
import sent_email from "../../images/modals/sent-email.png";
import { useNavigate } from "react-router-dom";
import DriverModal from "./DriverModal";
import { getRidersFunc } from "../../ApIs/drivers";
import { getContractorFunc } from "../../ApIs/contractors";


const invitationSchema = {
  name: "",
  email: "",
};

const invitationValidation = yup.object().shape({
  name: yup.string().required("*name is required"),
  email: yup
    .string()
    .email("*must be a valid email")
    .required("*email is required"),
});

const Orders = () => {
  const [search, setSearch] = useState("");
  const [invitationDetails, setInvitationDetails] = useState(invitationSchema);
  const [status] = useState("assign");
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static6, setModal_static6] = useState(false);
  const [modal_static8, setModal_static8] = useState(false);
  const [modal_static9, setModal_static9] = useState(false);
  const [modal_success, setModal_success] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = React.useState(false);
  const [invitationModal, setInvitationModal] = React.useState(false);
  const [createRider, setCreateRider] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialRider, setInitialRider] = useState(driverSchema);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [riderId, setRiderId] = useState("");
  const token = localStorage.getItem("token");
  const [confirmModal, setConfirmModal] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [driverModal, setDriverModal] = useState(false);
  const [contractorModal, setContractorModal] = useState(false);
  const [rowData, setRowData] = useState()

  const {
    data: allOrders,
    refetch,
    isLoading,
  } = useQuery(["getorders"], () => fetchAllOrders());

  const fetchAllOrders = () => getOrdersFunc(token);

  const formik = useFormik({
    initialValues: initialRider,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    // onSubmit: (values) => {
    //   const newVals = { ...values };
    //   newVals.vat = countryCode + values.vat;
    //   isEdit ? editRiderMutateAsync(newVals) : addRiderMutateAsync(newVals);
    // },
  });

  const navigate = useNavigate();

  function capitalizeFirstLetters(text) {
    const words = text.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const capitalizedText = capitalizedWords.join(" ");
    return capitalizedText;
  }
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Created Date",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{row?.createdAt?.split("T")[0]}</span>
            <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>
            <span>{row?.createdAt ? moment(row?.createdAt).fromNow()  : "-"}</span>
          </div>
        );
      },
    },
    {
      name: "Shipment ID",
      width: "150px",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{marginTop: "5px"}}>{row?.shiftId}</span>
            {hoveredRowId === row._id && (
              <div className="actions__below">
                
                <span 
                  onClick={() => {
                    navigate(`/order/${row._id}`);
                  }}
                >
                    View
                </span>
                <span
                   onClick={() => {
                    navigate(`/shipping?id=${row._id}&duplicate=true`);
                  }}
                >
                  Duplicate
                </span>

                {
                  (row?.status === "assign" || row?.status === "pending") && (
                  <>
                    <span
                      onClick={() => {
                        navigate(`/shipping?id=${row._id}`);
                      }}
                    >
                      Edit
                    </span>
                    <span
                      onClick={() => {
                        setRowData(row);
                        setDriverModal(true);
                      }}
                    >
                      Assign Driver
                    </span>
                    <span
                      onClick={() => {
                        setRowData(row);
                        setContractorModal(true);
                      }}
                    >
                      Assign Contractor
                    </span>
                    
                  </>
                  )
                }
                <span 
                  onClick={() => {
                    navigate(`/tracking?search=${row?.shiftId}`);
                  }}
                >
                        Track Order
                  </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: "Customer Name",
      width: "150px",
      selector: (row, index) => (
        <span>{row?.customerName ? row?.customerName : "-"}</span>
      ),
    },
    {
      name: "Shipment Date",
      width: "150px",
      selector: (row) => (
        <span>{row?.shippmentDate ? row?.shippmentDate : "-"}</span>
      ),
    },
    // {
    //   name: "Assigned Driver",
    //   width: "150px",
    //   selector: (row) => <span>{row?.assignTo ? row?.assignTo : "-"}</span>,
    // },
    {
      name: "Truck Type",
      width: "150px",
      selector: (row) => (
        <span>{row?.truckType ? row?.truckType : "-"}</span>
      ),
    },
    {
      name: "Truck Number",
      width: "150px",
      selector: (row) => <span>{row?.truckNumber ? row?.truckNumber : "-"}</span>,
    },
    {
      name: "Trailer Number",
      width: "150px",
      selector: (row) => <span>{row?.trailerNumber ? row?.trailerNumber : "-"}</span>,
    },
    {
      name: "Count of Pickups",
      width: "150px",
      selector: (row) => (
        <span>{row?.totalPickup ? row?.totalPickup : "-"}</span>
      ),
    },
    {
      name: "Count of Destinations",
      width: "200px",
      selector: (row) => (
        <span>{row?.totalDelivery ? row?.totalDelivery : "-"}</span>
      ),
    },
    {
      name: "Status",
      selector: (row) => <span>{capitalizeFirstLetters(row?.status)}</span>,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <Dropdown dropleft="true">
              <Dropdown.Toggle
                className="mx-1 header-dropdown btn-primary custom-toggle"
                variant="secondary"
                id="dropdown-basic"
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  margin: "0",
                  padding: "0",
                  border: "none",
                  background: "none",
                }}
              >
                <BsThreeDots />
              </Dropdown.Toggle>
              <Dropdown.Menu menuPortalTarget={document.body}>
                <Dropdown.Item
                  onClick={() => {
                    navigate(`/order/${row._id}`);
                  }}
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate(`/shipping?id=${row._id}&duplicate=true`);
                  }}
                >
                  Duplicate
                </Dropdown.Item>
                {
                  (row?.status === "assign" || row?.status === "pending") && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          navigate(`/shipping?id=${row._id}`);
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setRowData(row);
                          setDriverModal(true);
                        }}
                      >
                        Assign Driver
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setRowData(row);
                          setContractorModal(true);
                        }}
                      >
                        Assign Contractor
                      </Dropdown.Item>
                    </>
                  )
                }
                {/* <Dropdown.Item
                  onClick={() => {
                    setModal_static8(true);
                    setRiderId(row?._id);
                  }}
                >
                  Delete
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // *************
  let AllRidersData;
  // *************

  useEffect(() => {
    queryClient.invalidateQueries("AllOrders");
  }, []);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [toggledClearRows] = React.useState(false);

  const handleChange = (state) => {
    const orders = state?.selectedRows?.map((contrator) => contrator._id);
    setSelectedRows(orders);
  };

  const DelRiderFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/users/${riderId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          refetch();
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static8(false);
        } else if (result3.status === 204) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          refetch();
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const DeleteRidersFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/users/detele-users`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        ids: selectedRows,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          refetch();
          addToast("Drivers Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static9(false);
        } else if (result3.status === 204) {
          refetch();
          addToast("Orders Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal_static9(false);
        } else {
          setModal_static9(false);
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) => {
        setModal_static9(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        });
        queryClient.invalidateQueries("AllOrders");
      });
  };

  const {
    values: inviteValues,
    errors: inviteErrors,
    resetForm: inviteReset,
    handleBlur,
    handleChange: handleInviteChange,
    handleSubmit: inviteSubmit,
  } = useFormik({
    initialValues: invitationDetails,
    validationSchema: invitationValidation,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: (values) => {
      sendInvitation(values);
    },
  });

  const sendInvitation = async ({ email, name }) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}api/users/invitation`;
      const formData = {
        type: "driver",
        name: name,
        email: email,
      };

      const response = await fetch(url, {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data?.success) {
        setInvitationModal(false);
        addToast(data?.message, { appearance: "success", autoDismiss: true });
        inviteReset();
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = allOrders?.data?.data?.filter((e) => {
      return (
        e?.customerName?.toLowerCase().match(search.toLowerCase()) ||
        e?.status?.toLowerCase().match(search.toLowerCase()) ||
        e?.shiftId?.toLowerCase().match(search.toLowerCase()) ||
        e?.shippmentDate?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckType?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckNumber?.toLowerCase().match(search.toLowerCase()) ||
        e?.trailerNumber?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalPickup)?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalDelivery)?.toLowerCase().match(search.toLowerCase()) ||
        e?.createdAt?.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilterTable(result);
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    const result = allOrders?.data?.data?.filter((e) => {
      return (
        e?.customerName?.toLowerCase().match(search.toLowerCase()) ||
        e?.status?.toLowerCase().match(search.toLowerCase()) ||
        e?.shiftId?.toLowerCase().match(search.toLowerCase()) ||
        e?.shippmentDate?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckType?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckNumber?.toLowerCase().match(search.toLowerCase()) ||
        e?.trailerNumber?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalPickup)?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalDelivery)?.toLowerCase().match(search.toLowerCase()) ||
        e?.createdAt?.toLowerCase().match(search.toLowerCase())
      );
    });


    if (result.length < 1) {
      setFilterTable([]);

    } else {
      setFilterTable(result);
    }
  };

  const actionOptions = [{ value: "delete", label: "Delete Selected" }];

  const actionsChangeFunc = (e) => {
    if (e.value === "delete") {
      setModal_static9(true);
    } else if (e.value === "detach") {
      setModal_static6(true);
    }
  };

  const sendInvite = () => {
    setSearchError(false);
    setInvitationModal(true);
  };

  const customStyles = {
    table: {
      borderRadius: "20px",
      style: {
        height: `${
          allOrders?.data?.data
            && allOrders?.data?.data?.length === 1 ?
              "250px"
              : "auto"
        }`,
      },
    },
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const handleRowClick = (row) => {
    const id = row?._id;
    navigate(`/order/${id}`);
  };
  const customRowClick = (row) => {
    handleRowClick(row);
  };

  const { data: allDrivers } = useQuery(["alldrivers"], () =>
    fetchAllDrivers()
  );
  const fetchAllDrivers = () => getRidersFunc(token);


  const { data: allContractors} = useQuery(["contractors"], () =>
    fetchAllContractors()
  );

  const fetchAllContractors = () => getContractorFunc(token);

  let debounceTimeout;
  const debounce = (func, key, delay) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
          func(key);
      }, delay);
  };

  const onDelaySearch = (search) => {
    const result = allOrders?.data?.data?.filter((e) => {
      return (
        e?.customerName?.toLowerCase().match(search.toLowerCase()) ||
        e?.status?.toLowerCase().match(search.toLowerCase()) ||
        e?.shiftId?.toLowerCase().match(search.toLowerCase()) ||
        e?.shippmentDate?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckType?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckNumber?.toLowerCase().match(search.toLowerCase()) ||
        e?.trailerNumber?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalPickup)?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalDelivery)?.toLowerCase().match(search.toLowerCase()) ||
        e?.createdAt?.toLowerCase().match(search.toLowerCase())
      );
    });

    if (result.length < 1) {
      setFilterTable([]);
    } else {
      setFilterTable(result);
    }
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value)
    const search = () => onDelaySearch(e.target.value);
    debounce(search, 300);
  };

  return (
    <div>
      {/* Driver Modal  */}
      {driverModal && (
        <DriverModal
          DriverModal={driverModal}
          setDriverModal={setDriverModal}
          data={allDrivers?.data?.riders}
          rowData={rowData}
          setRowData={setRowData}
          name="driver"
          refetch={refetch}
        />
      )}
      {/* Driver Modal  */}
      {contractorModal && (
        <DriverModal
          DriverModal={contractorModal}
          setDriverModal={setContractorModal}
          data={allContractors?.data?.contractors}
          rowData={rowData}
          setRowData={setRowData}
          name="contractor"
          refetch={refetch}
        />
      )}
      {modal_static8 && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img
                src={delete_confirm}
                alt=""
                style={{ width: "200px", height: "183px" }}
              />
              <p>Are you sure?</p>
              <p>You will lose all your driver details.</p>
              <div className="edit__btns">
                <button
                  className="edit_no"
                  onClick={() => setModal_static8(false)}
                >
                  No
                </button>
                <button className={`edit_yes`} onClick={DelRiderFunc}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal_static9 && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img
                src={delete_confirm}
                alt=""
                style={{ width: "200px", height: "183.04px" }}
              />
              <p>Are you sure?</p>
              <p>
                Do you really want to delete {selectedRows.length} drivers data?
              </p>
              <div className="edit__btns">
                <button
                  className="edit_no"
                  onClick={() => setModal_static9(false)}
                >
                  No
                </button>
                <button className="edit_yes" onClick={DeleteRidersFunc}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal_success && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img
                src={success}
                alt=""
                style={{ height: "166.54px", width: "155px" }}
              />
              <p>Success?</p>
              <p>A new driver was successfully created.</p>
              <div className="edit__btns">
                <button
                  // className={`${
                  //   editRiderLoading ? "disable edit_yes" : "edit_yes"
                  // }`}
                  style={{ width: "300px" }}
                  onClick={() => {
                    setModal_success(false);
                    refetch();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmModal && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={edit_confirm} alt="" />
              <p>Are you sure?</p>
              <p>Do you want to make these changings?</p>
              <div className="edit__btns">
                <button
                  className="edit_no"
                  onClick={() => {
                    setCreateRider(true);
                    setConfirmModal(false);
                  }}
                >
                  No
                </button>
                <button
                  // className={`${
                  //   editRiderLoading ? "disable edit_yes" : "edit_yes"
                  // }`}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {/* {editRiderLoading ? (
                    <AiOutlineLoading className="loaderIcon" size={18} />
                  ) : (
                    "Yes"
                  )} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {createRider && (
        <FormModal
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setCreateRider={setCreateRider}
          data={isEdit ? initialRider : initialRider}
          formik={formik}
          // addRiderLoading={addRiderLoading}
          // editRiderLoading={editRiderLoading}
          driverSchema={driverSchema}
          setInitialRider={setInitialRider}
          setConfirmModal={setConfirmModal}
          setCountryCode={setCountryCode}
          countryCode={countryCode}
        />
      )}
      <div className="breadcrumb">
        <RiShoppingBasketLine className="breadcrumb_icon" />
        <span>/</span>
        <p>Orders</p>
      </div>
      <div className="boder-table-com">
        <div>
          <Table
            title=""
            customStyles={customStyles}
            columns={columns}
            progressPending={isLoading}
            rowClicking={customRowClick}
            onRowHover={(id) => setHoveredRowId(id)}
            data={filterTable ? filterTable : allOrders?.data?.data}

            // data={allOrders?.data?.data ? allOrders?.data?.data : []}
            pagination={true}
            fixedHeader={false}
            fixedHeaderScrollHeight="1000px"
            selectableRowsHighlight={true}
            highlightOnHover={true}
            subHeader={true}
            subHeaderComponent={
              <>
                <div className="table__header">
                  <div className="table__left">
                    {/* <button
                    // onClick={handleSelectAllRows}
                    className="select__btn"
                  >
                    Select All
                  </button> */}
                    
                  </div>
                  <div className="table__right">
                    <form onSubmit={(e) => onSearch(e)} action="">

                      <div className="table__Search">
                        <div className="input_search">
                          <RiSearch2Line onClick={(e) => onSearch(e)} style={{ color: "#bebebe", width: "18px", height: "18px", cursor: "pointer" }} />
                        </div>
                        <input
                          type="text"
                          placeholder="Search by anything"
                          value={search}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </form>
          
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {searchError && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setSearchError(false);
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={error_image} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun">
                      No driver was found with provided keyword.
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="">
                      <button
                        className="invite_btn"
                        onClick={() => {
                          sendInvite();
                        }}
                      >
                        Send them invite via link
                      </button>
                      <br />
                      <button
                        className="error_create_new"
                        onClick={() => {
                          setSearchError(false);
                          setCreateRider(true);
                        }}
                      >
                        Create New
                      </button>
                      <br />
                      <button
                        className="invite_btn"
                        onClick={() => setSearchError(false)}
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {invitationModal && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setInvitationModal(false);
                      inviteReset();
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={sent_email} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun sent_invite">
                      Please enter the required details correctly. Thanks!
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="invite__inputs">
                      <div className="input__with__error flex justify-center">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Full Name
                          </label>
                          <div
                            className={
                              inviteErrors.name
                                ? "error_border_input invite_input_field"
                                : "assign_rate_with_logo0 invite_input_field"
                            }
                          >
                            <input
                              name="name"
                              onChange={handleInviteChange}
                              onBlur={handleBlur}
                              value={inviteValues.name}
                              placeholder="Enter Full Name"
                              type="text"
                            />
                            <span className="margin-left-80">
                              {inviteErrors.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="input__with__error flex justify-center">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Email Address
                          </label>
                          <div
                            className={
                              inviteErrors.email
                                ? "error_border_input invite_input_field"
                                : "assign_rate_with_logo0 invite_input_field"
                            }
                          >
                            <input
                              name="email"
                              onChange={handleInviteChange}
                              onBlur={handleBlur}
                              placeholder="Enter Email"
                              value={inviteValues.email}
                              type="text"
                            />
                            <span className="margin-left-80">
                              {inviteErrors.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button
                          className="error_create_new error_create_new"
                          onClick={() => inviteSubmit()}
                          style={loading ? { cursor: "no-drop" } : {}}
                        >
                          {loading ? "Sending ..." : "Send An Email"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
