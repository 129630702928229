import * as yup from "yup";
export const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("*First Name is required"),
  lastName: yup.string().trim().required("*Last Name is required"),
  email: yup.string().trim().email("*enter valid email").required("*email is required"),
  mobileNumber: yup.string().trim().required("*phone is required"),
  passportNo: yup.string().trim()
  .test('either-passportNo-or-nationalid', 'passport or national id is required', function (value, context) {
    const { nationalid } = context.parent; 
    if (!value && !nationalid) {
      return false; 
    }
    return true; 
  }),
nationalid: yup.string().trim()
  .test('either-passportNo-or-nationalid', 'passport or national id is required', function (value, context) {
    const { passportNo } = context.parent; 
    if (!value && !passportNo) {
      return false; 
    }
    return true;
  }),
  company: yup.string().trim().required("*company is required"),
  jobTitle: yup.string().trim().required("*job title is required"),
  state: yup.string().trim().required("*state is required"),
  city: yup.string().trim().required("*city is required"),
  country: yup.string().trim().required("*Country is required"),
  postalCode: yup.string().required("*Postal Code  is required"),
  address: yup.string().trim().required("*address is required"),
})

export const driverSchema = {
  _id:"",
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  passportNo: "",
  company: "",
  jobTitle: "",
  state: "",
  city: "",
  country: "",
  postalCode: "",
  address: "",
  nationalid:""
};
