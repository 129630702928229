// ****** customers create Api******
export const createShipmentFunc = async (token, data, addToast) => {
  delete data._id;
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/users/add-shipment`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    addToast(resJson.error, {
      appearance: "error",
      autoDismiss: true,
    });
    throw new Error(resJson.error.message);
  }

  const responseData = await res.json();

  return {
    data: responseData,
  };
};
