// GET All Shipments
export const getShipments = async (token) => {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/allShipments`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
  
    return {
      data,
    };
};

export const getShipmentDetail = async (token, id) => {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/${id}`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();

    return {
      data,
    };
};

export const searchShipment = async (token, keyword) => {
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/search/${keyword}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
};