import React, {useState} from "react";
import Table from "../../components/tables/Table";
import { useQuery } from "react-query";
import {
  getOrderFunc,
} from "../../ApIs/orders";
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { RiShoppingBasketLine } from "react-icons/ri";
import EyeIcon from "../../images/editDelete/eye.svg";
import left_slider from '../../images/icons/left_slider.svg';
import right_slider from '../../images/icons/right_slider.svg';
import CloseIcon from "../../images/editDelete/cross.png";

import {
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs  } from "swiper";


const OrderDetail = () => {
  const token = localStorage.getItem("token");
  const [rowData, setRowData] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [modal_static7, setModal_static7] = useState(false);

  const tog_static7 = () => {
    setModal_static7(!modal_static7);
    setRowData([])
    setThumbsSwiper(null)
    setCurrentIndex(0)
    removeBodyCss();
  };



  const { id } = useParams();
  const {
    data: order,
    refetch,
    isLoading,
  } = useQuery(["order"], () => fetchAllOrders());


  const fetchAllOrders = () => getOrderFunc(token, id);

  function capitalizeFirstLetters(text) {
    const words = text.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const capitalizedText = capitalizedWords.join(" ");
    return capitalizedText;
  }

  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "View POD",
      cell: (row) => (
        <>
          <span onClick={() => {
            setModal_static7(true);
            // setOrderId(row?._id);
          }}>
            {
              row?.receiverDetail?.deliveryImages.length === 0 ? "No POD" : (

                <img onClick={() => setRowData(row)} alt="eye-icon" src={EyeIcon} style={{width: "17px", marginRight: "5px", cursor: "pointer"}} />  
              )
            }
          </span>
        </>
      ),
    },
    {
      name: "Shipment ID",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{row?.pickupDetail?.shipmentId}</span>
          </div>
        );
      },
    },
    {
      name: "Pickup Customer",
      selector: (row, index) => {
        return (
          <span>
            {row?.pickupDetail?.name ? row?.pickupDetail?.name : "-"}
          </span>
        );
      },
    },
    {
      name: "Pickup Address",
      selector: (row, index) => {
        return (
          <span>
            {row?.pickupDetail?.address ? row?.pickupDetail?.address : "-"}
          </span>
        );
      },
    },
    {
      name: "Pickup Date / Time",
      width: "150px",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {row?.pickupDetail?.date ? row?.pickupDetail?.date : "-"}
            </span>
            <span>
              {moment(row?.pickupDetail?.arrivalTime).format("h:mm:ss a")
                ? moment(row?.pickupDetail?.arrivalTime).format("h:mm:ss a")
                : "-"}
            </span>
          </div>
        );
      },
    },
    {
      name: "Receiver Customer",
      selector: (row, index) => {
        return (
          <span>
            {row?.receiverDetail?.name ? row?.receiverDetail?.name : "-"}
          </span>
        );
      },
    },
    {
      name: "Receiver Address",
      width: "150px",
      selector: (row, index) => {
        return (
          <span>
            {row?.receiverDetail?.address ? row?.receiverDetail?.address : "-"}
          </span>
        );
      },
    },
    {
      name: "Receiver Date / Time",
      width: "150px",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {row?.receiverDetail?.date ? row?.receiverDetail?.date : "-"}
            </span>
            <span>
              {moment(row?.receiverDetail?.arrivalTime).format("h:mm:ss a")
                ? moment(row?.receiverDetail?.arrivalTime).format("h:mm:ss a")
                : "-"}
            </span>
          </div>
        );
      },
    },
    
    {
      name: "Status",
      selector: (row, index) => {
        return (
          <span>
            {capitalizeFirstLetters(row?.receiverDetail?.status)
              ? capitalizeFirstLetters(row?.receiverDetail?.status)
              : "-"}
          </span>
        );
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const shipmentId = order?.data?.data[0]?.pickupDetail?.shipmentId;

 
  return (
    <div>
      <div className="breadcrumb">
        <RiShoppingBasketLine className="breadcrumb_icon" />
        <span>/</span>
        <p>Order Details</p>
        <span>/</span>
        <Link to={`/tracking?search=${shipmentId}`}>{shipmentId}</Link>
      </div>
      <div>
        <Table
          title=""
          customStyles={customStyles}
          columns={columns}
          progressPending={isLoading}
          data={order?.data?.data ? order?.data?.data : []}
          pagination={true}
          fixedHeader={false}
          fixedHeaderScrollHeight="1000px"
          // selectableRows={true}
          // onSelectedRowsChange={handleChange}
          // selectCheckboxProps={{
          //   checked: (rowData) => selectedRows.includes(rowData?._id),
          //   onClick: (e, rowData) => {
          //     const rowId = rowData?._id;
          //     if (selectedRows.includes(rowId)) {
          //       setSelectedRows(selectedRows.filter((id) => id !== rowId));
          //     } else {
          //       setSelectedRows([...selectedRows, rowId]);
          //     }
          //   },
          // }}
          // clearSelectedRows={toggledClearRows}
          selectableRowsHighlight={true}
          highlightOnHover={true}
          subHeader={true}
        />
      </div>
      <div>
          <Modal isOpen={modal_static7} toggle={tog_static7} centered={true}>
              <ModalHeader className="m_d_popup_header details-modal">
              <div className="w-100">
                  <Row>
                    <Col lg={10} md={10} sm={10}>
                      <p>Proof Of Delivery</p>
                    </Col>
                    <Col lg={2} md={2} sm={2}>
                      <span onClick={() => {
                        setModal_static7(false)
                        setRowData([])
                        setThumbsSwiper(null)
                        setCurrentIndex(0)
                      }}>
                        <img alt="eye-icon" src={CloseIcon} style={{width: "17px", cursor: "pointer"}} />  
                      </span>
                    </Col>
                  </Row>
                </div>
              </ModalHeader>
              <ModalBody style={{border: "5px solid #354faa"}}>
                <div className="order-details-popup-main">
                  <div className="order-details-popup">
                  {
                    rowData?.receiverDetail?.deliveryImages.length > 0 && (
                      <>
                          <Swiper
                            style={{
                              "--swiper-navigation-color": "#fff",
                              "--swiper-pagination-color": "#fff",
                            }}
                            loop={false}
                            spaceBetween={10}
                            navigation={{
                              nextEl: ".swiper-btn-next",
                              prevEl: ".swiper-btn-prev",
                            }}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                            onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
                          >
                            {
                              rowData?.receiverDetail?.deliveryImages.map(item => (
                                <SwiperSlide>
                                  <img src={item} alt=""/>
                                </SwiperSlide>
                              ))
                            }
                          
                          </Swiper>
                          <div className="slider-controler">
                            {rowData?.receiverDetail?.deliveryImages.length > 1 && (
                              <>
                                <div className={`swiper-btn-prev slider-arrow hidden ${currentIndex > 0 ? "display" : ""}`}>
                                  <img src={left_slider} alt="left-arrow" />
                                </div>
                                <div className={`swiper-btn-next slider-arrow hidden ${currentIndex < rowData.deliverConfirmDoc.length - 1 ? "display" : ""}`}>
                                  <img src={right_slider} alt="right-arrow" />
                                </div>
                              </>
                            )}
                          </div>
                        <Swiper
                          onSwiper={setThumbsSwiper}
                          loop={false}
                          spaceBetween={10}
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper"
                        >
                          {
                            rowData?.receiverDetail?.deliveryImages.map(item => (
                              <SwiperSlide>
                                <img src={item} alt=""/>
                              </SwiperSlide>
                            ))
                          }
                        </Swiper>
                      </>
                    )
                  }
                  
                  </div>
                  <div className="details-wrapper">
                    <div className="detail">
                      <h5>Receiver Name: </h5>
                      <p>{rowData?.receiverDetail?.name}</p>
                    </div>
                    <div className="detail">
                      <h5>Delivery Date: </h5>
                      <p>{rowData?.receiverDetail?.arrivalTime?.split("T")[0]}</p> 
                    </div>
                    <div className="detail last-detail">
                      <h5>Delivery Time: </h5>
                      <p className="last-detail">{rowData?.receiverDetail?.arrivalTime?.split("T")[1]?.split(".")[0]}</p>
                    </div>
                  </div>
                </div>
              </ModalBody>
          </Modal>
        </div>
    </div>
  );
};

export default OrderDetail;
