import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const App = () => {
  const data = [
    { day1: "M", sum: 400 },
    { day: "T", sum1: 700 },
    { day1: "W", sum: 200 },
    { day: "T", sum1: 1000 },
    { day1: "F", sum: 200 },
    { day: "S", sum1: 3000 },
    { day1: "S", sum: 100 },

    { day: "" },
  ];

  return (
    // <ResponsiveContainer>
    <div
      style={{
        width: "100%",
        // height: "400",
        background: "#F9FFFD",
        borderRadius: "14px",
        margin: "10px",
        padding: "14px 0px 14px 0px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <div className="bill-text">
          <div>Usage Analytics</div>
          <div>(Last 7 Days)</div>
        </div>
      </div>
      <BarChart
        width={300}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 2,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid /> */}
        <XAxis dataKey="day1" />
        <YAxis AxisComp="sum" />
        {/* <Bar dataKey="students" fill="green" /> */}
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Bar dataKey="sum" fill="#f25b7f" />
        <Bar dataKey="sum1" fill="#0cd8a1" />

        {/* <Bar dataKey="day" fill="#82ca9d" /> */}
      </BarChart>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <button className="view-analytics"> View Analytics</button> */}
      </div>
    </div>
  );
};

export default App;
