import React from 'react'
import { Link } from 'react-router-dom'
import "./css/manageShifts.css"

const CreateShift = () => {
  return (
    <div className='create-shift-main'>
        <h4>Before You Create Shift, You Should Create Orders</h4>
        <div className='create-shift-btns-wrapper'>
            <Link to="/create_order">
                <button>Create Orders</button>
            </Link>
            <Link to="/unassigned_orders">
                <button>Create Shift from Existing Orders</button>
            </Link>
           
        </div>
    </div>
  )
}

export default CreateShift