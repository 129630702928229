import React from "react";
import "./style.scss";

const Index = () => {
  const [toggle, setToggle] = React.useState(false);
  const [checked, setChecked] = React.useState("Action");
  const openDropdowm = (e) => {
    setToggle(!toggle);
    setChecked(e.target.innerText);
    e.preventDefault();
    e.stopPropagation();
    
  };
  return (
    <div>
      <span
        onClick={openDropdowm}
        className={`dropdown-el ${toggle ? "expanded" : ""}`}
      >
        <input
          type="radio"
          name="sortType"
          placeholder="Action"
          value="Action"
          checked={checked === "Action" ? "checked" : ""}
          id="sort-relevance"
        />
        <label className="lb-center" for="sort-relevance" value="Action">
          Action
        </label>
        <input
          type="radio"
          name="sortType"
          value="Edit"
          checked={checked === "Edit" ? "checked" : ""}
          id="sort-relevance"
        />
        <label className="lb-center" for="sort-relevance" value="Edit">
          Edit
        </label>
        <input
          type="radio"
          name="sortType"
          value="Delete"
          id="sort-best"
          checked={checked === "Delete" ? "checked" : ""}
        />
        <label className="lb-center" for="sort-best" value="Delete">
          Delete
        </label>
        <input
          type="radio"
          name="sortType"
          checked={checked === "ReAsign" ? "checked" : ""}
          value="ReAsign"
          id="sort-low"
        />
        <label className="lb-center" for="sort-low " value="ReAsign">
          ReAsign
        </label>
        <input
          type="radio"
          name="sortType"
          checked={checked === "View-Map" ? "checked" : ""}
          value="View-Map"
          id="sort-low"
        />
        <label className="lb-center" for="sort-low " value="View-Map">
          View-Map
        </label>
      </span>
    </div>
  );
};

export default Index;
