import React from "react";
import { GoogleMap, MarkerF, Polyline } from "@react-google-maps/api";

import PickUp from "../../../images/icons/pickup-location.svg";
import Delivery from "../../../images/icons/delivery-location.svg";
import Stop from "../../../images/icons/stop-sign.png";


function Map({pickupLocations,deliveryLocations, points1, points2, packages, events1, events2}) {

  // Extract the coordinates from the valid points array
  const path1 = points1?.coordinates?.map(([lng, lat]) => ({
    lat,
    lng
  }));


  const path1Events = events1?.map((event) => ({
    lat: event?.latitude,
    lng: event?.longitude,
    type: event?.schedule?.scheduleTypes[0] ? event?.schedule?.scheduleTypes[0] : ""
  }))

  

  // Extract the coordinates from the valid points array
  const path2 = points2?.coordinates?.map(([lng, lat]) => ({
    lat,
    lng
  }));

  const path2Events = events2?.map((event) => ({
    lat: event.latitude,
    lng: event.longitude,
    type: event?.schedule?.scheduleTypes[0] ? event?.schedule?.scheduleTypes[0] : ""
  }))


  

    // Step 1: Create sets of unique pickup and delivery IDs from packages
    const packagePickupIds = new Set(packages?.map(packageData => packageData.pickup_id));
    const packageDeliveryIds = new Set(packages?.map(packageData => packageData.delivery_id));

    // Step 2 and 3: Filter the pickup and deliveries arrays to remove unreferenced objects
    const filteredPickup = pickupLocations?.filter(pickupItem => packagePickupIds.has(pickupItem._id));
    const filteredDeliveries = deliveryLocations?.filter(deliveryItem => packageDeliveryIds.has(deliveryItem._id));
  

  const renderMarkers = () =>  {
    // const { pickupLocations, deliveryLocations } = this.props;

    const markers = [];

    // Render markers for pickup locations
    filteredPickup?.forEach(pickup => {
      markers.push(
        <MarkerF
          key={pickup._id}
          position={{ lat: pickup.lat, lng: pickup.lng }}
          title={'Pickup: ' + pickup.name}
          icon={PickUp}
        />
      );
    });

    // Render markers for delivery locations
    filteredDeliveries?.forEach(delivery => {
      markers.push(
        <MarkerF
          key={delivery._id}
          position={{ lat: delivery.lat, lng: delivery.lng }}
          title={'Delivery: ' + delivery.name}
          icon={Delivery}
        />
      );
    });


    path1Events?.forEach(point => {
      if(point.type === "BREAK"){
        markers.push(
          <MarkerF
            // key={delivery._id}
            position={{ lat: point.lat, lng: point.lng }}
            // title={'Delivery: ' + delivery.name}
            icon={Stop}
          />
        );
      }
    });


    path2Events?.forEach(point => {
      if(point.type === "BREAK"){
        markers.push(
          <MarkerF
            // key={delivery._id}
            position={{ lat: point.lat, lng: point.lng }}
            // title={'Delivery: ' + delivery.name}
            icon={Stop}
          />
        );
      }
    });

    return markers;
  }


  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  };



  const mapOptions = {
    gestureHandling: "greedy",
    zoom: 10,
    center: pickupLocations?.length > 0 ? {lat: pickupLocations[0].lat, lng: pickupLocations[0].lng} : null, // Set the center to the first location in the path
  };



  return (    
    <GoogleMap
      onLoad={handleOnLoad}
      mapContainerStyle={{ width: "100%", height: "50vh" }}
      // zoom={10}
      // position={center}
      
      options={mapOptions}
    >
      

      {renderMarkers()}

      <Polyline
          path={path1}
          options={{
            strokeColor: 'green',
            strokeOpacity: 1.0,
            strokeWeight: 4,
          }}
        />
        <Polyline
          path={path2}
          options={{
            strokeColor: 'red',
            strokeOpacity: 1.0,
            strokeWeight: 4,
          }}
        />
      
    </GoogleMap>
  );
}

export default React.memo(Map);