/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import customer_breadcrumb from '../../images/icons/customer_breadcrumb.png'
import Select from "react-select";

import { useQuery, useQueryClient, useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { validationSchema, customerSchema } from "./customerSchemas";
import { AiOutlineLoading } from 'react-icons/ai';

import "./css/customer.css";
import moment from "moment";
import { useFormik } from "formik";
import Table from "../../components/tables/Table";
import { RiSearch2Line } from 'react-icons/ri'
import * as yup from 'yup';
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDots } from "react-icons/bs";
import edit_confirm from "../../images/modals/confirm.png"
import delete_confirm from "../../images/modals/delete.png"
import FormModal from "./FormModal";
import success from "../../images/modals/success.png"
import { VscChromeClose } from "react-icons/vsc";
import error_image from "../../images/modals/error-image.png";
import sent_email from "../../images/modals/sent-email.png";
import { createAdminFunc, editAdminFunc, getAdminsFunc } from "../../ApIs/admins";


const invitationSchema = {
  name: "",
  email: ""
}

const invitationValidation = yup.object().shape({
  name: yup.string().required("*name is required"),
  email: yup.string().email("*must be a valid email").required("*email is required"),
})

const Admins = () => {
  const [search, setSearch] = useState("");
  const [invitationDetails, setInvitationDetails] = useState(invitationSchema);
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static6, setModal_static6] = useState(false);
  const [modal_static8, setModal_static8] = useState(false);
  const [modal_static9, setModal_static9] = useState(false);
  const [modal_success, setModal_success] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = React.useState(false);
  const [invitationModal, setInvitationModal] = React.useState(false);
  const [createCustomer, setCreateCustomer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialCustomer, setInitailCustomer] = useState(customerSchema);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [customerId, setCustomerId] = useState("");
  const token = localStorage.getItem("token");
  const [confirmModal, setConfirmModal] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  const { data: allCustomers, refetch, isLoading } = useQuery(["customers"], () =>
    fetchAllCustomers()
  );

  const fetchAllCustomers = () => getAdminsFunc(token);

  const { mutateAsync: addCustomerMutateAsync, isLoading: addCustomerLoading } =
    useMutation((data) => createAdminFunc(token, data, addToast), {
      onSuccess: () => {
        addToast("Add Admin Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        refetch();
        setCreateCustomer(false);
        setIsEdit(false);
        setInitailCustomer(customerSchema);
        formik.resetForm();
        setModal_success(true);
      },
    });

  const {
    mutateAsync: editCustomerMutateAsync,
    isLoading: editCustomerLoading,
  } = useMutation((data) => editAdminFunc(token, data, addToast), {
    onSuccess: () => {
      addToast("Edit Admin Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      refetch();
      setCreateCustomer(false);
      setIsEdit(false);
      setInitailCustomer(customerSchema);
      formik.resetForm();
      setConfirmModal(false);
    },
  });

  const formik = useFormik({
    initialValues: initialCustomer,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      const newVals = { ...values }
      newVals.plan = {
        id: values?.plan?.value,
        name: values?.plan?.label,
        duration: values?.duration?.value,
      }
      newVals.vat = countryCode + values.vat
      isEdit ? editCustomerMutateAsync(newVals) : addCustomerMutateAsync(newVals);
    },
  });

  const [hoveredRowId, setHoveredRowId] = useState(null);

  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Created Date",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{row?.createdAt?.split("T")[0]}</span>
            <span>{row?.createdAt?.split("T")[1]?.split(".")[0]}</span>
            <span>{moment(row?.createdAt).fromNow()}</span>
          </div>
        );
      },
    },
    {
      name: "First Name",
      selector: (row) => {
        return (
          <div>
            <span>{row.firstName}</span>
            {hoveredRowId === row._id && (
              <div className="actions__below">
                <span
                  onClick={() => {
                    setInitailCustomer({
                      _id: row?._id,
                      firstName: row?.firstName,
                      lastName: row?.lastName,
                      email: row?.email,
                      mobileNumber: row?.mobileNumber,
                      vat: row?.vat,
                      company: row?.company,
                      jobTitle: row?.jobTitle,
                      state: row?.state,
                      city: row?.city,
                      country: row?.country,
                      postalCode: row?.postalCode,
                      address: row?.address,
                      duration: {
                        label: row.plan?.duration?.toUpperCase(),
                        value: row.plan?.duration
                      },
                      plan: row.plan?.id,
                      firstNameOperation: row?.firstNameOperation,
                      lastNameOperation: row?.lastNameOperation,
                      emailOperation: row?.emailOperation,
                      mobileNumberOperation: row?.mobileNumberOperation,
                      jobTitleOperation: row?.jobTitleOperation,
                      firstNameFinance: row?.firstNameFinance,
                      lastNameFinance: row?.lastNameFinance,
                      emailFinance: row?.emailFinance,
                      mobileNumberFinance: row?.mobileNumberFinance,
                      jobTitleFinance: row?.jobTitleFinance,
                    });
                    setCreateCustomer(true);
                    setIsEdit(true);
                  }}
                >
                  Edit
                </span>
                <span
                  onClick={() => {
                    setModal_static8(true);
                    setCustomerId(row?._id);
                  }}
                  className="delete_action"
                >
                  Delete
                </span>
              </div>
            )}
          </div>
        )
      }
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Email",
      width: "150px",
      selector: (row) => row.email,
    },
    {
      name: "Plan",
      width: "150px",
      selector: (row) => row?.plan?.name || row?.plan?.id,
    },
    {
      name: "Duration",
      width: "150px",
      selector: (row) => row.plan?.duration?.toUpperCase(),
    },
    {
      name: "Mobile Number",
      width: "150px",
      selector: (row) => row.mobileNumber,
    },
    {
      name: "vat",
      selector: (row) => row?.vat,
    },
    {
      name: "Company",
      selector: (row) => row?.company,
    },
    {
      name: "Job Title",
      selector: (row) => row?.jobTitle,
    },
    {
      name: "city",
      selector: (row) => row?.city,
    },
    {
      name: "country",
      selector: (row) => row.country,
    },
    {
      name: "postalCode",
      selector: (row) => row.postalCode,
    },
    {
      name: "address",
      width: "200px",
      selector: (row) => row.address,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <Dropdown dropleft="true">
              <Dropdown.Toggle
                className="mx-1 header-dropdown btn-primary custom-toggle"
                variant="secondary"
                id="dropdown-basic"
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  margin: "0",
                  padding: "0",
                  border: "none",
                  background: "none"
                }}
              >
                <BsThreeDots />
              </Dropdown.Toggle>
              <Dropdown.Menu menuPortalTarget={document.body}>
                <Dropdown.Item
                  onClick={() => {
                    setInitailCustomer({
                      _id: row?._id,
                      firstName: row?.firstName,
                      lastName: row?.lastName,
                      email: row?.email,
                      mobileNumber: row?.mobileNumber,
                      vat: row?.vat,
                      company: row?.company,
                      jobTitle: row?.jobTitle,
                      state: row?.state,
                      city: row?.city,
                      country: row?.country,
                      postalCode: row?.postalCode,
                      address: row?.address,
                      duration: {
                        label: row.plan?.duration?.toUpperCase(),
                        value: row.plan?.duration
                      },
                      plan: row.plan?.id,
                      firstNameOperation: row?.firstNameOperation,
                      lastNameOperation: row?.lastNameOperation,
                      emailOperation: row?.emailOperation,
                      mobileNumberOperation: row?.mobileNumberOperation,
                      jobTitleOperation: row?.jobTitleOperation,
                      firstNameFinance: row?.firstNameFinance,
                      lastNameFinance: row?.lastNameFinance,
                      emailFinance: row?.emailFinance,
                      mobileNumberFinance: row?.mobileNumberFinance,
                      jobTitleFinance: row?.jobTitleFinance,
                    });
                    setCreateCustomer(true);
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setModal_static8(true);
                    setCustomerId(row?._id);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // *************
  const AllCustomersData = allCustomers?.data?.admins;
  // *************

  useEffect(() => {
    queryClient.invalidateQueries("AllOrders");
  }, []);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [toggledClearRows] = React.useState(false);

  const handleChange = (state) => {
    const orders = state?.selectedRows?.map((order) => order._id);
    setSelectedRows(orders);
  };

  const DelOrderFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/users/${customerId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          refetch();
          setModal_static8(false);
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (result3.status === 204) {
          setModal_static8(false);
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          refetch();
          queryClient.invalidateQueries("AllOrders");
        } else {
          setModal_static8(false);
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const DeleteOrdersFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}api/users/detele-users`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        ids: selectedRows,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          setModal_static9(false);
          addToast("Orders Successfully Deleted, orders will be removed", {
            appearance: "success",
            autoDismiss: true,
          });
          refetch();
        } else if (result3.status === 204) {
          setModal_static9(false);
          addToast("Orders Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });
          refetch();
        } else {
          setModal_static9(false);
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          queryClient.invalidateQueries("AllOrders");
        }
      })
      .catch((error) => {
        setModal_static9(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        });
        queryClient.invalidateQueries("AllOrders");
      });
  };

  const {
    values: inviteValues,
    errors: inviteErrors,
    resetForm: inviteReset,
    handleBlur,
    handleChange: handleInviteChange,
    handleSubmit: inviteSubmit
  } = useFormik({
    initialValues: invitationDetails,
    validationSchema: invitationValidation,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,

    onSubmit: (values) => {
      sendInvitation(values);
    }
  })

  const sendInvitation = async ({ email, name }) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}api/users/invitation`;
      const formData = {
        type: "customer",
        name: name,
        email: email
      }

      const response = await fetch(url, {
        method: 'POST',
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data?.success) {
        setInvitationModal(false);
        addToast(data?.message, { appearance: "success", autoDismiss: true });
        inviteReset();
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }

  };

  useEffect(() => {
    const result = AllCustomersData?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.vat?.toString() === (search)
      );
    });
    setFilterTable(result);
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    const result = AllCustomersData?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.vat?.toString() === (search)
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  };

  const actionOptions = [{ value: "delete", label: "Delete Selected" }];




  const customStyles = {
    table: {
      borderRadius: "20px",
      style: {
        height: `${filterTable ? filterTable?.length === 1 ? "200px" : "auto" : AllCustomersData?.length === 1 ? "200px" : "auto"
          }`
      }

    },
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  let debounceTimeout;
  const debounce = (func, key, delay) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      func(key);
    }, delay);
  };

  const onDelaySearch = (search) => {
    const result = AllCustomersData?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.vat?.toString() === (search)
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value)
    const search = () => onDelaySearch(e.target.value);
    debounce(search, 1000);
  };

  return (
    <div>
      {modal_static8 && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={delete_confirm} alt="" style={{ width: "200px", height: "183px" }} />
              <p>Are you sure?</p>
              <p>
                You will lose all your admins details.
              </p>
              <div className="edit__btns">
                <button className="edit_no" onClick={() => setModal_static8(false)}>
                  No
                </button>
                <button
                  className={`edit_yes`}
                  onClick={DelOrderFunc}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal_static9 && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={delete_confirm} alt="" style={{ width: "200px", height: "183.04px" }} />
              <p>Are you sure?</p>
              <p>
                Do you really want to delete {selectedRows.length} admins data?
              </p>
              <div className="edit__btns">
                <button className="edit_no" onClick={() => setModal_static9(false)}>
                  No
                </button>
                <button
                  className="edit_yes"
                  onClick={DeleteOrdersFunc}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal_success && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={success} alt="" style={{ height: "166.54px", width: "155px" }} />
              <p>Success?</p>
              <p>
                A new admin was successfully created.
              </p>
              <div className="edit__btns">
                <button
                  className={`${editCustomerLoading ? "disable edit_yes" : "edit_yes"}`}
                  style={{ width: "300px" }}
                  onClick={() => {
                    setModal_success(false);
                    queryClient.invalidateQueries('customers');
                    refetch();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmModal && (
        <div className="modalMask">
          <div className="confirm-edit" style={{ background: "#fff" }}>
            <div className="confirm-edit-body">
              <img src={edit_confirm} alt="" />
              <p>Are you sure?</p>
              <p>
                Do you want to make these changings?
              </p>
              <div className="edit__btns">
                <button className="edit_no" onClick={() => {
                  setCreateCustomer(true);
                  setConfirmModal(false)
                }}>
                  No
                </button>
                <button
                  className={`${editCustomerLoading ? "disable edit_yes" : "edit_yes"} `}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {editCustomerLoading ? <AiOutlineLoading className="loaderIcon" size={18} /> : "Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        createCustomer && (
          <FormModal
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setCreateCustomer={setCreateCustomer}
            data={isEdit ? initialCustomer : initialCustomer}
            formik={formik}
            addCustomerLoading={addCustomerLoading}
            editCustomerLoading={editCustomerLoading}
            customerSchema={customerSchema}
            setInitailCustomer={setInitailCustomer}
            setConfirmModal={setConfirmModal}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
      )}
      <div className="breadcrumb">
        <img src={customer_breadcrumb} alt="truck-logo" />
        <span>/</span>
        <p>Admins</p>
      </div>
      <div className="boder-table-com">
        <div>
          <Table
            title=""
            customStyles={customStyles}
            columns={columns}
            progressPending={isLoading}
            data={filterTable ? filterTable : AllCustomersData}
            pagination={true}
            fixedHeader={false}
            onRowHover={(id) => setHoveredRowId(id)}
            fixedHeaderScrollHeight="1000px"
            selectableRows={true}
            onSelectedRowsChange={handleChange}
            selectCheckboxProps={{
              checked: (rowData) => selectedRows.includes(rowData?._id),
              onClick: (e, rowData) => {
                const rowId = rowData?._id;
                if (selectedRows.includes(rowId)) {
                  setSelectedRows(selectedRows.filter((id) => id !== rowId));
                } else {
                  setSelectedRows([...selectedRows, rowId]);
                }
              },
            }}
            clearSelectedRows={toggledClearRows}
            selectableRowsHighlight={true}
            highlightOnHover={true}
            subHeader={true}
            subHeaderComponent={
              <>
                <div className="table__header">
                  <div className="table__left">
                    
                  </div>
                  <div className="table__right">
                    <form onSubmit={(e) => onSearch(e)} action="">
                      <div className="table__Search">
                        <div className="input_search">
                          <RiSearch2Line onClick={(e) => onSearch(e)} style={{ color: "#bebebe", width: "18px", height: "18px", cursor: "pointer" }} />
                        </div>
                        <input
                          type="text"
                          placeholder="Search by first name/last name/VAT ID/email"
                          value={search}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </form>
                    <button
                      onClick={() => setCreateCustomer(true)}
                      className="create__btn"
                    >
                      Create New
                    </button>
                  </div>

                </div>
              </>
            }
          />
        </div>
      </div>
      {searchError && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setSearchError(false);
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={error_image} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun">
                      No customer was found with provided keyword.
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="">
                      
                      <button
                        className="error_create_new"
                        onClick={() => {
                          setSearchError(false);
                          setCreateCustomer(true);
                        }}
                      >
                        Create New
                      </button>
                      <br />
                      <button
                        className="invite_btn"
                        onClick={() => setSearchError(false)}
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {invitationModal && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setInvitationModal(false);
                      inviteReset();
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={sent_email} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun sent_invite">
                      Please enter the required details correctly. Thanks!
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="invite__inputs">
                      <div className="input__with__error flex justify-center">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Full Name
                          </label>
                          <div
                            className={
                              inviteErrors.name
                                ? "error_border_input invite_input_field"
                                : "assign_rate_with_logo0 invite_input_field"
                            }
                          >
                            <input
                              name="name"
                              onChange={handleInviteChange}
                              onBlur={handleBlur}
                              value={inviteValues.name}
                              placeholder="Enter Full Name"
                              type="text"
                            />
                            <span className="margin-left-80">
                              {inviteErrors.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="input__with__error flex justify-center">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Email Address
                          </label>
                          <div
                            className={
                              inviteErrors.email
                                ? "error_border_input invite_input_field"
                                : "assign_rate_with_logo0 invite_input_field"
                            }
                          >
                            <input
                              name="email"
                              onChange={handleInviteChange}
                              onBlur={handleBlur}
                              placeholder="Enter Email"
                              value={inviteValues.email}
                              type="text"
                            />
                            <span className="margin-left-80">
                              {inviteErrors.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button
                          className="error_create_new error_create_new"
                          onClick={() => inviteSubmit()}
                          style={loading ? { cursor: "no-drop" } : {}}
                        >
                          {loading ? "Sending ..." : "Send An Email"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admins;
