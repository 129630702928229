import React, { useState, useEffect } from "react";
import { GoogleMap, MarkerF, Polyline} from "@react-google-maps/api";


import TrackingIcon from "../../images/tracking.png"; 




function Map({user, shipmentId}) {

  const [driverLocations, setDriverLocations] = useState([]);
  const [path, setPath] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);



  useEffect(() => {
    

    const ws = new WebSocket( `${process.env.REACT_APP_WSS}`); // Replace with your WebSocket server URL


    ws.onopen = (event) => {

      // Send the Redis channel name to the server
      const channelName = `${user?._id}-${shipmentId}`; // Replace with your channel name
      ws.send(channelName);
    };

    ws.onmessage = (event) => {
  

      const data = JSON.parse(event.data);

      if(data && user){
        setDriverLocations(data)

          if(data.length > 0){
              const Polyline = data?.map((item) => ({
                  lat: item?.lat,
                  lng: item?.lng
              }));

              setPath(Polyline)

              setMapCenter({ lat: data[data?.length-1]?.lat, lng: data[data?.length-1]?.lng });


            }
        
      }else{
        setDriverLocations([])
      }


      
    };

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, [user]);


  const renderMarkers = () =>  {

    const markers = [];

    if(driverLocations?.length > 0){
      markers.push(
        <MarkerF
          key={driverLocations[0]?.lat}
          position={{ lat: driverLocations[driverLocations.length-1]?.lat, lng: driverLocations[driverLocations.length-1]?.lng }}
          title='Driver Live Locations'
          icon={TrackingIcon}
        />
      )

    }

    return markers;
  }


  const handleOnLoad = (map) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);

    if (driverLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      
      driverLocations.forEach((location) => {
        const latLng = new window.google.maps.LatLng(location.lat, location.lng);
        bounds.extend(latLng);
      });
  
      map.fitBounds(bounds);
    }
  };

  const mapOptions = {
    gestureHandling: "greedy",
    zoom: 16,
    center: mapCenter
  };


  return mapOptions?.center  ? (
    <GoogleMap
      onLoad={handleOnLoad}
      mapContainerStyle={{ width: "100%", height: "635px" }}
      zoom={10}
      options={mapOptions}
      center={mapCenter}
    >
      {renderMarkers()}
      <Polyline
        path={path}
        options={{
          strokeColor: 'green',
          strokeOpacity: 1.0,
          strokeWeight: 4,
        }}
      />
    </GoogleMap>
  ) : <><p style={{textAlign: "center", marginTop: "20px", fontWeight: "bold"}}>No Trackable Record</p></>
}

export default React.memo(Map);
