import React, {useState} from "react";
import axios from "axios";
import { Button, Card, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {BiLinkExternal} from "react-icons/bi";


import "../css/EditDrivers.css";


const EditDrivers = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const {state} = useLocation();
  const { addToast } = useToasts();


  const [uploadind, setUploading] = useState(false);
  const [riderData, setRiderData] = useState({
    userVerifed: state?.userVerifed ? state?.userVerifed : '',
    vehicleType: state?.vehicleType ? state?.vehicleType : '',
    insuranceID: state?.insuranceID ? state?.insuranceID : '',
    insuranceIDDoc: state?.insuranceIDDoc ? state?.insuranceIDDoc : '',
    insuranceCompanyName: state?.insuranceCompanyName ? state?.insuranceCompanyName : '',
    policeCheckNo: state?.policeCheckNo ? state?.policeCheckNo : '',
    policeCheckDoc: state?.policeCheckDoc ? state?.policeCheckDoc : '',
    NumberPlate: state?.NumberPlate ? state?.NumberPlate : '',
    NumberPlateDoc: state?.NumberPlateDoc ? state?.NumberPlateDoc : '',
    IdCardNo: state?.IdCardNo ? state?.IdCardNo : '',
    idCardDoc: state?.idCardDoc ? state?.idCardDoc : '',
    passportNo: state?.passportNo ? state?.passportNo : '',
    expireDate: state?.expireDate ? state?.expireDate : '',
    abnNo: state?.abnNo ? state?.abnNo : '',
    gst: state?.gst ? state?.gst : '',
    businessStructure: state?.businessStructure ? state?.businessStructure : '',
    alcoholCertNo: state?.alcoholCertNo ? state?.alcoholCertNo : '',
    alcoholCertDoc: state?.alcoholCertDoc ? state?.alcoholCertDoc : '',
  })

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/upload`, formData, config)

      setRiderData({
        ...riderData,
        [e.target.name] : data?.path
      })
      if(data.path){
        addToast("Successfully Uploaded", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      setUploading(false)
      // setImage(data)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }
  const UpdateDriversFunc = (riderStatus) => {
    // let body = riderData
    
    fetch(`${process.env.REACT_APP_BASE_URL}/api/users/profile/${state._id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: riderStatus === false ? JSON.stringify({...riderData, userVerifed: riderStatus}) : riderStatus === true ? JSON.stringify({...riderData, userVerifed: riderStatus}) : JSON.stringify(riderData),
    })
      .then((result3) => {
        if (result3.status === 200) {
          navigate("/manage_drivers")
          addToast("Successfully Updated", {
            appearance: "success",
            autoDismiss: true,
          });

          // queryClient.invalidateQueries("AllDrivers");
        } else {
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          // queryClient.invalidateQueries("AllDrivers");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const RejectRiderFunc = () => {
    // let body = riderData
    
    fetch(`${process.env.REACT_APP_BASE_URL}/api/users/RejectRider/${state._id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          navigate("/manage_drivers")
          addToast("Successfully Updated", {
            appearance: "success",
            autoDismiss: true,
          });

          // queryClient.invalidateQueries("AllDrivers");
          setTimeout(() => {
            window.location.reload(false);
          }, 500)

        } else {
          addToast(result?.error, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) =>{
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      }

      );
  };


  const verifyDriver = () => {
    setRiderData({...riderData, userVerifed: true});
    UpdateDriversFunc();
  }

  const disableDriver = () => {
    setRiderData({...riderData, userVerifed: false});
    UpdateDriversFunc();
  }

  const handleChangeValue = (e) => {
    if(e.target.name === "expireDate"){
      setRiderData({
        ...riderData,
        expireDate : e.target.value.split("T")[0]
      })
    }else{
      setRiderData({
        ...riderData,
        [e.target.name] : e.target.value
      })
    }
  }

  const vehicleOptions = [
    { value: 'car', label: "Car" },
    { value: 'van', label: "Van" },
    { value: 'mini-car', label: "Mini Car" },
    { value: 'veagon', label: "Veagon" },
  ];


  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <Card className="e_d_header p-4">
                  <span>Vehicle</span>
                  <Col md={12} >
                    <Select
                      defaultValue={{ label: riderData.vehicleType, value: 0 }}
                      options={vehicleOptions}
                      className="c_o_select_css"
                      placeholder="Choose Vehicle Type"
                      onChange={(e) => setRiderData({...riderData, vehicleType: e.value})}
                    ></Select>
                  </Col>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="mt-5 e_d_v_title_card">
              <p className="e_d_v_title">Vehicle Information</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="e_d_body p-4">
              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Insurance</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="insuranceID" value={riderData.insuranceID} className="e_d_input" placeholder="Insurance ID"/>
                </Col>
                <Col md={4}></Col>
                <Col md={3}>
                <>
                  <label
                    className="uploadapk"
                    for="insuranceIDDoc"
                  >
                    Upload a document
                  </label>
                  <input
                    name="insuranceIDDoc"
                    onChange={uploadFileHandler}
                    accept="image/png, image/jpeg"
                    type="file"
                    placeholder="Upload a document"
                    className="e_d_btn_row"
                    id="insuranceIDDoc"
                  />
                </>
                                  
                  <div style={{marginTop: "20px"}}>{riderData.insuranceIDDoc ? <a target="blank" href={riderData.insuranceIDDoc}><img style={{width: '40px', height: '40px', borderRadius: '10px'}} src={riderData.insuranceIDDoc}/></a> : ''}</div>
                </Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Insurance Company</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="insuranceCompanyName" value={riderData.insuranceCompanyName} className="e_d_input" placeholder="Company Name"/>
                </Col>
                <Col md={4}></Col>
                
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Police Number</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="policeCheckNo" value={riderData.policeCheckNo} className="e_d_input" placeholder="0937664678992" />
                </Col>
                <Col md={1}></Col>
                <Col md={3}>
                  <Button className="e_d_btn_row_blue">
                    Police Check
                  </Button>
                </Col>
                <Col md={3}>
                <>
                  <label
                    className="uploadapk"
                    for="policeCheckDoc"
                  >
                    Upload Verified document
                  </label>
                  <input
                    name="policeCheckDoc"
                    onChange={uploadFileHandler}
                    accept="image/png, image/jpeg"
                    type="file"
                    placeholder="Upload a document"
                    className="e_d_btn_row"
                    id="policeCheckDoc"
                  />
                </>
                <div>{riderData.policeCheckDoc ? <a target="blank" href={riderData.policeCheckDoc}><img style={{width: '40px', height: '40px', borderRadius: '10px'}} src={riderData.policeCheckDoc}/></a> : ''}</div>
                </Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Number Plate</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="NumberPlate" value={riderData.NumberPlate} className="e_d_input" placeholder="e.g. Abc235" />
                </Col>
                <Col md={4}></Col>
                <Col md={3}>
                <>
                  <label
                    className="uploadapk"
                    for="NumberPlateDoc"
                  >
                    Upload a Photo
                  </label>
                  <input
                    name="NumberPlateDoc"
                    onChange={uploadFileHandler}
                    accept="image/png, image/jpeg"
                    type="file"
                    placeholder="Upload a document"
                    className="e_d_btn_row"
                    id="NumberPlateDoc"
                  />
                </>
                <div>{riderData.NumberPlateDoc ? <a target="blank" href={riderData.NumberPlateDoc}><img style={{marginTop: '10px', width: '40px', height: '40px', borderRadius: '10px'}} src={riderData.NumberPlateDoc}/></a> : ''}</div>
                </Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Passport & Visa Verification</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="passportNo" value={riderData.passportNo} className="e_d_input" placeholder="Verification Number" />
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">License No.</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="IdCardNo" value={riderData.IdCardNo} className="e_d_input" placeholder="License No." />
                </Col>
                <Col md={4}></Col>
                <Col md={3}>
                <>
                  <label
                    className="uploadapk"
                    for="idCardDoc"
                  >
                    Upload a Photo
                  </label>
                  <input
                    name="idCardDoc"
                    onChange={uploadFileHandler}
                    accept="image/png, image/jpeg"
                    type="file"
                    placeholder="Upload a document"
                    className="e_d_btn_row"
                    id="idCardDoc"
                  />
                </>
                <div>{riderData.idCardDoc ? <a target="blank" href={riderData.idCardDoc}><img style={{marginTop: '10px', width: '40px', height: '40px', borderRadius: '10px'}} src={riderData.idCardDoc}/></a> : ''}</div>
                </Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">License Expiry Date</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="expireDate" value={riderData.expireDate} type="date" className="e_d_input" placeholder="22/05/24" />
                </Col>
                <Col md={4}></Col>
                <Col md={3}></Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">ABN and GST</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="abnNo" value={riderData.abnNo} className="e_d_input" placeholder="ABN Number" />
                </Col>
                
                <Col md={4}></Col>
                <Col md={3} >
                <FormGroup switch style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span  style={{color:"green", fontSize: '14px', marginRight: "2px"}}>Registered For GST ?</span>
                <Input
                    type="switch"
                    checked={riderData.gst}
                    onClick={() => {
                      setRiderData({...riderData, gst: !riderData.gst});
                    }}
                  />
                </FormGroup>
                {/* <Input accept="image/png, image/jpeg" type="file" placeholder="Upload a document" className="e_d_btn_row" /> */}

                </Col>
                <Col md={2}>
                  <p className="e_d_title2">Business Structure</p>
                </Col>
                <Col md={3}>
                  <Input onChange={handleChangeValue} name="businessStructure" value={riderData.businessStructure} className="e_d_input" placeholder="Software House" />
                </Col>
                
              </Row>
              <hr className="e_d_sparator" />

              <Row>
                <Col md={2}>
                  <p className="e_d_title2">Responsible Service of Alcohol</p>
                </Col>
                <Col md={3}>
                  
                  <Input onChange={handleChangeValue} name="alcoholCertNo" value={riderData.alcoholCertNo} className="e_d_input" placeholder="Number" />
                </Col>
                <Col md={4}></Col>
                <Col md={3}>

                <>
                  <label
                    className="uploadapk"
                    for="alcoholCertDoc"
                  >
                    Upload your RSA Certificate
                  </label>
                  <input
                    name="alcoholCertDoc"
                    onChange={uploadFileHandler}
                    accept="image/png, image/jpeg"
                    type="file"
                    placeholder="Upload a document"
                    className="e_d_btn_row"
                    id="alcoholCertDoc"
                  />
                </>
                <div>{riderData.alcoholCertDoc ? <a target="blank" href={riderData.alcoholCertDoc}><img style={{marginTop: '10px', width: '40px', height: '40px', borderRadius: '10px'}} src={riderData.alcoholCertDoc}/></a> : ''}</div>
                </Col>
              </Row>
              <hr className="e_d_sparator" />

              <Row>
              <Col md={9}></Col>
              <Col md={3}>
                {
                // riderData?.isUserRejected === false && !userVerifed (

                  <Row style={{marginBottom: "20px"}}>
                    {riderData.userVerifed ? (
                      ""
                      ) : (
                      <Button onClick={() => {RejectRiderFunc()}} color="danger" className="w-100">Rejected</Button>
                    )}
                  </Row>
                // ) : ""
              }
                </Col>
              </Row>
              
              <Row>
                <Col md={3}>
                {
                riderData?.vehicleType ? (
                  <Row>
                      <Button onClick={UpdateDriversFunc} color="success" className="w-100"> Save</Button>

                  </Row>
                ) : ''
              }
                </Col>
              
                <Col md={3}></Col>
                <Col md={3}></Col>
                
                <Col md={3}>
                {
                riderData?.vehicleType ? (
                  <Row>
                    {riderData.userVerifed ? (
                      ""
                      ) : (
                      <Button onClick={() => {UpdateDriversFunc(true)}} color="success" className="w-100">Verify</Button>
                    )}
                  </Row>
                ) : ''
              }
                </Col>
                
              </Row>
              
              
              
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditDrivers;
