import React, { useState, useEffect } from "react";

import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  Input,
  Container,
  Spinner,
} from "reactstrap";
import DataTabelComponent from "../../components/tables";
import classnames from "classnames";
import DelIcon from "../../images/editDelete/del.svg";
import EditIcon from "../../images/editDelete/edit.svg";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { getshiftsFunc } from "../../ApIs/shifts";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import "./css/manageShifts.css";
import { getdriversFunc } from "../../ApIs/drivers";
import moment from "moment";



const CompletedShifts = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [search, setSearch] = useState("");
  const [filterTable, setFilterTable] = useState([]);
  const [modal_static4, setModal_static4] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [shiftId, setShiftId] = useState("");
  const token = localStorage.getItem("token");
  const [modal_static8, setModal_static8] = useState(false);
  const [status, setStatus] = useState("completed");

  const [deleteShift, setDeleteShift] = useState(false);

  const actionOptions = [
    { value: "View-Details", label: "View Details" }
  ];

  const dateOptions = [
    { value: "25hrs", label: "Last 24 Hours" },
    { value: "3days", label: "Last 3 Days" },
    { value: "7days", label: "Last 7 Days" },
    { value: "15days", label: "Last 15 Days" },
    { value: "30days", label: "Last 30 Hours" },
    // { value: "custom", label: "Custom Date Range" },
  ]

  const onChangeSelect = (e, row) => {
    if(e.value === "View-Details") {
      window.location.href = `/#/shift-orders/${row._id}`
      // navigate(`/shift-orders/${row._id}`, {replace: true})
    }
  }


  const columns = [
    {
      name: "Sr#",
      width: "40px",
      selector: (row, index) => index + 1,
    },
    {
      name: "Completed At",
      selector: (row, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{row?.updatedAt?.split("T")[0]}</span>
          <span>{row?.updatedAt?.split("T")[1]?.split(".")[0]}</span>
          <span>{moment(row?.updatedAt).fromNow()}</span>
        </div>
      ),
    },
    {
      name: "Shift Id",
      width: "90px",
      selector: (row) => row?.shiftId,
    },
    {
      name: "Delivery Date",
      selector: (row) => row?.date?.split("T")[0],
      sortable: true,
    },
    {
      name: "Duration (hour)",
      selector: (row) => Number(row?.duration)?.toFixed(2) + " " + "hours",
    },
    {
      name: "Minimum Earning By Rider",
      selector: (row) => Number(row?.minimumEarning)?.toFixed(2),
    },
    {
      name: "Starting Time",
      selector: (row) => row?.startTime,
    },
    {
      name: "Jobs",
      width: "70px",
      selector: (row) => row?.orders?.length,
    },
    {
      name: "Kilometers",
      width: "90px",
      selector: (row) => row?.totalDistance ? row?.totalDistance : 0 ,
    },
    {
      name: "Driver",
      selector: (row) => row?.assignedTo?.name,
    },
    {
      name: "",
      selector: (row) => (
        row?.assignedTo?.profilePhoto ? (
          <img
            src={row?.assignedTo?.profilePhoto}
            alt="driver-img"
            className="user-image"
          />
        ) : ''
      ),
    },

    {
      name: "Actions",
      width: "150px",

      cell: (row) => (
        <>
          <span className="">
            <Select
              className="c_o_select_css2 mx-auto"
              options={actionOptions}
              placeholder="Acions"
              value=''
              menuPortalTarget={document.body}
              onChange={ (e) => onChangeSelect(e, row)}
            ></Select>
          </span>
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) =>  <span className={row.status === 'unassigned' ? 'text-danger' : 'text-success'}>{row.status === 'unassigned' ? 'UNASSIGNED' : row.status === 'completed' ? "COMPLETED" : row.status === "progress" ? "ASSIGNED" : "Pending From Driver"}</span>,
    },
  ];

  const driverColumns = [
    {
      name: "Driver Name",
      selector: (row) => (
        row?.profilePhoto ? (
          <>
          <img
            src={row?.profilePhoto}
            alt="driver-img"
            className="user-image-2"
          />
          <span></span>

          <span>{row?.name}</span>
          </>
          
        ) : ''
      ),
      // selector: (row) => row?.name,
    },
    {
      name: "Contact Number",
      selector: (row) => row?.mobileNumber,
    },
    {
      name: "Address",
      selector: (row) => row?.address,
    },
    {
      name: "Vehicle Information",
      selector: (row) => row?.vehicleType,
    }
  ];

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };
  const tog_static8 = () => {
    setModal_static8(!modal_static8);
    removeBodyCss();
  };


  const routeToMap = (id) => {
    navigate(`/shift-map/${id}`)
  }

  // *******All Shifts******
  const AllShifts = useQuery(["AllShifts", status], () => getshiftsFunc(token, status));
  const AllShiftsData = AllShifts?.data;
  // *************

  // *************
  const AllDrivers = useQuery(["AllDrivers"], () => getdriversFunc(token, "approved"));
  const AllDriversData = AllDrivers?.data?.data;
  // *************

  const DelShiftFunc = () => {
    setDeleteShift(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/${shiftId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((result3) => {
        setDeleteShift(false);
        queryClient.invalidateQueries("AllShifts");
        if (result3.success) {
          addToast("Successfully Deleted", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static8(false);
          // queryClient.invalidateQueries("AllShifts");
          setTimeout(() => {
            window.location.reload(false);
          }, 500)

        } else {
          setDeleteShift(false);
          addToast(result3?.error, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllShifts");
        }
      })
      .catch((error) => {
        setDeleteShift(false);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      }
      );
  };

  const AssignShiftFunc = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/assignShift`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        riderId: selectedRows,
        shiftId: shiftId,
      }),
    })
      .then((res) => res.json())
      .then((result3) => {
        queryClient.invalidateQueries("AllShifts");
        if (result3.success) {
          addToast("Successfully Assign", {
            appearance: "success",
            autoDismiss: true,
          });

          setModal_static4(false);
          // queryClient.invalidateQueries("AllShifts");
          setTimeout(() => {
            window.location.reload(false);
          }, 500)

        } else {
          addToast("Assing Shift Failed", {
            appearance: "error",
            autoDismiss: true,
          });
          setModal_static8(false);
          queryClient.invalidateQueries("AllShifts");
        }
      })
      .catch((error) =>
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: true,
        })
      );
  };

  const handleChange = (state ) => {
    const riders = state?.selectedRows?.map((rider) => rider._id)
    setSelectedRows(riders[0])    
  };

  useEffect(() => {
    const result = AllShiftsData?.data?.filter((e) => {
      return e?.shiftId?.toLowerCase().match(search.toLowerCase()) ||
      e?.assignedTo?.name?.toLowerCase().match(search.toLowerCase());
    });
    setFilterTable(result);
  }, [search]);

  return (
    <div className="pt-4">
      <div className="p-4">
        <DataTabelComponent
          title=""
          columns={columns}
          progressPending={AllShifts.isLoading}
          data={filterTable ? filterTable : AllShiftsData?.data}
          pagination={true}
          fixedHeader={false}
          fixedHeaderScrollHeight="1000px"
          // selectableRows={true}
          // selectableRowsHighlight={true}
          highlightOnHover={true}
          subHeader={true}
          subHeaderComponent={
            <div className="w-100">
              <Row className="mb-2 mt-2">
                <Col lg={4} md={4} sm={4} style={{ display: "flex" }}>
                </Col>
                <Col lg={3} md={3} sm={3}></Col>
                 <Col lg={5} md={5} sm={5}>
                  <input
                    type="text"
                    placeholder="Search By Shift Id"
                    className="form-control w-70"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
          }
          // subHeaderComponent={
          //   <div className="w-100">
          //     <Row className="mt-4 mb-4">
          //       <Col lg={5} md={5} sm={5} style={{ display: "flex" }}>
          //         <span className="px-2">
          //           <Select
          //             className="c_o_select_css2 mx-auto"
          //             options={dateOptions}
          //             placeholder="Choose Date Range"
          //           ></Select>
          //         </span>
          //       </Col>
          //     </Row>
          //   </div>
          // }
        />
        <div>
          <Modal size="lg" style={{maxWidth: '700px', width: '100%'}} isOpen={modal_static4} toggle={tog_static4} centered={true} >
            <ModalHeader className=" m_d_popup_header">
              <Container fluid>
                <Row>
                  <Col md={12}>
                    {" "}
                    <p className="mt-3 m_d_popup_header_font">Assign Driver</p>
                  </Col>
                </Row>
              </Container>
            </ModalHeader>
            <ModalBody >
              <Container fluid>
                <Row >
                  <DataTabelComponent
                    columns={driverColumns}
                    data={AllDriversData?.riders}
                    pagination={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight="200px"
                    selectableRows={true}
                    selectableRowsHighlight={true}
                    highlightOnHover={true}
                    subHeader={true}
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={toggledClearRows}
                    selectableRowsSingle={true}
                  />
                </Row>

                <Row className="mt-5">
                  <Col md={3}>
                    <Button
                      color="danger"
                      className="w-100"
                      onClick={() => AssignShiftFunc()}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Assign
                      </p>
                    </Button>
                  </Col>
                  <Col md={3}></Col>
                  <Col md={3}></Col>
                  <Col md={3}>
                    <Button
                      color="danger"
                      outline
                      className="w-100 "
                      onClick={() => setModal_static4(false)}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Cancel
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
      </div>

      <div>
        <Modal isOpen={modal_static8} toggle={tog_static8} centered={true}>
          <ModalBody className="diverdelmodel">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <p className="m_d_popup_font">Are You Sure?</p>
                  <p>
                    If You Proceed, you will lose your all your Shift data. Are
                    you sure you want to delete this shift
                  </p>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md={6}>
                  <Button
                    color="light"
                    className="w-100"
                    onClick={DelShiftFunc}
                    disabled={deleteShift}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                    {
                        deleteShift ? <Spinner color="dark" size="sm" /> : "Confirm"
                      }

                    </p>
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    color="light"
                    outline
                    className="w-100 "
                    onClick={() => setModal_static8(false)}
                    disabled={deleteShift}
                  >
                    <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                      Not Now
                    </p>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default CompletedShifts;
