import React, { useState, useEffect } from "react";
import cross from "../../images/icons/cross-white.svg";
import DataTable from "react-data-table-component";
import { useToasts } from "react-toast-notifications";
import { VscChromeClose } from "react-icons/vsc";
import modalImage1 from "../../images/modals/customer.png";

import table_search from "../../images/icons/table_search.svg";
import { AiOutlineLoading } from "react-icons/ai";

const DriverModal = ({
  setDriverModal,
  data,
  rowData,
  setRowData,
  name,
  refetch
}) => {

  const { addToast } = useToasts();
  const [driverSelectedRow, setDriverSelectedRow] = useState();

  const handleRowSelected = (row) => {
    setDriverSelectedRow(row);
  };

  const [search, setSearch] = useState("")
  const [searchError, setSearchError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [filterTable, setFilterTable] = useState([]);
  const columns = [
    {
      name: "",
      width: "50px",
      selector: (row) => {
        return (
          <div>
            <input
              type="checkbox"
              checked={driverSelectedRow?._id === row._id}
              onChange={() => handleRowSelected(row)}
            />
          </div>
        );
      },
    },
    {
      name: "Driver Name",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>{row?.fullName ? row?.fullName : row?.firstName + " " + row?.lastName}</div>
        </div>
      ),
    },
    {
      name: "Phone No.",
      selector: (row) => row?.mobileNumber,
    },
    {
      name: "Email",
      width: "115px",
      selector: (row) => row?.email,
    },
    {
      name: "Vehicle Info",
      selector: (row) => row.vehical_Info,
    },
    {
      name: "Status",
      selector: (row) => (row.status ? row?.status : ""),
    },
  ];


  const onSearch = (e) => {
    e.preventDefault();
    const result = data?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.mobileNumber?.toString() === (search)
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  };

  useEffect(() => {
    const result = data?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.vat?.toString() === (search)
      );
    });
    setFilterTable(result);
  }, []);

  const customStyles = {
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
  }
  };
  const token = localStorage.getItem("token");

  const assignShipment = async (data) => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/assignShipment`,
      {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setLoading(false);
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      addToast(resJson.error, { appearance: "error", autoDismiss: true });
      setLoading(false);
      throw new Error(resJson.error.message);
    }
  
    const responseData = await res.json();
    if(responseData.success) {
      addToast(responseData?.message, { appearance: "success", autoDismiss: true });
      refetch();
      setRowData("");
      setLoading(false);
      setDriverModal(false);
    }
  };

  
  let debounceTimeout;
  const debounce = (func, key, delay) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
          func(key);
      }, delay);
  };

  const onDelaySearch = (search) => {
    const result = data?.filter((e) => {
      return (
        e?.email?.toLowerCase().match(search.toLowerCase()) ||
        e?.firstName?.toLowerCase().match(search.toLowerCase()) ||
        e?.lastName?.toLowerCase().match(search.toLowerCase()) ||
        e?.mobileNumber?.toString() === (search)
      );
    });

    if (result.length < 1) {
      setSearchError(true);
    } else {
      setFilterTable(result);
    }
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value)
    const search = () => onDelaySearch(e.target.value);
    debounce(search, 300);
  };

  return (
    <div className="modalMask">
      <div className="userModalWrapper">
        <div className="modal__body">
          <div>
            <div className="usermodal__body modal__body">
              <div className="flex justify-flex">
                <img src={modalImage1} alt="" />
                <button
                  className="close-button"
                  onClick={() => setDriverModal(false)}
                >
                  <VscChromeClose />
                </button>
              </div>
              <div className="customer-details">List of {name === "driver" ? "Drivers" : "Contractors"}!</div>
              <div className="flex justify-flex">
                <div className="please-enter-the-req-modal">
                  Please select or search the {name} here. <br /> Thanks!
                </div>
                <form onSubmit={onSearch}>
                  <div className="table__Search__modal">
                    <button type="submit" className="input_search__modal">
                      <img
                        src={table_search}
                        alt=""
                        onClick={() => onSearch()}
                      />
                    </button>
                    <input
                      type="text"
                      placeholder="Search by name/email/license number"
                      value={search}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: "0px 0px 30px 0px" }}>
          <div style={{ height: "200px", overflow: "auto" }}>
            <DataTable 
              columns={columns} 
              data={filterTable ? filterTable : data} 
              customStyles={customStyles}
            />
          </div>
          <div className="content_btn_center">
            <button className={!driverSelectedRow || loading ? "assign_btn_dis" : "assign_btn"}>
              <div
                className="inner_text_btn"
                onClick={() => {
                assignShipment({
                    "shipmentId": rowData?._id,
                    "userId": driverSelectedRow?._id,
                    "userName": driverSelectedRow?.fullName
                })
                }}
              >
               {loading ? <AiOutlineLoading className="loaderIcon" size={18} />  : "Assign"}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverModal;
