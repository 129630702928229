import React, { useState, useEffect } from "react";
import ShipmentDetails from "./Cards/ShipmentDetails";
import "./css/shipment.css";
import PickupDetails from "./Cards/PickupDetails";
import ReceiverDetails from "./Cards/ReceiverDetails";
import Maps from "./maps";
import EditTable from "./Cards/pickupPointTable";
import PackageDetailTable from "./Cards/packageDetailTable";
import RecieverTable from "./Cards/ReceiverDetailTable";
import breadcrumb_logo from '../../images/icons/breadcrumb_logo.svg'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";

const Shipping = () => {
  const [addressMapTogglePickup, setAddressMapTogglePickup] = useState(false);
  const [addressMapToggleReceiver, setAddressMapToggleReceiver] = useState(false);
  const [addressMapToggleReceiverTable, setAddressMapToggleReceiverTable] = useState(false);
  const [addressMapTogglePickupTable, setAddressMapTogglePickupTable] = useState(false);

  const [pickupAddress, setPickupAddress] = useState({ address: "" });
  const [receiverAddress, setReceiverAddress] = useState({ address: "" });
  const [receiverAddressTable, setReceiverAddressTable] = useState({ address: "" });
  const [pickupAddressTable, setPickupAddressTable] = useState({ address: "" });

  const [shipmentId, setShipmentId] = useState();
  const [changeAnyThing, setChangeAnyThing] = useState(false);
  const [shippingLoader, setShippingLoader] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [focusSave, setFocusSave] = useState(false);
  const [data, setData] = useState(null)
  const location = useLocation();

  const params = new URLSearchParams(location?.search);
  const id = params.get("id");
  const isDup = params.get("duplicate");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  function generateUniqueID() {
    const randomPart = Math.random().toString(36).substr(2,3).toUpperCase();
    const timePart = Date.now().toString(36).substr(-3).toUpperCase(); 
    const uniqueID = `${timePart}${randomPart}`;
    return uniqueID;
  }
  
  const uniqueID = "SP_" + generateUniqueID();

  useEffect(() => {
    if (
      JSON.parse(localStorage?.getItem("receiverDetails"))?.length > 0 &&
      JSON.parse(localStorage?.getItem("pickupDetails"))?.length > 0 &&
      JSON.parse(localStorage?.getItem("packages"))?.length > 0
    ) {
      setShowNextButton(true);
    }
  }, [changeAnyThing]);

  const [storage, setStorage] = useState(false);
  
  const setLocal = (data) => {
    // Setting new Shipment ID
    localStorage?.setItem("shipmentId", uniqueID);

    // Storing shipment data into storage
    const shipmentClone = data?.shipmentDetails;
    const shipment = {
      customerRate: +shipmentClone?.customerRate || "",
      assignRate: +shipmentClone?.assignRate || "",
      customer: shipmentClone?.customerObj?.name || "",
      customerId: shipmentClone?.customerObj?._id || "",
      assignTo: { label: shipmentClone?.contractor?.name ? "contractor" : "own driver", value: shipmentClone?.contractor?.name ? "contractor" : "own_driver" },
      customerObj: {
        label: "",
        value: "",
      },
      contractor: {
        id: shipmentClone?.contractor?._id || "",
        name: shipmentClone?.contractor?.name || "",
      },
      expense: +shipmentClone?.expense || "",
      currency: shipmentClone?.currency || "pounds",
      expenseCurrency: shipmentClone?.expenseCurrency || "pounds",
      truck_type: shipmentClone?.truck_type || "",
      truck_number: shipmentClone?.truck_number || "",
      trailer_number: shipmentClone?.trailer_number || "",
      clientReference: shipmentClone?.clientReference || "",
      profit: +shipmentClone?.profit || "",
      track_driver: true,
    }
    localStorage.removeItem('shipmentDetails');
    localStorage.removeItem('receiverDetails');
    localStorage.removeItem('pickupDetails');
    localStorage.removeItem('packages');

    localStorage?.setItem(
      "shipmentDetails",
      JSON.stringify([{
        ...shipment,
        _id: localStorage?.getItem("shipmentId")
      }])
    );
    setShipmentId(localStorage?.getItem("shipmentId"));

    // Storing pickup data into storage
    let pickupDetailsClone = data?.pickupDetails.map(obj => {
      return { ...obj, shipmentId: localStorage?.getItem("shipmentId") };
    });

    localStorage.setItem("pickupDetails", JSON.stringify(pickupDetailsClone));

    // Storing receiver data into storage
    let receiverDetailsClone = data?.receiverDetails.map(obj => {
      return { ...obj, shipmentId: localStorage?.getItem("shipmentId") };
    });

    localStorage.setItem("receiverDetails", JSON.stringify(receiverDetailsClone));

    // Storing packaging data into storage
    localStorage.setItem("packages", JSON.stringify(data?.packages));
    
    setData(null)
    setStorage(true);
    if (
      JSON.parse(localStorage?.getItem("receiverDetails"))?.length > 0 &&
      JSON.parse(localStorage?.getItem("pickupDetails"))?.length > 0 &&
      JSON.parse(localStorage?.getItem("packages"))?.length > 0
    ) {
      setShowNextButton(true);
    }
    navigate('/shipping');
  }

  useEffect(() => {
    const fetchShipmentDetails = async (id) => {
      setShippingLoader(true);
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}api/shipping/shippment/all-details/${id}`, {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (!res.ok) {
        if (res.status === 401) {
          const resJson = await res.json();
          setShippingLoader(false);
          throw new Error(resJson.error.message);
        }
        const resJson = await res.json();
        setShippingLoader(false);
        throw new Error(resJson.error.message);
      }
      const data = await res.json();
      if(isDup === "true") {
        setLocal(data?.data)
      } else {
        setData(data?.data)
      }
      setShippingLoader(false);
    }
    if (id) {
      fetchShipmentDetails(id)
    }
  }, [id])

  const getShipmentId = () => {
    let id =
      Array.isArray(JSON.parse(localStorage?.getItem("shipmentDetails"))) &&
        JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id
        ? Array.isArray(JSON.parse(localStorage?.getItem("shipmentDetails"))) &&
        JSON.parse(localStorage?.getItem("shipmentDetails"))?.[0]?._id
        : "-";

    return id;
  };

  return (
    <div className="shipment__main">
      {(shippingLoader) && (
        <div className='loader__overlay'>
          <AiOutlineLoading style={{ margin: "auto", textAlign: "center" }} className="loaderIcon" size={50} />
        </div>
      )}
      <div className="breadcrumb">
        <img src={breadcrumb_logo} alt="truck-logo" />
        <span>/</span>
        <p>Shipping</p>
      </div>
      <div
        className={`${addressMapTogglePickup || addressMapToggleReceiver || addressMapToggleReceiverTable || addressMapTogglePickupTable ? "deactivate" : ""
          } cards__wrapper`}
      >
        {" "}
        <div className="flex__wrapper">
          <ShipmentDetails
            setShipmentId={setShipmentId}
            shipmentId={shipmentId}
            focusSave={focusSave}
            setFocusSave={setFocusSave}
            data={data}
            isLocal={data ? false : true}
            setData={setData}
            storage={storage}
          />
        </div>
        <div className="flex__wrapper">
          <PickupDetails
            setChangeAnyThing={setChangeAnyThing}
            shipmentId={shipmentId}
            addressMapTogglePickup={addressMapTogglePickup}
            setAddressMapTogglePickup={setAddressMapTogglePickup}
            pickupAddress={pickupAddress}
            setPickupAddress={setPickupAddress}
            focusSave={focusSave}
            setFocusSave={setFocusSave}
            dataLength={JSON.parse(localStorage?.getItem("pickupDetails"))?.length ? JSON.parse(localStorage?.getItem("pickupDetails"))?.length : 0}
            data={data}
            isLocal={data ? false : true}
            setData={setData}
          />
        </div>
        <div className="flex__wrapper">
          <ReceiverDetails
            setChangeAnyThing={setChangeAnyThing}
            shipmentId={shipmentId}
            addressMapToggleReceiver={addressMapToggleReceiver}
            setAddressMapToggleReceiver={setAddressMapToggleReceiver}
            receiverAddress={receiverAddress}
            setReceiverAddress={setReceiverAddress}
            focusSave={focusSave}
            setFocusSave={setFocusSave}
            dataLength={JSON.parse(localStorage?.getItem("receiverDetails"))?.length ? JSON.parse(localStorage?.getItem("receiverDetails"))?.length : 0}
            data={data}
            isLocal={data ? false : true}
            setData={setData}
          />
        </div>
      </div>
      {(addressMapTogglePickup
        || addressMapToggleReceiver
        || addressMapToggleReceiverTable
        || addressMapTogglePickupTable
      ) && (
          <div style={{ marginTop: "20px" }}>
            <div style={{ border: "5px solid white", margin: "0px 15px" }}>
              <Maps
                visible={
                  addressMapTogglePickup
                    ? addressMapTogglePickup
                    : addressMapToggleReceiver
                      ? addressMapToggleReceiver
                      : addressMapToggleReceiverTable
                        ? addressMapToggleReceiverTable
                        : addressMapTogglePickupTable
                }

                setVisible={
                  addressMapTogglePickup
                    ? setAddressMapTogglePickup
                    : addressMapToggleReceiver
                      ? setAddressMapToggleReceiver
                      : addressMapToggleReceiverTable
                        ? setAddressMapToggleReceiverTable
                        : setAddressMapTogglePickupTable
                }

                pickupAdress={pickupAddress}
                setPickupAdress={setPickupAddress}
                receiverAdress={receiverAddress}
                setReceiverAdress={setReceiverAddress}
                receiverAddressTable={receiverAddressTable}
                setReceiverAddressTable={setReceiverAddressTable}
                pickupAddressTable={pickupAddressTable}
                setPickupAddressTable={setPickupAddressTable}

                name={
                  addressMapTogglePickup ? "pickup"
                    : addressMapToggleReceiver ? "receiver"
                      : addressMapToggleReceiverTable ? "receiverTable"
                        : "pickupTable"
                }
              />
            </div>
          </div>
        )}
      <div
        className={`${addressMapTogglePickup || addressMapToggleReceiver || addressMapToggleReceiverTable || addressMapTogglePickupTable ? "deactivate" : ""
          }`}
      >
        <PackageDetailTable
          changeAnyThing={changeAnyThing}
          setChangeAnyThing={setChangeAnyThing}
          data={data}
          isLocal={data ? false : true}
          setData={setData}
          storage={storage}
        />
        <EditTable
          setChangeAnyThing={setChangeAnyThing}
          changeAnyThing={changeAnyThing}
          setAddressMapTogglePickupTable={setAddressMapTogglePickupTable}
          pickupAddressTable={pickupAddressTable}
          setPickupAddressTable={setPickupAddressTable}
          data={data}
          isLocal={data ? false : true}
          setData={setData}
          storage={storage}
        />
        <RecieverTable
          setChangeAnyThing={setChangeAnyThing}
          changeAnyThing={changeAnyThing}
          setAddressMapToggleReceiverTable={setAddressMapToggleReceiverTable}
          receiverAddressTable={receiverAddressTable}
          setReceiverAddressTable={setReceiverAddressTable}
          data={data}
          isLocal={data ? false : true}
          setData={setData}
          storage={storage}
        />
      </div>

      {JSON.parse(localStorage?.getItem("receiverDetails"))?.length > 0 &&
        JSON.parse(localStorage?.getItem("pickupDetails"))?.length > 0 &&
        JSON.parse(localStorage?.getItem("packages"))?.length > 0 &&
        showNextButton
        && !addressMapTogglePickup
        && !addressMapToggleReceiver
        && !addressMapToggleReceiverTable
        && !addressMapTogglePickupTable
        ? (
          <Link
            onClick={() => {
              localStorage.setItem("modalFlag", JSON.stringify(true));
            }}
            to={`/shipping/${getShipmentId()}`}
          >
            <button className="shipping__next">Next</button>
          </Link>
        ) : (
          ""
        )}

      {
        data?.receiverDetails?.length > 0 &&
          data?.pickupDetails?.length > 0 &&
          data?.packages?.length > 0
          && !addressMapTogglePickup
          && !addressMapToggleReceiver
          && !addressMapToggleReceiverTable
          && !addressMapTogglePickupTable
          ?
          (
            <Link
              onClick={() => {
                localStorage.setItem("modalFlag", JSON.stringify(true));
                localStorage.setItem("editData", JSON.stringify(data));
                localStorage.setItem("editShipmentId", JSON.stringify(id));
              }}
              to={`/edit-shipping/${data?.shipmentDetails?._id}`}
            >
              <button className="shipping__next">Next</button>
            </Link>
          ) : (
            ""
          )}
    </div>
  );
};

export default Shipping;
