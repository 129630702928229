// ****** statistics get Api******
export const getStatsFunc = async (token, date) => {
    // return await (
      const res = 
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/statistics/${date}`, {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
  
      if (!res.ok) {
        if(res.status === 401){
        //   history.push("/login");
          throw new Error(resJson.error.message);
       
      }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const data = await res.json();
      
      return {
        data: data
      };
    // ).json();
  };


export const getBillStatsFunc = async (token, date) => {
  // return await (
    const res = 
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/billsStats`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    if (!res.ok) {
      if(res.status === 401){
      //   history.push("/login");
        throw new Error(resJson.error.message);
      
    }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
    
    return {
      data: data
    };
  // ).json();
};

export const getRiderBillStatsFunc = async (token, date) => {
  // return await (
    const res = 
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/riderBillsStats`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    if (!res.ok) {
      if(res.status === 401){
      //   history.push("/login");
        throw new Error(resJson.error.message);
      
    }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
    
    return {
      data: data
    };
  // ).json();
};


export const getDashboardStatsFunc = async (token, date) => {
  // return await (
    const res = 
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/dashboardStats`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    if (!res.ok) {
      if(res.status === 401){
      //   history.push("/login");
        throw new Error(resJson.error.message);
      
    }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
    
    return {
      data: data
    };
  // ).json();
};


// ****** Rider statistics get Api******
export const getRiderStatsFunc = async (token, id, date) => {
  // return await (
    const res = 
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/riderAnalytics/${id}/${date}`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    if (!res.ok) {
      if(res.status === 401){
      //   history.push("/login");
        throw new Error(resJson.error.message);
     
    }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const data = await res.json();
    
    return {
      data: data
    };
  // ).json();
};