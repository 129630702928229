/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getInvoicesFunc,
} from "../../ApIs/orders";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { validationSchema, driverSchema } from "./ordersSchemas";
import "./css/driver.css";
import moment from "moment";
import { useFormik } from "formik";
import Table from "../../components/tables/Table";
import { RiSearch2Line, RiShoppingBasketLine } from "react-icons/ri";
import * as yup from "yup";
import modalImage1 from "../../images/modals/typewriter.svg";
import { VscChromeClose } from "react-icons/vsc";
import error_image from "../../images/modals/error-image.png";
import sent_email from "../../images/modals/sent-email.png";
import { useNavigate } from "react-router-dom";
import { getRidersFunc } from "../../ApIs/drivers";
import { getContractorFunc } from "../../ApIs/contractors";
import { AiOutlineLoading } from "react-icons/ai";


const invitationSchema = {
  name: "",
  email: "",
};

const invoiceSchema = {
  flatRate: "",
  currency: "",
  waitingTimeCharges: "",
  otherCharges: "",
  taxType: "exempt",
  taxCharges: "0",
  invoiceCharges: ""
};

const invitationValidation = yup.object().shape({
  name: yup.string().required("*name is required"),
  email: yup
    .string()
    .email("*must be a valid email")
    .required("*email is required"),
});

const invoiceValidation = yup.object().shape({
  flatRate: yup.mixed(),
  currency: yup.mixed(),
  waitingTimeCharges: yup.mixed(),
  otherCharges: yup.mixed(),
  taxType: yup.mixed(),
  taxCharges: yup.mixed(),
  invoiceCharges: yup.mixed(),
});

const Invoices = () => {
  const [search, setSearch] = useState("");
  const [invitationDetails, setInvitationDetails] = useState(invitationSchema);
  const [invoiceDetails, setInvoiceDetails] = useState(invoiceSchema);
  const [status] = useState("assign");
  const [filterTable, setFilterTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = React.useState(false);
  const [invitationModal, setInvitationModal] = React.useState(false);
  const [createRider, setCreateRider] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialRider, setInitialRider] = useState(driverSchema);
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token");
  const [countryCode, setCountryCode] = useState("");

  const {
    data: allInvoices,
    refetch,
    isLoading,
  } = useQuery(["getinvoices"], () => fetchAllInvoices());

  const fetchAllInvoices = () => getInvoicesFunc(token);

  const formik = useFormik({
    initialValues: initialRider,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    // onSubmit: (values) => {
    //   const newVals = { ...values };
    //   newVals.vat = countryCode + values.vat;
    //   isEdit ? editRiderMutateAsync(newVals) : addRiderMutateAsync(newVals);
    // },
  });

  const navigate = useNavigate();

  function capitalizeFirstLetters(text) {
    const words = text.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const capitalizedText = capitalizedWords.join(" ");
    return capitalizedText;
  }
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [activeInvoice, setActiveInvoice] = useState(null);
  const [loader, setLoader] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [enable, setEnable] = useState(false);


  const columns = [
    {
      name: "Sr#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Shipment ID",
      width: "150px",
      selector: (row, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginTop: "5px" }}>{row?.shiftId}</span>
          </div>
        );
      },
    },
    {
      name: "Customer Name",
      width: "150px",
      selector: (row, index) => (
        <span>{row?.customerName ? row?.customerName : "-"}</span>
      ),
    },
    {
      name: "Customer Reference",
      width: "150px",
      selector: (row) => (
        <span>{row?.clientReference ? row?.clientReference : "-"}</span>
      ),
    },
    {
      name: "Actions",
      width: "300px",
      selector: (row) => (
        <div className="invoice_actions">
          <button
            className="gen_inv_button"
            onClick={() => {
              setInvoiceModal(true);
              if(row?.invoiceCharges) {
                setFieldValue("waitingTimeCharges", row?.waitingTimeCharges);
                setFieldValue("otherCharges", row?.otherCharges);
                setFieldValue("taxCharges", row?.taxCharges);
                setFieldValue("taxType", row?.taxType);
                setFieldValue("invoiceCharges", row?.invoiceCharges);
                setEnable(true);
              } else {
                const totalAmt = (+row.flatRate) + (+invoiceValues.waitingTimeCharges) + (+invoiceValues.otherCharges);
                setFieldValue("invoiceCharges", totalAmt + (totalAmt * (+invoiceValues.taxCharges)) / 100);
              }
              setActiveInvoice(row);
              setFieldValue('flatRate', row.flatRate);
              setFieldValue('currency', row.currency);
            }}
          >
            {
              row?.invoiceCharges ? "Edit Invoice" : "Generate Invoice"
            }
          </button>
          <button 
          onClick={() => downloadInvoicePdfFunction(row?._id)}
            className={row?.invoiceCharges ? "down_inv_button" : "down_inv_button_dis"}
          >
            Download Invoice
          </button>
        </div>
      ),
    }
  ];

  useEffect(() => {
    queryClient.invalidateQueries("allInvoices");
  }, []);



  const {
    values: inviteValues,
    errors: inviteErrors,
    resetForm: inviteReset,
    handleBlur,
    handleChange: handleInviteChange,
    handleSubmit: inviteSubmit,
  } = useFormik({
    initialValues: invitationDetails,
    validationSchema: invitationValidation,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true
  });

  const { addToast } = useToasts();

  const postInvoice = async (values) => {
    setLoader(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/${activeInvoice?._id}`,
        {
          method: "PATCH",
          redirect: "follow",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(values),
        }
      );
    
      if (!res.ok) {
        if (res.status === 401) {
          const resJson = await res.json();
          throw new Error(resJson.error.message);
        }
        const resJson = await res.json();
        addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
        throw new Error(resJson.error.message);
      }
    
      const responseData = await res.json();
      if(responseData.success) {
        refetch();
        setEnable(true);
        addToast(responseData?.message, { appearance: "success" , autoDismiss: true,});
      }
    } catch(err) {
      addToast(err?.response?.data?.message, { appearance: "error" , autoDismiss: true,});
    } finally {
      setLoader(false);
    }
  }

  const sendInvoice = async () => {
    setSendLoader(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/sendInvoice`,
        {
          method: "POST",
          redirect: "follow",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ id: activeInvoice?._id }),
        }
      );
    
      if (!res.ok) {
        if (res.status === 401) {
          const resJson = await res.json();
          throw new Error(resJson.error.message);
        }
        const resJson = await res.json();
        addToast(resJson.error, { appearance: "error" , autoDismiss: true,});
        throw new Error(resJson.error.message);
      }
    
      const responseData = await res.json();
      if(responseData.success) {
        refetch()
        setInvoiceModal(false);
        setActiveInvoice(null);
        invoiceReset();
        addToast(responseData?.message, { appearance: "success" , autoDismiss: true,});
      }
    } catch(err) {
      addToast(err?.response?.data?.message, { appearance: "error" , autoDismiss: true,});
    } finally {
      setSendLoader(false);
    }
  }

  const {
    values: invoiceValues,
    errors: invoiceErrors,
    resetForm: invoiceReset,
    handleChange: handleInvoiceChange,
    handleSubmit: invoiceSubmit,
    setFieldValue
  } = useFormik({
    initialValues: invoiceDetails,
    validationSchema: invoiceValidation,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      postInvoice(values);
    }
  });


  useEffect(() => {
    const result = allInvoices?.data?.data?.filter((e) => {
      return (
        e?.customerName?.toLowerCase().match(search.toLowerCase()) ||
        e?.shiftId?.toLowerCase().match(search.toLowerCase()) ||
        e?.clientReference?.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilterTable(result);
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    const result = allInvoices?.data?.data?.filter((e) => {
      return (
        e?.customerName?.toLowerCase().match(search.toLowerCase()) ||
        e?.shiftId?.toLowerCase().match(search.toLowerCase()) ||
        e?.clientReference?.toLowerCase().match(search.toLowerCase())
      );
    });


    if (result.length < 1) {
      setFilterTable([]);

    } else {
      setFilterTable(result);
    }
  };

  const customStyles = {
    table: {
      borderRadius: "20px",
      style: {
        height: `${allInvoices?.data?.data
          && allInvoices?.data?.data?.length === 1 ?
          "250px"
          : "auto"
          }`,
      },
    },
    rows: {
      style: {
        minHeight: "58px",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "106.68%",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "58px",
        background: "#EFF8FF",
        color: "#1E3A52",
        fontFamily: "Avenir Next",
        fontStyle: "normal",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        background: "#FFFFFF",
        height: "auto",
      },
    },
    subHeaderComponent: {
      background: "#FFFFFF",
      border: "1px solid #A9C7BF",
      borderRadius: "11px",
      height: "42px",
    },

    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const handleRowClick = (row) => {
    const id = row?._id;
    navigate(`/order/${id}`);
  };
  const customRowClick = (row) => {
    handleRowClick(row);
  };

  const { data: allDrivers } = useQuery(["alldrivers"], () =>
    fetchAllDrivers()
  );
  const fetchAllDrivers = () => getRidersFunc(token);


  const { data: allContractors } = useQuery(["contractors"], () =>
    fetchAllContractors()
  );

  const fetchAllContractors = () => getContractorFunc(token);

  let debounceTimeout;
  const debounce = (func, key, delay) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      func(key);
    }, delay);
  };

  const onDelaySearch = (search) => {
    const result = allInvoices?.data?.data?.filter((e) => {
      return (
        e?.customerName?.toLowerCase().match(search.toLowerCase()) ||
        e?.status?.toLowerCase().match(search.toLowerCase()) ||
        e?.shiftId?.toLowerCase().match(search.toLowerCase()) ||
        e?.shippmentDate?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckType?.toLowerCase().match(search.toLowerCase()) ||
        e?.truckNumber?.toLowerCase().match(search.toLowerCase()) ||
        e?.trailerNumber?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalPickup)?.toLowerCase().match(search.toLowerCase()) ||
        String(e?.totalDelivery)?.toLowerCase().match(search.toLowerCase()) ||
        e?.createdAt?.toLowerCase().match(search.toLowerCase())
      );
    });

    if (result.length < 1) {
      setFilterTable([]);
    } else {
      setFilterTable(result);
    }
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value)
    const search = () => onDelaySearch(e.target.value);
    debounce(search, 300);
  };

  const downloadInvoicePdfFunction = (id) => {

    const apiUrl = `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/invoice/${id}`;
    window.open(apiUrl, '_blank');
}

  return (
    <div>
      {
        invoiceModal && (
          <div className="modalMask">
            <div className="userModalWrapper" style={{ borderRadius: "12px", width: "690px" }}>
              <div className="modal__body scroller_modal">
                <div>
                  <div className="usermodal__body modal__body" style={{ gap: "5px" }}>
                    <div className="flex justify-flex">
                      <img src={modalImage1} alt="" />
                      <button
                        className="close-button"
                        onClick={() => {
                          setInvoiceModal(false);
                          setActiveInvoice(null);
                          invoiceReset();
                        }}
                      >
                        <VscChromeClose />
                      </button>
                    </div>
                    <div className="customer-details">
                      Generate Invoice
                    </div>
                    <div className="please-enter-the-req">
                      Please enter the required details correctly. Thanks!
                    </div>
                    <form className='modal-form'>
                      <div className="grid-flex2">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Flat Rate
                          </label>
                          <div className="input__with__error">
                            <div
                              className="assign_rate_with_logo"
                            >
                              <input
                                placeholder="Flat Rate"
                                name="flatRate"
                                type="text"
                                readOnly
                                value={invoiceValues?.flatRate}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="" className="grid-label">
                            Waiting Time Charges
                          </label>
                          <div className="input__with__error">
                            <div
                              className="assign_rate_with_logo"
                            >
                              <input
                                placeholder="Waiting Time Charges"
                                name="waitingTimeCharges"
                                onChange={(e) => {
                                  setFieldValue('waitingTimeCharges', e.target.value)
                                  const totalAmt = (+invoiceValues.flatRate) + (+e.target.value) + (+invoiceValues.otherCharges);
                                  setFieldValue("invoiceCharges", totalAmt + (totalAmt * (+invoiceValues.taxCharges)) / 100);
                                }}
                                value={invoiceValues.waitingTimeCharges}
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="" className="grid-label">
                            Other Charges
                          </label>
                          <div className="input__with__error">
                            <div
                              className="assign_rate_with_logo"
                            >
                              <input
                                placeholder="Other Charges"
                                name="otherCharges"
                                onChange={(e) => {
                                  setFieldValue('otherCharges', e.target.value)
                                  const totalAmt = (+invoiceValues.flatRate) + (+e.target.value) + (+invoiceValues.waitingTimeCharges);
                                  setFieldValue("invoiceCharges", totalAmt + (totalAmt * (+invoiceValues.taxCharges)) / 100);
                                }}
                                defaultValue={invoiceValues.otherCharges}
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid-flex2">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Tax Type
                          </label>
                          <div className="input__with__error" style={{display: "flex", gap: "10px"}}>
                            <div>
                              <label style={{display: "flex", gap: "5px"}}>
                                <input 
                                  type="radio" 
                                  name="taxType" 
                                  value="exempt" 
                                  onChange={(e) => {
                                    setFieldValue('taxType', e.target.value)
                                    setFieldValue('taxCharges', 0)
                                    const totalAmt = (+invoiceValues.flatRate) + (+invoiceValues.waitingTimeCharges) + (+invoiceValues.otherCharges);
                                    setFieldValue("invoiceCharges", totalAmt);
                                  }}
                                  checked={invoiceValues.taxType === "exempt"}
                                />
                                Exempt
                              </label>
                            </div>
                            <div>
                              <label style={{display: "flex", gap: "5px"}}>
                                <input 
                                  type="radio" 
                                  name="taxType" 
                                  value="zero" 
                                  onChange={(e) => {
                                    setFieldValue('taxType', e.target.value)
                                    setFieldValue('taxCharges', 0)
                                    const totalAmt = (+invoiceValues.flatRate) + (+invoiceValues.waitingTimeCharges) + (+invoiceValues.otherCharges);
                                    setFieldValue("invoiceCharges", totalAmt);
                                  }}
                                  checked={invoiceValues.taxType === "zero"}
                                />
                                Zero
                              </label>
                            </div>
                            <div>
                              <label style={{display: "flex", gap: "5px"}}>
                                <input 
                                  type="radio" 
                                  name="taxType" 
                                  value="custom" 
                                  onChange={handleInvoiceChange} 
                                  checked={invoiceValues.taxType === "custom"}
                                />
                                Custom
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="" className="grid-label">
                            Tax Charges
                          </label>
                          <div className="input__with__error">
                            <div
                              className="assign_rate_with_logo"
                              style={{
                                background: `${invoiceValues.taxType !== 'custom' ? "#cecece" : "#fff"}`,
                                cursor: `${invoiceValues.taxType !== 'custom' ? "not-allowed" : ""}`,
                              }}
                            >
                              <input
                                placeholder="Tax Charges"
                                name="taxCharges"
                                onChange={(e) => {
                                  setFieldValue('taxCharges', e.target.value)
                                  const totalAmt = (+invoiceValues.flatRate) + (+invoiceValues.otherCharges) + (+invoiceValues.waitingTimeCharges);
                                  setFieldValue("invoiceCharges", totalAmt + (totalAmt * (+e.target.value)) / 100);
                                }}
                                value={invoiceValues.taxCharges}
                                type="number"
                                disabled={invoiceValues.taxType !== 'custom'}
                                style={{
                                  background: `${invoiceValues.taxType !== 'custom' ? "#cecece" : "#fff"}`,
                                  cursor: `${invoiceValues.taxType !== 'custom' ? "not-allowed" : ""}`,
                                }}
                              />
                              <p style={{margin: "0"}}>%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid-flex2">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Total
                          </label>
                          <p><span style={{marginLeft: "10px"}}>{invoiceValues?.currency === "pounds" ? "£" : invoiceValues?.currency === "dollar" ? "$" : "€"  }</span> {Number(invoiceValues.invoiceCharges).toFixed(2)} </p>
                        </div>
                      </div>
                      <div className="flex-center p-t20" style={{gap: "20px"}}>
                        <button
                          className="flex-button"
                          onClick={invoiceSubmit}
                          disabled={sendLoader || loader}
                          style={{cursor: sendLoader || loader ? "not-allowed" : "pointer"}}
                        >
                          {loader ? <AiOutlineLoading className="loaderIcon" size={18} /> : "Submit"}
                        </button>
                        <button
                          className="flex-button"
                          onClick={sendInvoice}
                          disabled={sendLoader || loader || !enable}
                          style={{
                            cursor: sendLoader || loader || !enable ? "not-allowed" : "pointer",
                            backgroundColor: !enable ? "#cecece" : "",
                            color: !enable ? "#000" : "#fff",
                            border: !enable ? "1px solid" : "",
                          }}
                        >
                          {sendLoader ? <AiOutlineLoading className="loaderIcon" size={18} /> : "Send to Customer"}
                        </button>
                        <button
                        onClick={() => downloadInvoicePdfFunction(activeInvoice?._id)}
                          className="flex-button"
                          disabled={sendLoader || loader || !enable}
                          style={{
                            cursor: sendLoader || loader || !enable ? "not-allowed" : "pointer",
                            backgroundColor: !enable ? "#cecece" : "",
                            color: !enable ? "#000" : "#fff",
                            border: !enable ? "1px solid" : "",
                          }}
                        >
                          Download
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div className="breadcrumb">
        <RiShoppingBasketLine className="breadcrumb_icon" />
        <span>/</span>
        <p>Invoices</p>
      </div>
      <div className="boder-table-com">
        <div>
          <Table
            title=""
            customStyles={customStyles}
            columns={columns}
            progressPending={isLoading}
            rowClicking={customRowClick}
            onRowHover={(id) => setHoveredRowId(id)}
            data={filterTable ? filterTable : allInvoices?.data?.data}
            pagination={true}
            fixedHeader={false}
            fixedHeaderScrollHeight="1000px"
            selectableRowsHighlight={true}
            highlightOnHover={true}
            subHeader={true}
            subHeaderComponent={
              <>
                <div className="table__header">
                  <div className="table__left">
                    {/* <button
                    // onClick={handleSelectAllRows}
                    className="select__btn"
                  >
                    Select All
                  </button> */}

                  </div>
                  <div className="table__right">
                    <form onSubmit={(e) => onSearch(e)} action="">

                      <div className="table__Search">
                        <div className="input_search">
                          <RiSearch2Line onClick={(e) => onSearch(e)} style={{ color: "#bebebe", width: "18px", height: "18px", cursor: "pointer" }} />
                        </div>
                        <input
                          type="text"
                          placeholder="Search by anything"
                          value={search}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </form>

                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {searchError && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setSearchError(false);
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={error_image} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun">
                      No driver was found with provided keyword.
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="">
                      <button
                        className="invite_btn"

                      >
                        Send them invite via link
                      </button>
                      <br />
                      <button
                        className="error_create_new"
                        onClick={() => {
                          setSearchError(false);
                          setCreateRider(true);
                        }}
                      >
                        Create New
                      </button>
                      <br />
                      <button
                        className="invite_btn"
                        onClick={() => setSearchError(false)}
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {invitationModal && (
        <div className="modalMask">
          <div
            style={{ width: "512px", borderRadius: "13px" }}
            className="userModalWrapper"
          >
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <button
                    className="close-button absolute-btn"
                    onClick={() => {
                      setInvitationModal(false);
                      inviteReset();
                    }}
                  >
                    <VscChromeClose />
                  </button>
                  <div className="flex justify-center">
                    <img src={sent_email} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <div className="no-customer-was-foun sent_invite">
                      Please enter the required details correctly. Thanks!
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="invite__inputs">
                      <div className="input__with__error flex justify-center">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Full Name
                          </label>
                          <div
                            className={
                              inviteErrors.name
                                ? "error_border_input invite_input_field"
                                : "assign_rate_with_logo0 invite_input_field"
                            }
                          >
                            <input
                              name="name"
                              onChange={handleInviteChange}
                              onBlur={handleBlur}
                              value={inviteValues.name}
                              placeholder="Enter Full Name"
                              type="text"
                            />
                            <span className="margin-left-80">
                              {inviteErrors.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="input__with__error flex justify-center">
                        <div>
                          <label htmlFor="" className="grid-label">
                            Email Address
                          </label>
                          <div
                            className={
                              inviteErrors.email
                                ? "error_border_input invite_input_field"
                                : "assign_rate_with_logo0 invite_input_field"
                            }
                          >
                            <input
                              name="email"
                              onChange={handleInviteChange}
                              onBlur={handleBlur}
                              placeholder="Enter Email"
                              value={inviteValues.email}
                              type="text"
                            />
                            <span className="margin-left-80">
                              {inviteErrors.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button
                          className="error_create_new error_create_new"
                          onClick={() => inviteSubmit()}
                          style={loading ? { cursor: "no-drop" } : {}}
                        >
                          {loading ? "Sending ..." : "Send An Email"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoices;
