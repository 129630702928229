import React, { useState } from 'react'
import { VscChromeClose } from "react-icons/vsc";
import modalImage1 from "../../images/modals/typewriter.svg";
import { useEffect } from 'react';
import Select from "react-select";
import { Country, State, City } from 'country-state-city';
import { AiOutlineLoading } from 'react-icons/ai';

const FormModal = ({
    isEdit,
    setCreateRider,
    setIsEdit,
    data,
    formik,
    editRiderLoading,
    addRiderLoading,
    driverSchema,
    setInitialRider,
    setConfirmModal,
    countryCode,
    setCountryCode
}) => {

    const { handleChange, handleBlur, values, setValues, handleSubmit, errors } =
        formik;

    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [start, setStart] = useState(true);

    useEffect(() => {
        setValues({ ...values });
    }, [data]);

    const onCancel = () => {
        setInitialRider(driverSchema);
        setCreateRider(false);
        setIsEdit(false);
    };

    useEffect(() => {
        if (isEdit && start) {
            setCountry({ value: values.country, label: values.country });
            setState({ value: values.state, label: values.state });
            setCity({ value: values.city, label: values.city });
        }
    }, [formik.values])


    // Get list of country, state and city
    const countryList = Country.getAllCountries().map(({ isoCode, name }) => ({ value: isoCode, label: name }));
    const stateList = country ? State.getStatesOfCountry(country.value).map(({ isoCode, name }) => ({ value: isoCode, label: name })) : [];
    const cityList = state ? City.getCitiesOfState(country.value, state.value).map(({ name }) => ({ value: name, label: name })) : [];

    useEffect(() => {
        const countries = Country.getAllCountries();
        const country = countries.find(country => country.name === formik.values.country);
        if (country) {
            setCountryCode(country.isoCode);
        }
    }, [formik.values.country]);

    return (
        <div>
            <div className="modalMask">
                <div className="userModalWrapper" style={{ borderRadius: "12px", width: "690px" }}>
                    <div className="modal__body">
                        <div>
                            <div className="usermodal__body modal__body" style={{ gap: "5px" }}>
                                <div className="flex justify-flex">
                                    <img src={modalImage1} alt="" />
                                    <button
                                        className="close-button"
                                        onClick={onCancel}
                                    >
                                        <VscChromeClose />
                                    </button>
                                </div>
                                <div className="customer-details">
                                    {
                                        isEdit ? "Edit Driver Details!" : "Create New Driver!"
                                    }
                                </div>
                                <div className="please-enter-the-req">
                                    {
                                        isEdit ? "Please enter the required details correctly. Thanks!" : "Please enter the required details correctly. Thanks!"
                                    }
                                </div>
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    if (isEdit) {
                                        setCreateRider(false);
                                        setConfirmModal(true);
                                    } else {
                                        handleSubmit();
                                    }
                                }} action="" className='modal-form'>

                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                First Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.firstName
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="First Name"
                                                        name="firstName"
                                                        onChange={handleChange}
                                                        value={values.firstName}
                                                        defaultValue={values.firstName}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.firstName}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Last Name
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.lastName
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Last Name"
                                                        name="lastName"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.lastName}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.lastName}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Email
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.email
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Email Address"
                                                        name="email"
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        defaultValue={values.email}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Country
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={countryList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="country"
                                                    value={country}
                                                    onChange={value => {
                                                        setStart(false);
                                                        formik.setFieldValue('country', value.label)
                                                        setCountry(value);
                                                        setState("")
                                                        setCity("")
                                                        formik.setFieldValue('state', "")
                                                        formik.setFieldValue('city', "")
                                                    }}
                                                />
                                                <span>{formik.errors.country}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                State
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={stateList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="state"
                                                    value={state}
                                                    isDisabled={!formik.values.country}
                                                    onChange={value => {
                                                        setStart(false);
                                                        formik.setFieldValue('state', value.label)
                                                        setState(value);
                                                        setCity("")
                                                        formik.setFieldValue('city', "")
                                                    }}
                                                />
                                                <span>{formik.errors.state}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                City
                                            </label>
                                            <div className="input__with__error">
                                                <Select
                                                    options={cityList}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            maxHeight: '200px',
                                                            overflow: 'hidden',
                                                        }),
                                                    }}
                                                    placeholder="city"
                                                    value={city}
                                                    isDisabled={!formik.values.state || !formik.values.country}
                                                    onChange={(value) => {
                                                        setStart(false);
                                                        formik.setFieldValue('city', value.label)
                                                        setCity(value)
                                                    }}
                                                />
                                                <span>{formik.errors.city}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Phone
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.mobileNumber
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Enter Phone #"
                                                        name="mobileNumber"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.mobileNumber}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.mobileNumber}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Address
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.address
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Address"
                                                        name="address"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.address}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.address}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Job Title
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.jobTitle
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Job Title"
                                                        name="jobTitle"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.jobTitle}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.jobTitle}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-flex2">
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Postal Code
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.postalCode
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Postal Code"
                                                        name="postalCode"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.postalCode}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.postalCode}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Company
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.company
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Company"
                                                        name="company"
                                                        onChange={handleChange}
                                                        value={values.company}
                                                        defaultValue={values.company}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.company}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="" className="grid-label">
                                                Passport No.
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.passportNo
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="Passport No."
                                                        name="passportNo"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.passportNo}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.passportNo}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-2">
                                        <div style={{width: "204px"}}>
                                            <label htmlFor="" className="grid-label">
                                                National ID
                                            </label>
                                            <div className="input__with__error">
                                                <div
                                                    className={
                                                        errors.nationalid
                                                            ? "error_border_input"
                                                            : "assign_rate_with_logo"
                                                    }
                                                >
                                                    <input
                                                        placeholder="National ID"
                                                        name="nationalid"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.nationalid}
                                                        type="text"
                                                    />
                                                </div>
                                                <span>{formik.errors.nationalid}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-center p-t20">
                                        <button
                                            type='submit'
                                            className="flex-button"

                                        >
                                            {addRiderLoading || editRiderLoading ? <AiOutlineLoading className="loaderIcon" size={18} /> : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormModal