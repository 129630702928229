import React, { useEffect } from 'react';
import './tracking.css';
import { useState } from 'react';
import { FaPlay } from 'react-icons/fa'
import truck from '../../images/tracking/truck.svg';
import tracking from '../../images/tracking/track_image.svg';
import distance from '../../images/tracking/distance.svg';
import time from '../../images/tracking/time.svg';
import sent_email from "../../images/modals/sent-email.png";

import { useLoadScript } from "@react-google-maps/api";

import { IoLogoWhatsapp } from 'react-icons/io'
import { HiLocationMarker } from 'react-icons/hi';
import Maps from './maps';
import { getShipmentDetail, getShipments, searchShipment } from '../../ApIs/tracking';
import { useQuery } from 'react-query';
import moment from 'moment';
import { BiTargetLock } from "react-icons/bi";
import { useToasts } from 'react-toast-notifications';
import { RiSearchLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { VscChromeClose } from 'react-icons/vsc';
import * as yup from 'yup'
import { useFormik } from 'formik';

const invitationSchema = {
    name: "",
    email: ""
}

const invitationValidation = yup.object().shape({
    name: yup.string(),
    email: yup.string().email("*must be a valid email").required("*email is required"),
})


const Tracking = () => {
    const [invitationDetails] = useState(invitationSchema);
    const { addToast } = useToasts();   
    const [details, setDetails] = useState();
    const [detailsLoader, setDetailsLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shipmentDetails, setShipmentDetails] = useState();
    const [keyword, setKeyword] = useState("");
    const [toggle, setToggle] = useState("next-destination");
    const [openModal, setOpenModal] = useState(false);
    const token = localStorage.getItem("token");
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const search = query.get('search')

    useEffect(() => {
        if (search) {
            setKeyword(search);
            searchHandler(undefined, search);
        }
    }, [search])

    const viewDetailsFunc = async (key) => {
        setDetailsLoader(true);
        setDetails(key)
        const d = await getShipmentDetail(token, key);
        setShipmentDetails(d.data)
        setDetailsLoader(false);
    }

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    });

    const { data: shipments, isLoading } = useQuery(["shipments"], () => fetchAllShipments(), {
        onSuccess: async (data) => {
            setDetails(data?.data?.data[0]?.id)
            if (data?.data?.data.length > 0 && !search) {
                const d = await getShipmentDetail(token, data?.data?.data[0]?.id);
                setShipmentDetails(d.data)
            }
        }
    }
    );

    const fetchAllShipments = () => getShipments(token);

    const searchHandler = async (e, keyword) => {
        if (e) e.preventDefault();
        if (keyword) {
            try {
                setDetailsLoader(true);
                const { data } = await searchShipment(token, keyword);
                if (data.success) {
                    setDetails(data?.id)
                    const d = await getShipmentDetail(token, data?.id);
                    setShipmentDetails(d.data)
                    setDetailsLoader(false);
                } else {
                    setDetailsLoader(false);
                    addToast("something went wrong", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            } catch (error) {
                setDetailsLoader(false);
                addToast("something went wrong", {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }
    }

    const {
        values: inviteValues,
        errors: inviteErrors,
        resetForm: inviteReset,
        handleBlur,
        handleChange: handleInviteChange,
        handleSubmit: inviteSubmit
      } = useFormik({
        initialValues: invitationDetails,
        validationSchema: invitationValidation,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true,
    
        onSubmit: (values) => {
          sendInvitation(values);
        }
      })

      const sendInvitation = async ({ email, name }) => {
        try {
          setLoading(true);
          const url = `${process.env.REACT_APP_BASE_URL}api/shipping/shippment/share-tracking`;
          const formData = {
            id: shipmentDetails?.data?.shipmentDetail?.shiftId,
            name: name,
            email: email
          }
    
          const response = await fetch(url, {
            method: 'POST',
            redirect: "follow",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
          });
    
          const data = await response.json();
          if (data?.success) {
            setOpenModal(false);
            addToast(data?.message, { appearance: "success", autoDismiss: true });
            inviteReset();
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
    
      };


    return (
        <div className='main'>
            {openModal && (
                <div className="modalMask">
                    <div
                        style={{ width: "512px", borderRadius: "13px" }}
                        className="userModalWrapper"
                    >
                        <div className="modal__body">
                            <div>
                                <div className="usermodal__body modal__body">
                                    <button
                                        className="close-button absolute-btn"
                                        onClick={() => {
                                            setOpenModal(false);
                                            inviteReset();
                                        }}
                                    >
                                        <VscChromeClose />
                                    </button>
                                    <div className="flex justify-center">
                                        <img src={sent_email} alt="" />
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="no-customer-was-foun sent_invite">
                                            Please enter the required details correctly. Thanks!
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="invite__inputs">
                                            <div className="input__with__error flex justify-center">
                                                <div>
                                                    <label htmlFor="" className="grid-label">
                                                        Full Name
                                                    </label>
                                                    <div
                                                        className={
                                                            inviteErrors.name
                                                                ? "error_border_input invite_input_field"
                                                                : "assign_rate_with_logo0 invite_input_field"
                                                        }
                                                    >
                                                        <input
                                                            name="name"
                                                            onChange={handleInviteChange}
                                                            onBlur={handleBlur}
                                                            value={inviteValues.name}
                                                            placeholder="Enter Full Name"
                                                            type="text"
                                                        />
                                                        <span className="margin-left-80">
                                                            {inviteErrors.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input__with__error flex justify-center">
                                                <div>
                                                    <label htmlFor="" className="grid-label">
                                                        Email Address
                                                    </label>
                                                    <div
                                                        className={
                                                            inviteErrors.email
                                                                ? "error_border_input invite_input_field"
                                                                : "assign_rate_with_logo0 invite_input_field"
                                                        }
                                                    >
                                                        <input
                                                            name="email"
                                                            onChange={handleInviteChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Enter Email"
                                                            value={inviteValues.email}
                                                            type="text"
                                                        />
                                                        <span className="margin-left-80">
                                                            {inviteErrors.email}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-center">
                                                <button
                                                    className="error_create_new error_create_new"
                                                    onClick={() => inviteSubmit()}
                                                    style={loading ? { cursor: "no-drop" } : {}}
                                                >
                                                    {loading ? "Sending ..." : "Send An Email"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="breadcrumb">
                <BiTargetLock className="breadcrumb_icon" />
                <span>/</span>
                <p>Tracking</p>
            </div>
            {
                isLoading ? (
                    <div className='track__main__loader'>
                        <div class="lds-dual-ring"></div>
                    </div>
                ) :
                    shipments?.data?.data.length ?
                        (
                            <div className='tracking__wrap' onSubmit={(e) => searchHandler(e, keyword)}>
                                <form action="">
                                    <div className="flex-input-wrap">
                                        <div className="flex-input">
                                            <div
                                                className="assign_rate_with_logo"
                                                style={{ borderRadius: "50px 0 0 50px", width: "300px", borderRight: "none", background: "#fff" }}
                                            >
                                                <input
                                                    type="text"
                                                    placeholder='Search by ShipmentID'
                                                    value={keyword}
                                                    onChange={(e) => setKeyword(e.target.value)}
                                                />
                                            </div>
                                            <div className="input-with-icon" style={{ borderRadius: "0 50px 50px 0" }}>
                                                <RiSearchLine
                                                    onClick={(e) => {
                                                        searchHandler(e, keyword)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' style={{ display: "none" }}></button>
                                </form>
                                <div className="tracking__wrapping">
                                    <div className='tracking__left'>
                                        <h5>Shipment Tracking</h5>
                                        <div className='tracking__cards__wrapper'>
                                            {
                                                shipments?.data?.data?.map((shipment, index) => (
                                                    <div
                                                        className="tracking__card"
                                                        style={{
                                                            background: `${shipment.status === "accept" ? "#fff" : shipment.status === "start" ? "#FFD07B" : "#354FAA"}`,
                                                            color: `${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}`
                                                        }}
                                                    >
                                                        <div className='flex'>
                                                            <h6 className='tracking_label'>SHIPMENT ID:</h6>
                                                            <p className='tracking_value'>{shipment.shiftId}</p>
                                                        </div>
                                                        <div className='flex'>
                                                            <h6 className='tracking_label'>CURRENT STATUS:</h6>
                                                            <p className='tracking_value'>
                                                                {
                                                                    shipment.status === "accept"
                                                                        ? "Driver Checking"
                                                                        : shipment.status === "start"
                                                                            ? "In Transit" 
                                                                            : shipment.status === "delivered" ? "All Completed"
                                                                            : "Out of delivering"
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="progress__bar" style={{ background: `${shipment.status === "accept" ? "rgba(13,14,22,0.6)" : shipment.status === "start" ? "rgba(13,14,22,0.6)" : "rgba(255,255,255,0.6)"}` }}>
                                                            <p
                                                                className="content"
                                                                style={{
                                                                    background: `${shipment.status === "accept" ? "#0D0E16" : shipment.status === "start" ? "#0D0E16" : "#fff"}`,
                                                                    width: `${shipment.status === "accept" ? "33.33%"
                                                                            : shipment.status === "start" ? "67%"
                                                                                : shipment.status === "delivered" ? "100%" : "90%"
                                                                        }`
                                                                }}
                                                            ></p>
                                                        </div>
                                                        <div className="tracking__states">
                                                            <div className={(shipment.status === "start" || shipment.status === "delivering" || shipment.status === "delivered") ? "line_through state_wrap" : "state_wrap"} >
                                                                <div className="flex">
                                                                    <div
                                                                        className="filled_dot"
                                                                        style={{ background: `${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}` }}
                                                                    >
                                                                        <div className="line" style={{ borderLeft: `1px ${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"} dotted` }}></div>
                                                                    </div>
                                                                    <p>Checking</p>
                                                                    <p>{shipment?.checkInCity ? (shipment.checkInCity) : "-"}</p>
                                                                </div>
                                                                <p>{shipment.checkingTime ? moment(shipment.checkingTime).utc().format("HH:mm (MMM D, YYYY)") : "-"}</p>
                                                            </div>
                                                            <div className={(shipment.status === "delivering" || shipment.status === "delivered") ? "line_through state_wrap" : "state_wrap"}>
                                                                <div className="flex">
                                                                    <div
                                                                        className={(shipment.status === "start" || shipment.status === "delivering" || shipment.status === "delivered") ? "filled_dot" : "empty_dot"}
                                                                        style={(shipment.status === "start" || shipment.status === "delivering" || shipment.status === "delivered") ? { background: `${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}` } : { border: `1.4px solid ${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}` }}
                                                                    >
                                                                        <div className="line" style={{ borderLeft: `1px ${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"} dotted` }}></div>
                                                                    </div>
                                                                    <p>In Transit</p>
                                                                    <p>{shipment?.startCity ? (shipment.startCity) : "-"}</p>
                                                                </div>
                                                                <p>{shipment.startTime ? moment(shipment.startTime).utc().format("HH:mm (MMM D, YYYY)") : "-"}</p>

                                                            </div>
                                                            <div className={(shipment.status === "delivering" || shipment.status === "delivered") ? "line_through state_wrap" : "state_wrap"}>
                                                                <div className="flex">
                                                                    <div
                                                                        className={(shipment.status === "delivering" || shipment.status === "delivered") ? "filled_dot" : "empty_dot"}
                                                                        style={(shipment.status === "delivering" || shipment.status === "delivered") ? { background: `${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}` } : { border: `1.4px solid ${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}` }}
                                                                    >
                                                                        <div className="line" style={{ borderLeft: `1px ${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"} dotted` }}></div>
                                                                    </div>
                                                                    <p>Out For Delivery</p>
                                                                    {/* <p>{shipment?.deliveringCity ? (shipment.deliveringCity) : "-"}</p> */}

                                                                </div>

                                                                <p>{shipment.deliveringTime ? moment(shipment.deliveringTime).utc().format("HH:mm (MMM D, YYYY)") : "-"}</p>
                                                            </div>
                                                            <div className={(shipment.status === "delivered") ? "line_through state_wrap" : "state_wrap"}>
                                                                <div className="flex">
                                                                    <div
                                                                        className={(shipment.status === "delivered" || shipment.status === "delivered") ? "filled_dot" : "empty_dot"}
                                                                        style={(shipment.status === "delivered" || shipment.status === "delivered") ? { background: `${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}` } : { border: `1.4px solid ${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}` }}
                                                                    ></div>
                                                                    <p>Delivered</p>
                                                                    {/* <p>{shipment?.deliveringCity ? (shipment.deliveringCity) : "-"}</p> */}
                                                                </div>
                                                                <p>{shipment?.deliveredTime ? moment(shipment?.deliveredTime).utc().format("HH:mm (MMM D, YYYY)") : "-"}</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={() => viewDetailsFunc(shipment.id)}
                                                            className='card_btn'
                                                            style={{
                                                                background: `${shipment.status === "accept" ? "#000" : shipment.status === "start" ? "#000" : "#fff"}`,
                                                                color: `${shipment.status === "accept" ? "#fff" : shipment.status === "start" ? "#fff" : "#354FAA"}`
                                                            }}
                                                        >
                                                            {details === shipment.id ? "Currently Tracking ..." : "View Details"}
                                                        </button>
                                                        {details === shipment.id && (
                                                            <FaPlay
                                                                className="arroww"
                                                                style={{
                                                                    color: `${shipment.status === "accept" ? "#fff"
                                                                            : shipment.status === "start"
                                                                                ? "#FFD07B" : "#354FAA"
                                                                        }`
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    {
                                        detailsLoader ? (
                                            <div className='track__right__loader'>
                                                <div class="lds-dual-ring"></div>
                                            </div>
                                        ) :
                                            shipmentDetails && (
                                                <div className='tracking__right'>
                                                    <div className='track-header'>
                                                        <h5>SHIPMENT ID: {shipmentDetails?.data?.shipmentDetail?.shiftId}</h5>

                                                        <button className='tracking-btn' onClick={() => setOpenModal(true)}>
                                                            Share Tracking
                                                        </button>
                                                    </div>
                                                    <div className='right__top'>
                                                        <div className='right__top__left'>
                                                            <div className='flex-between'>
                                                                <div className='flex-col'>
                                                                    <img src={tracking} alt="" className="tracking_image" />
                                                                    <p className='truck_label'>Truck Type</p>
                                                                    <p className='truck_value'>{shipmentDetails?.data?.truckDetail?.name}</p>
                                                                    <p className='truck_label'>Truck Capacity</p>
                                                                    <p className='truck_value'>{shipmentDetails?.data?.truckDetail?.weight / 1000} t </p>
                                                                </div>
                                                                <div>
                                                                    <img src={truck} alt="truck" />
                                                                </div>
                                                            </div>
                                                            <div className='flex-10' style={{ justifyContent: "space-between" }}>
                                                                <div className="flex-col">
                                                                    <p className='truck_label'>Vehicle (Height x Width x Length)</p>
                                                                    <p className='truck_value'>{shipmentDetails?.data?.truckDetail?.height} x {shipmentDetails?.data?.truckDetail?.width} x {shipmentDetails?.data?.truckDetail?.length} ({shipmentDetails?.data?.truckDetail?.otherUnit})</p>
                                                                </div>
                                                                {/* <div className="flex-col">
                                                        <p className='truck_label'>Loaded Volume</p>
                                                        <p className='truck_value'>{shipmentDetails?.data?.truckDetail?.capacity}</p>
                                                    </div> */}
                                                                <div className="flex-col">
                                                                    <p className='truck_label'>Pickup Locations</p>
                                                                    <p className='truck_value'>{shipmentDetails?.data?.shipmentDetail?.totalPickup}</p>
                                                                </div>
                                                                <div className="flex-col">
                                                                    <p className='truck_label'>Dropoff Locations</p>
                                                                    <p className='truck_value'>{shipmentDetails?.data?.shipmentDetail?.totalDelivery}</p>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className='right__top__right'>
                                                            {
                                                                shipmentDetails?.data?.userDetails ? (
                                                                    <>
                                                                        <span style={{ fontWeight: "Bold" }}>Drivers Details</span>
                                                                        <div className="avatar">
                                                                            <img src={shipmentDetails?.data?.userDetails?.profilePhoto} alt="" />
                                                                        </div>
                                                                        <p className='name'>{shipmentDetails?.data?.userDetails?.fullName ? shipmentDetails?.data?.userDetails?.fullName : shipmentDetails?.data?.userDetails?.firstName + " " + shipmentDetails?.data?.userDetails?.lastName}</p>
                                                                        {/* <div className='track_user_status'>
                                                    <div className="online"></div>
                                                    <span className='user-status'>Online</span>
                                                </div> */}
                                                                        {
                                                                            shipmentDetails?.data?.userDetails?.vat ? (

                                                                                <div className='track_user'>
                                                                                    <p className='truck_label'>VAT:</p>
                                                                                    <p className='truck_value'>{shipmentDetails?.data?.userDetails?.vat}</p>
                                                                                </div>
                                                                            ) : ""
                                                                        }
                                                                        <a href={`https://wa.me/${shipmentDetails?.data?.userDetails?.mobileNumber}`} rel="noreferrer" target='_blank' className='track_whatsapp_btn'>
                                                                            <IoLogoWhatsapp />
                                                                            <p>Whats App</p>
                                                                        </a>
                                                                    </>
                                                                ) : (
                                                                    <div>
                                                                        <p className='name'>No Driver Assigned</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="right__bottom">
                                                        <div className='right__bottom__left'>
                                                            <div className="track__toggle__wrapper">
                                                                <h4
                                                                    onClick={() => setToggle("next-destination")}
                                                                    className={`${toggle === "next-destination" ? "track__toggle track__toggle__active" : "track__toggle"}`}
                                                                >
                                                                    Next Destination
                                                                </h4>
                                                                <h4
                                                                    onClick={() => setToggle("delivery-history")}
                                                                    className={`${toggle === "delivery-history" ? "track__toggle track__toggle__active" : "track__toggle"}`}
                                                                >
                                                                    Delivery History
                                                                </h4>
                                                                {/* <h4
                                                        onClick={() => setToggle("message")}
                                                        className={`${toggle === "message" ? "track__toggle track__toggle__active" : "track__toggle"}`}
                                                    >
                                                        Message
                                                    </h4> */}
                                                            </div>
                                                            <div className=''>
                                                                {
                                                                    toggle === "next-destination" && (
                                                                        <div className='next_destination'>
                                                                            <p className='on_the_way'>On The Way To Deliver:</p>
                                                                            <div className="scroller">
                                                                                {
                                                                                    shipmentDetails?.data?.shipmentDetail?.deliveryPending?.map((delivery) => (
                                                                                        <div className='details__scroller'>
                                                                                            <div className="ontheway_details">
                                                                                                <div className="flex-10" style={{ alignItems: "center", gap: "10px" }}>
                                                                                                    <HiLocationMarker />
                                                                                                    <p className='truck_label2'>Delivery Location:</p>
                                                                                                </div>
                                                                                                <p className="truck_label">
                                                                                                {/* {delivery?.address} */}
                                                                                                jrngijr rkjbfgijerf kjrbgiureg rjghiorg kjrgbijrng ornogrngo
                                                                                                </p>
                                                                                                <div className="flex-10">
                                                                                                    <p className='truck_label2'>Special Instructions: {delivery?.specialInstructions}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='detail_card_wrapper'>
                                                                                                <div className="detail_card">
                                                                                                    <div className='detail_card_image'>
                                                                                                        <img src={distance} alt="" />
                                                                                                    </div>
                                                                                                    <p className='truck_label'>Date</p>
                                                                                                    <p className='truck_value'>{delivery?.date}</p>
                                                                                                </div>
                                                                                                <div className="detail_card">
                                                                                                    <div className='detail_card_image'>
                                                                                                        <img src={time} alt="" />
                                                                                                    </div>
                                                                                                    <p className='truck_label'>Estimated Time</p>
                                                                                                    <p className='truck_value'>{delivery?.time}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    toggle === "delivery-history" && (
                                                                        <div className='next_destination'>
                                                                            <p className='on_the_way'>Deliver Done:</p>
                                                                            <div className="scroller">
                                                                                {
                                                                                    shipmentDetails?.data?.shipmentDetail?.deliveryDone?.map((delivery) => (
                                                                                        <div className='details__scroller'>
                                                                                            <div className="ontheway_details">
                                                                                                <div className="flex-10">
                                                                                                    <HiLocationMarker />
                                                                                                    <p className='truck_label2'>Delivery Location:</p>
                                                                                                </div>
                                                                                                <p className="truck_label">{delivery?.address}</p>
                                                                                                <div className="flex-10">
                                                                                                    <p className='truck_label2'>Special Instructions: {delivery?.specialInstructions}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='detail_card_wrapper'>
                                                                                                <div className="detail_card">
                                                                                                    <div className='detail_card_image'>
                                                                                                        <img src={distance} alt="" />
                                                                                                    </div>
                                                                                                    <p className='truck_label'>Date</p>
                                                                                                    <p className='truck_value'>{delivery?.date}</p>
                                                                                                </div>
                                                                                                <div className="detail_card">
                                                                                                    <div className='detail_card_image'>
                                                                                                        <img src={time} alt="" />
                                                                                                    </div>
                                                                                                    <p className='truck_label'>Estimated Time</p>
                                                                                                    <p className='truck_value'>{delivery?.time}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="right__bottom__right">
                                                            {isLoaded ? (
                                                                <div style={{ border: "5px solid white", height: "635px" }}>
                                                                    {
                                                                        shipmentDetails?.data?.userDetails ? (

                                                                            <Maps user={shipmentDetails?.data?.userDetails} shipmentId={shipmentDetails?.data?.shipmentDetail?._id} />
                                                                        ) : <><p style={{ textAlign: "center", marginTop: "20px", fontWeight: "bold" }}>No Trackable Driver</p></>
                                                                    }
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        ) : (
                            <div style={{ textAlign: "center", marginTop: "30vh" }}>
                                <div >No Trackable Order Found</div>
                            </div>

                        )
            }
        </div>
    )
}



export default Tracking