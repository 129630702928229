import React, { useState } from "react";
import Select from "react-select";
import modalImage1 from "../../../images/modals/packagedetail.png";
import { VscChromeClose } from "react-icons/vsc";
import { AiOutlineLoading } from "react-icons/ai";

const EditPackageDetail = ({
  data,
  showModal,
  pickupOptions,
  setShowModal,
  deliveryOptions,
  setReloadData,
  setRowData,
  formik,
  setInitialValues,
  schema,
  updateData,
  isLocal,
  setData
}) => {
  const [loading, setLoading] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "#666",
      fontSize: "11.5px",
      backgroundColor: state.isSelected ? "#354faa" : "transparent",
      cursor: "pointer", // Add cursor style
      ":hover": {
        backgroundColor: state.isSelected ? "#354faa" : "#e0e0e0", // Custom hover background color
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#000",
      fontSize: "11.5px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#666",
      fontSize: "13px",
    }),
  };

  const {
    delivery_id,
    pickup_id,
  } = formik.values;

  return (
    <>
      {showModal && (
        <div className="modalMask">
          <div className="userModalWrapper">
            <div className="modal__body">
              <div>
                <div className="usermodal__body modal__body">
                  <div className="flex justify-flex">
                    <img src={modalImage1} alt="" />
                    <button
                      className="close-button"
                      onClick={() => {
                        setShowModal(false);
                        setInitialValues(schema);
                      }}
                    >
                      <VscChromeClose />
                    </button>
                  </div>
                  <form
                    className="usermodal__body"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      setTimeout(() => {
                        if (delivery_id && pickup_id) {
                          formik.handleSubmit();
                        } else {
                          setShowErrorMessage(true);
                          setTimeout(() => {
                            setShowErrorMessage(false);
                          }, 3000);
                        }
                        setLoading(false);
                        setInitialValues(schema);
                      }, 1000);
                    }}
                    action=""
                  >
                    <div className="package-details">Package Details</div>
                    <div className="please-enter-the-req">
                      Please enter required details correctly thanks
                    </div>
                    <div className="grid-flex">
                      <div>
                        <label htmlFor="" className="grid-label">
                          Pickup Id
                        </label>
                        <Select
                          placeholder="Select Pickup Id"
                          styles={customStyles}
                          options={pickupOptions ? pickupOptions : []}
                          name="pickup_id"
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "pickup_id",
                              selectedOption?.value
                            );
                          }}
                          value={
                            formik.values.pickup_id === ""
                              ? null
                              : pickupOptions.filter((el) => {
                                  return el.value === formik.values.pickup_id;
                                })
                          }
                        />
                      </div>
                      <div>
                        <label htmlFor="" className="grid-label">
                          Delivery Id
                        </label>
                        <Select
                          styles={customStyles}
                          placeholder="Select Delivery Id"
                          options={deliveryOptions ? deliveryOptions : []}
                          name="delivery_id"
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "delivery_id",
                              selectedOption?.value
                            );
                          }}
                          value={
                            formik.values.delivery_id === ""
                              ? null
                              : deliveryOptions.filter((el) => {
                                  return el.value === formik.values.delivery_id;
                                })
                          }
                        />
                      </div>
                    </div>
                    <div className="grid-flex">
                      <div>
                        <label htmlFor="" className="grid-label">
                          Number of Packages
                        </label>
                        <div className="assign_rate_with_logo input__with__error">
                          <input
                            type="text"
                            value={formik?.values?.number_of_Packages}
                            name="number_of_Packages"
                            onChange={formik.handleChange}
                            placeholder="Enter No of Packages"
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="" className="grid-label">
                          Article Name
                        </label>
                        <div className="assign_rate_with_logo input__with__error">
                          <input
                            type="text"
                            value={formik?.values?.article_name}
                            name="article_name"
                            onChange={formik.handleChange}
                            placeholder="Enter Article Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid-flex">
                      <div>
                        <div className="grid-flex">
                          <div>
                            <label htmlFor="" className="grid-label">
                              Article No.
                            </label>
                            <div className="assign_rate_with_logo input__with__error">
                              <input
                                type="text"
                                value={formik?.values?.article_no}
                                name="article_no"
                                onChange={formik?.handleChange}
                                placeholder="Enter Article No."
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="" className="grid-label">
                              Article Content
                            </label>
                            <div className="assign_rate_with_logo input__with__error">
                              <input
                                type="text"
                                value={formik?.values?.article_content}
                                name="article_content"
                                onChange={formik?.handleChange}
                                placeholder="Enter Article Content"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="" className="grid-label">
                          Package Weight (kg)
                        </label>
                        <div className="assign_rate_with_logo input__with__error">
                          <input
                            type="number"
                            value={formik?.values?.package_weight}
                            name="package_weight"
                            onChange={formik?.handleChange}
                            placeholder="Enter Package Weight"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid-flex">
                      <div>
                        <div className="grid-flex">
                          <div className="flex-between-size">
                            <label htmlFor="" className="grid-label">
                              Package Length <br/>(cm)
                            </label>
                            <div className="assign_rate_with_logo input__with__error">
                              <input
                                type="number"
                                value={formik?.values?.package_length}
                                name="package_length"
                                onChange={formik?.handleChange}
                                placeholder="Package Length"
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="" className="grid-label">
                              Package Width <br/>(cm)
                            </label>
                            <div className="assign_rate_with_logo input__with__error">
                              <input
                                type="number"
                                value={formik?.values?.package_width}
                                name="package_width"
                                onChange={formik?.handleChange}
                                placeholder="Package Width"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="grid-flex">
                          <div>
                            <label htmlFor="" className="grid-label">
                              Package Height <br/>(cm)
                            </label>
                            <div className="assign_rate_with_logo input__with__error">
                              <input
                                type="number"
                                value={formik?.values?.package_height}
                                name="package_height"
                                onChange={formik?.handleChange}
                                placeholder="Package Height"
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="" className="grid-label">
                              Package Volume <br/>(Cubic Meter)
                            </label>
                            <div className="assign_rate_with_logo">
                              <input
                                type="number"
                                value={formik?.values?.package_volume}
                                name="package_volume"
                                onChange={formik?.handleChange}
                                placeholder="Package Volume"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="input__with__error">
                      {showErrorMessage ? (
                        <span>Pickup ID and Delivery ID are required</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex-center">
                      <button className="flex-button" type="submit">
                        {loading ? (
                          <AiOutlineLoading className="loaderIcon" size={18} />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPackageDetail;
