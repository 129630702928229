import {
    Button,
    Row,
    Col,
    ModalBody,
    Modal,
    ModalHeader,
    Container,
    Input,
    Spinner
  } from "reactstrap";
  import React from "react";
  import DataTabelComponent from "../../components/tables";
  import { useState } from "react";
  import { useEffect } from "react";
  import DelIcon from "../../images/editDelete/del.svg";
  import EyeIcon from "../../images/editDelete/eye.svg";
  import left_slider from '../../images/icons/left_slider.svg';
  import right_slider from '../../images/icons/right_slider.svg';
  import CloseIcon from "../../images/editDelete/cross.png";
  import Select from "react-select";
  import { useNavigate, useParams } from "react-router-dom";
  import { getOrdersFunc } from "../../ApIs/orders";
  import { getShiftFunc } from "../../ApIs/shifts";

  import { useQuery, useQueryClient } from "react-query";
  import { useToasts } from "react-toast-notifications";
  import "./css/createOrder.css";
  // Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs  } from "swiper";
  
  const CompletedOrders = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("delivered");
    const [selectedOrders, setSelectedOrders] = useState();
    const [filterTable, setFilterTable] = useState([]);
    const [modal_static4, setModal_static4] = useState(false);
    const [modal_static5, setModal_static5] = useState(false);
    const [modal_static6, setModal_static6] = useState(false);
    const [modal_static7, setModal_static7] = useState(false);
    const [activeTabJustify, setActiveTabJustify] = useState("1");
    const [modal_static8, setModal_static8] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const queryClient = useQueryClient();
    const { addToast } = useToasts();
    const [orderId, setOrderId] = useState("");
    const token = localStorage.getItem("token");
    const [rowData, setRowData] = useState([]);
    const [ordersDate, setOrdersDate] = React.useState("");

    const params = useParams();

    const columns = [
      {
        name: "Sr#",
        selector: (row, index) => index + 1,
      },
      
      {
        name: "Order Id",
        selector: (row) => row.orderId,
      },
      {
        name: "Internal Reference No",
        selector: (row) => row.internalReferenceNumber,
      },
      {
        name: "Name",
        selector: (row) => row.contactName,
      },
      {
        name: "Item Type",
        selector: (row) => row.itemType,
      },
      {
        name: "Item No.",
        selector: (row) => row.itemCount,
      },
      {
        name: "Phone",
        selector: (row) => row.contactPhoneNumber,
      },
      {
        name: "Email",
        selector: (row) => row.contactEmail,
      },
      {
        name: "Address",
        selector: (row) => row?.address?.street,
      },
      {
        name: "Suburb",
        selector: (row) => row?.address?.suburb,
      },
      {
        name: "State",
        selector: (row) => row?.address?.state,
      },
      {
        name: "Post Code",
        selector: (row) => row?.address?.postalCode,
      },
      {
        name: "Description",
        selector: (row) => row.year,
      },
      {
        name: "",
        cell: (row) => (
          <>
            <span onClick={() => {
              setModal_static7(true);
              setOrderId(row?._id);
            }}>
              <img onClick={() => setRowData(row)} alt="eye-icon" src={EyeIcon} style={{width: "17px", marginRight: "5px", cursor: "pointer"}} />  
            </span>
            <span
              onClick={() => {
                setModal_static8(true);
                setOrderId(row?._id);
              }}
            >
              <img src={DelIcon} />
            </span>
          </>
        ),
      },
      {
        name: "Status",
        selector: (row) => <span className={row.status === "delivered" ? 'text-success' : 'text-danger'}>{row.status === "delivered" && "Delivered"}</span>
      
      }
    ];
  
    const toggleCustomJustified = (tab) => {
      if (activeTabJustify !== tab) {
        setActiveTabJustify(tab);
      }
    };
  
    const removeBodyCss = () => {
      document.body.classList.add("no_padding");
    };
    const tog_static4 = () => {
      setModal_static4(!modal_static4);
      removeBodyCss();
    };

    const tog_static5 = () => {
      setModal_static5(!modal_static5);
      removeBodyCss();
    };

    const tog_static6 = () => {
      setModal_static6(!modal_static6);
      removeBodyCss();
    };

    const tog_static7 = () => {
      setModal_static7(!modal_static7);
      setRowData([])
      setThumbsSwiper(null)
      setCurrentIndex(0)
      removeBodyCss();
    };
  
    const tog_static8 = () => {
      setModal_static8(!modal_static8);
      removeBodyCss();
    };
  
    const durationOptions = [
      { value: '2-3', label: "2-3 Hours" },
      { value: '3-4', label: "3-4 Hours" },
      { value: '4-5', label: "4-5 Hours" },
    ];
  
    const timeOptions = [
      { value: "08:00 A.M", label: "08:00 A.M" },
      { value: "09:00 A.M", label: "09:00 A.M" },
      { value: "10:00 A.M", label: "10:00 A.M" },
      { value: "11:00 A.M", label: "11:00 A.M" },
      { value: "12:00 P.M", label: "12:00 P.M" },
      { value: "13:00 P.M", label: "13:00 P.M" },
      { value: "14:00 P.M", label: "14:00 P.M" },
  
    ];

    const dateOptions = [
      { value: "25hrs", label: "Last 24 Hours" },
      { value: "3days", label: "Last 3 Days" },
      { value: "7days", label: "Last 7 Days" },
      { value: "15days", label: "Last 15 Days" },
      { value: "30days", label: "Last 30 Hours" },
      // { value: "custom", label: "Custom Date Range" },
    ]

    const actionOptions = [
      { value: "delete", label: "Delete Selected" },
      { value: "export", label: "Export as CSV" },
    ]

    const actionsChangeFunc = (e) => {
      if(e.value === "delete"){
        setModal_static5(true);
      } else if (e.value === "export"){
        setModal_static6(true);
      }
    }


    
  

    // *************
    const Shift = useQuery(["singleShifts"], () => getShiftFunc(token, params.id), false);
    const AllOrdersData = Shift?.data?.data;

  
    useEffect(() => {
      queryClient.invalidateQueries({ queryKey: ['singleShifts'] })



      return () => {
        queryClient.invalidateQueries({ queryKey: ['singleShifts'] })
      }
    }, [])

  
    const [createShiftLoading, setCreateShiftLoading] = useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [duration, setDuration] = React.useState("");
    const [date, setDate] = React.useState("");
    const [startTime, setStartTime] = React.useState("");
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
  
    const handleChange = (state ) => {
      const orders = state?.selectedRows?.map((order) => order._id)
      setSelectedRows(orders);
    };

    const rowClick = (state) => {
    }
    const CreateShiftFunc = () => {
      setCreateShiftLoading(true);
      fetch(`${process.env.REACT_APP_BASE_URL}/api/shifts/createShift`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          orders: selectedRows,
          address: {
            postalCode: 2125,
            state: "NSW",
            suburb: "West Pennant Hills",
            street: "16 Royal Oak Pl"
          },
          duration: duration,
          startTime: startTime,
          date: date
        }),
      })
        .then((result3) => {
          if (result3.status === 200) {
            setCreateShiftLoading(false);
            addToast("Shift Created Successfully", {
              appearance: "success",
              autoDismiss: true,
            });
  
            setModal_static8(false);
            setModal_static4(false)
            // navigate("/manage_shifts");
            window.location.href = "/#/manage_shifts";
            queryClient.invalidateQueries("AllShifts");
  
          } else if (result3.status === 204) {
            setCreateShiftLoading(false);
            addToast("Shift Created Successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            setModal_static8(false);
            queryClient.invalidateQueries("AllShifts");
          } else {
            setCreateShiftLoading(false);
            addToast(result3?.error, {
              appearance: "error",
              autoDismiss: true,
            });
            setModal_static8(false);
            queryClient.invalidateQueries("AllOrders");
          }
        })
        .catch((error) => {
          setCreateShiftLoading(false);
          setModal_static8(false);
          addToast("Something Went Wrong", {
            appearance: "error",
            autoDismiss: true,
          })
        }
        );
    };

    
  
  
    const DelOrderFunc = () => {
      fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/${orderId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({}),
      })
        .then((result3) => {
          if (result3.status === 200) {
            addToast("Successfully Deleted", {
              appearance: "success",
              autoDismiss: true,
            });
  
            setModal_static8(false);
            // queryClient.invalidateQueries("singleShifts");
            setTimeout(() => {
              window.location.reload(false);
            }, 500)

          } else if (result3.status === 204) {
            addToast("Successfully Deleted", {
              appearance: "success",
              autoDismiss: true,
            });
            setModal_static8(false);
            queryClient.invalidateQueries("singleShifts");
          } else {
            addToast(result3?.error, {
              appearance: "error",
              autoDismiss: true,
            });
            setModal_static8(false);
            queryClient.invalidateQueries("singleShifts");
          }
        })
        .catch((error) =>
          addToast("Something Went Wrong", {
            appearance: "error",
            autoDismiss: true,
          })
        );
    };
  
    useEffect(() => {
      const result = AllOrdersData?.orders?.filter((e) => {
        return (
          e?.orderId?.toLowerCase().match(search.toLowerCase()) ||
          e?.contactName?.toLowerCase().match(search.toLowerCase()) ||
          e?.internalReferenceNumber?.toLowerCase().match(search.toLowerCase())
          // e?.internalReferenceNumber?.match(search)
        );
      });
      setFilterTable(result);
    }, [search]);
  
    return (
      <div>
        <div className="p-4">
          <DataTabelComponent
            title=""
            columns={columns}
            progressPending={Shift.isLoading}
            data={filterTable ? filterTable : AllOrdersData?.orders}
            pagination={true}
            fixedHeader={false}
            fixedHeaderScrollHeight="1000px"
            selectableRows={status === "pending" ? true : false}
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggledClearRows}
            selectableRowsHighlight={true}
            highlightOnHover={true}
            subHeader={true}
            // rowClicking={() => {console.log("Hello")}}
            subHeaderComponent={
              <div className="w-100">
                <Row className="mb-2 mt-2">
                
                <Col lg={4} md={4} sm={4}>
                  <span className="">
                    <Select
                      className="c_o_select_css2 mx-2"
                      options={dateOptions}
                      placeholder="Choose Date Range"
                      onChange={(e) => setOrdersDate(e.value)}
                    ></Select>
                  </span>
                </Col>
                  <Col lg={3} md={3} sm={3} style={{ display: "flex" }}>
                    
                    <span className="px-2">
                      <Select
                        isDisabled={selectedRows.length === 0}
                        className="c_o_select_css2 mx-auto"
                        options={actionOptions}
                        placeholder="Bulk Actions"
                        onChange={(e) => actionsChangeFunc(e)}
                      ></Select>
                    </span>
                  </Col>
                  <Col lg={3} md={3} sm={3}>
                  <span>
                    <input
                      type="text"
                      placeholder="search by id | name | phone"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </span>
                </Col>
                   
                </Row>
              </div>
            }
          />
         
        </div>
  
        <div>
          <Modal isOpen={modal_static8} toggle={tog_static8} centered={true}>
            <ModalBody className="diverdelmodel">
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <p className="m_d_popup_font">Are You Sure?</p>
                    <p>
                      If You Proceed, you will lose your all your order data. Are
                      you sure you want to delete this order
                    </p>
                  </Col>
                </Row>
  
                <Row className="mt-5">
                  <Col md={6}>
                    <Button
                      color="light"
                      className="w-100"
                      onClick={DelOrderFunc}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Confirm
                      </p>
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      color="light"
                      outline
                      className="w-100 "
                      onClick={() => setModal_static8(false)}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Not Now
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal isOpen={modal_static5} toggle={tog_static5} centered={true}>
            <ModalBody className="diverdelmodel">
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <p className="m_d_popup_font">Are You Sure?</p>
                    <p>
                      If You Proceed, you will lose your all your order data. Are
                      you sure you want to delete this order
                    </p>
                  </Col>
                </Row>
  
                <Row className="mt-5">
                  <Col md={6}>
                    <Button
                      color="light"
                      className="w-100"
                      onClick={DelOrderFunc}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Confirm
                      </p>
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      color="light"
                      outline
                      className="w-100 "
                      onClick={() => setModal_static5(false)}
                    >
                      <p className="m_d_popup_bttns" style={{ margin: "auto" }}>
                        Not Now
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal isOpen={modal_static7} toggle={tog_static7} centered={true}>
              <ModalHeader className="m_d_popup_header details-modal">
              <div className="w-100">
                  <Row>
                    <Col lg={10} md={10} sm={10}>
                      <p>Receipt Details</p>
                    </Col>
                    <Col lg={2} md={2} sm={2}>
                      <span onClick={() => {
                        setModal_static7(false)
                        setRowData([])
                        setThumbsSwiper(null)
                        setCurrentIndex(0)
                      }}>
                        <img alt="eye-icon" src={CloseIcon} style={{width: "17px", cursor: "pointer"}} />  
                      </span>
                    </Col>
                  </Row>
                </div>
              </ModalHeader>
              <ModalBody style={{border: "5px solid #354faa"}}>
                <div className="order-details-popup-main">
                  <div className="order-details-popup">
                  {
                    rowData.deliverConfirmDoc && (
                      <>
                          <Swiper
                            style={{
                              "--swiper-navigation-color": "#fff",
                              "--swiper-pagination-color": "#fff",
                            }}
                            loop={false}
                            spaceBetween={10}
                            navigation={{
                              nextEl: ".swiper-btn-next",
                              prevEl: ".swiper-btn-prev",
                            }}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                            onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
                          >
                            {
                              rowData.deliverConfirmDoc.map(item => (
                                <SwiperSlide>
                                  <img src={item} alt=""/>
                                </SwiperSlide>
                              ))
                            }
                          
                          </Swiper>
                          <div className="slider-controler">
                            {rowData?.deliverConfirmDoc.length > 1 && (
                              <>
                                <div className={`swiper-btn-prev slider-arrow hidden ${currentIndex > 0 ? "display" : ""}`}>
                                  <img src={left_slider} alt="left-arrow" />
                                </div>
                                <div className={`swiper-btn-next slider-arrow hidden ${currentIndex < rowData.deliverConfirmDoc.length - 1 ? "display" : ""}`}>
                                  <img src={right_slider} alt="right-arrow" />
                                </div>
                              </>
                            )}
                          </div>
                        <Swiper
                          onSwiper={setThumbsSwiper}
                          loop={false}
                          spaceBetween={10}
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper"
                        >
                          {
                            rowData.deliverConfirmDoc.map(item => (
                              <SwiperSlide>
                                <img src={item} alt=""/>
                              </SwiperSlide>
                            ))
                          }
                        </Swiper>
                      </>
                    )
                  }
                  
                  </div>
                  <div className="details-wrapper">
                    <div className="detail">
                      <h5>Receiver Name: </h5>
                      <p>{rowData?.contactName}</p>
                    </div>
                    <div className="detail">
                      <h5>Delivery Date: </h5>
                      <p>{rowData?.updatedAt?.split("T")[0]}</p> 
                    </div>
                    <div className="detail last-detail">
                      <h5>Delivery Time: </h5>
                      <p className="last-detail">{rowData?.updatedAt?.split("T")[1]?.split(".")[0]}</p>
                    </div>
                  </div>
                </div>
              </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal isOpen={modal_static6} toggle={tog_static6} centered={true}>
          <ModalHeader className=" m_d_popup_header">
              <Container fluid>
                <Row>
                  <Col md={12}>
                    {" "}
                    <p className="mt-3">Export as CSV</p>
                  </Col>
                </Row>
              </Container>
            </ModalHeader>
            <ModalBody>
              <div className="suspend-driver-popup">
                <div className="suspend-driver-btns-wrapper">
                    <Button
                      color="success"
                      outline
                      className="c_o_btns skip_shift_btn"
                    >
                      Export
                    </Button>
                
                    <Button
                      color="success"
                      outline
                      className="c_o_btn1 skip_shift_btn"
                      onClick={() => setModal_static6(false)}
                    >
                      Go Back
                    </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  };
  
  export default CompletedOrders;
  