import React from 'react';
import ReactECharts from 'echarts-for-react';

function MonthlyDataChart({ data }) {
  // Extract the dates and counts from your data
  const dates = data.map(item => item.date);
  const counts = data.map(item => item.count);

   // Create a Date object to get the current month name
   const currentDate = new Date();
   const currentMonthName = new Intl.DateTimeFormat('en', { month: 'long' }).format(currentDate);

  // Create the option for the ECharts chart
  const option = {
    title: {
      text: currentMonthName + " Orders",
    },
    xAxis: {
      type: 'category',
      data: dates,
    },
    yAxis: {
      type: 'value',
      min: 0,    // Set the minimum value for the Y-axis
      max: 1000, // Set the maximum value for the Y-axis
    },
    tooltip: {
      trigger: 'axis',
      formatter: '{b} - {c}',
    },
    series: [
      {
        data: counts,
        type: 'line',
        name: 'Count', // Legend label
        smooth: true, // Smooth line
      },
    ],
  };

  return (
    <div>
      <ReactECharts
        option={option}
        style={{ height: '400px' }}
      />
    </div>
  );
}

export default MonthlyDataChart;
