import React, { useState, useEffect } from 'react'
import {AiOutlineDashboard, AiOutlineLoading} from 'react-icons/ai'
import './css/dashboard.css'
import { useToasts } from "react-toast-notifications";
import { useNavigate } from 'react-router-dom';

import MonthlyDataChart from './chart';

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const { addToast } = useToasts();
  const [data, setData] = useState([])
  const [statsData, setStatsData] = useState([])
  const [loading, setLoading] = useState(false)


  const fetchAnalytics = async () => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/stats/dashboard`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        const resJson = await res.json();
        setLoading(false);
        addToast(resJson.error.message, { appearance: "error" , autoDismiss: true,});
      }
      setLoading(false);
      const resJson = await res.json();
      addToast(resJson.error.message, { appearance: "error" , autoDismiss: true,});
    }
    setLoading(false);
    const resData = await res.json();
    setData(resData?.data);
  }

  const fetchStats = async () => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/stats/admin/dashboard-stats`,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  
    if (!res.ok) {
      if (res.status === 401) {
        setStatsData([]);
      }
      setStatsData([]);
    }
    const resData = await res.json();
    setStatsData(resData?.data);
  }

  useEffect(() => {
    fetchAnalytics();
    fetchStats();
  }, [])

  // const icons = [
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  //   <AiOutlineDashboard color={"#fff"} size={40} />,
  // ]


  function formatNumberToK(n) {
    if (n < 1000) {
        return n.toString();
    } else {
        return (n / 1000).toFixed(1).replace('.0', '') + 'K';
    }
  }

  const navigate = useNavigate();

  return (
    <div>
      <div className="breadcrumb">
        <AiOutlineDashboard color={"#354FAA"}/>
        <span>/</span>
        <p>Dashboard</p>
      </div>

      <div>
      <MonthlyDataChart data={statsData} />
    </div>
  
      <div className="dashboard__cards__wrapper">
        {(loading) && (
            <div className='loader__overlay'>
              <AiOutlineLoading style={{ margin: "auto", textAlign: "center" }} className="loaderIcon" size={50} />
            </div>
        )}
        {
          data.length > 0 && data?.map((d, index) => (
            <div key={index} className='dashboard__Card' onClick={() => navigate(`${d?.redirection}`)}>
              {/* <div className='dashboard__icon'>
                {icons[index]}
              </div> */}
              <div className='dashboard__analytics'>
                <h4>{formatNumberToK(d?.value)}</h4>
                <p>{d?.key}</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Dashboard