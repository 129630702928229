import React, { useEffect, useState } from "react";
import "../css/routePage.css";
import AssignToModal from "../Modals/AssignToModal";
import DriverModal from "../Modals/DriverModal";
import ImageSelect from "../../../components/Dropdown/ImageSelect";
import DataTable from "react-data-table-component";
import Map from "./map.js";
import { useQuery } from "react-query";
import { getRidersFunc } from "../../../ApIs/drivers";
import { getTrucksFunc } from "../../../ApIs/trucks";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { createShipmentFunc } from "../../../ApIs/shipment";
import { useToasts } from "react-toast-notifications";
import breadcrumb_logo from '../../../images/icons/breadcrumb_logo.svg'
import ReportPopup from "../Modals/ReportDetail"
import { Alert } from "reactstrap";
import { useLoadScript } from "@react-google-maps/api";


import axios from "axios"



const api_key = process.env.REACT_APP_PTV_ACCESS_TOKEN



const RoutePage = () => {
  const [assignVehicleModal, setAssignVehicleModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [truckSelectedRow, setTruckSelectedRow] = useState({});
  const [driverSelectedRow, setDriverSelectedRow] = useState({});
  const token = localStorage.getItem("token");
  const [selectedOption, setSelectedOption] = useState({});
  const [diverModal, setDriverModal] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);
  const [pickupLocations, setPickupLocations] = useState([]);
  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [withTollpoints, setWithTollPoints] = useState(null);
  const [withoutTollpoints, setWithoutTollPoints] = useState(null);
  const [withTollEvents, setWithTollEvents] = useState(null);
  const [withoutTollEvents, setWithoutTollEvents] = useState(null);
  const [center, setCenter] = useState(null);
  const [withToll, setWithToll] = useState(null);
  const [withoutToll, setWithoutToll] = useState(null);
  const [allRouteData, setAllRouteData] = useState([]);
  const [createRoute, setCreateRoute] = useState(false);

  const { addToast } = useToasts();


  useEffect(() => {
    if (JSON.parse(localStorage.getItem("modalFlag"))) {
      setAssignVehicleModal(true);
      localStorage.removeItem("modalFlag");
    }
  }, []);


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
  })


  const { mutateAsync: createShipment, isLoading: addShipmentLoading } =
    useMutation((data) => createShipmentFunc(token, data, addToast), {
      onSuccess: (successData) => {
        addToast("Shipment Created Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        localStorage.setItem("shipmentID", successData?.data?.data);
        setReportPopup(true);
        localStorage.removeItem("receiverDetails");
        localStorage.removeItem("pickupDetails");
        localStorage.removeItem("packages");
        localStorage.removeItem("shipmentDetails");
        localStorage.removeItem("routeAssingTo");
        localStorage.removeItem("selectedTruck");
        localStorage.removeItem("shipmentId");
        localStorage.removeItem("selectedDriver");
        localStorage.removeItem("expenses");
      },
    });

  // Get Details From Localstorage
  useEffect(() => {
    const pickupDetails = JSON.parse(localStorage.getItem("pickupDetails"));
    const receiverDetails = JSON.parse(localStorage.getItem("receiverDetails"));
    const packages = JSON.parse(localStorage.getItem("packages"));

    if (isLoaded) {
      setPickupLocations(pickupDetails);
      setDeliveryLocations(receiverDetails);
      setPackages(packages);
      setCenter({ lat: pickupDetails && pickupDetails[0]?.lng, lng: pickupDetails && pickupDetails[0]?.lat })

    }

  }, [isLoaded]);

  const calculateRoute = async () => {

    // Initialize a counter to keep track of completed requests
    let completedRequests = 0;

    // Function to handle the completion of each request
    function handleRequestCompletion() {
      completedRequests++;
      
      // Check if both requests have completed
      if (completedRequests === 2) {
        // Both requests have completed, set createRoute to false
        setCreateRoute(false);
      }
    }


    // Step 1: Create sets of unique pickup and delivery IDs from packages
    const packagePickupIds = new Set(packages.map(packageData => packageData.pickup_id));
    const packageDeliveryIds = new Set(packages.map(packageData => packageData.delivery_id));

    // Step 2 and 3: Filter the pickup and deliveries arrays to remove unreferenced objects
    const filteredPickup = pickupLocations.filter(pickupItem => packagePickupIds.has(pickupItem._id));
    const filteredDeliveries = deliveryLocations.filter(deliveryItem => packageDeliveryIds.has(deliveryItem._id));



      // Create objects to store the combined package weights for each pickup and delivery
      const pickupWeightObj = {}; // Object to store pickup ID and combined weight
      const deliveryWeightObj = {}; // Object to store delivery ID and combined weight

      // Iterate through the packages array and aggregate the weights for pickups and deliveries
      packages?.forEach(packageData => {
        const { pickup_id, delivery_id, package_weight } = packageData;

        // Update pickup weights
        if (pickupWeightObj?.hasOwnProperty(pickup_id)) {
          pickupWeightObj[pickup_id] += package_weight;
        } else {
          pickupWeightObj[pickup_id] = package_weight;
        }

        // Update delivery weights
        if (deliveryWeightObj?.hasOwnProperty(delivery_id)) {
          deliveryWeightObj[delivery_id] += package_weight;
        } else {
          deliveryWeightObj[delivery_id] = package_weight;
        }
      });

      // Update filteredPickup and filteredDeliveries arrays with combined package weights
      filteredPickup?.forEach(item => {
        item.package_weight = pickupWeightObj[item._id];
      });

      filteredDeliveries?.forEach(item => {
        item.package_weight = deliveryWeightObj[item._id];
      });




    const data = [...filteredPickup, ...filteredDeliveries];


    // waypoints array with load weight
    const waypointsArray = data.map(item => ({
      "onRoad": {
          "latitude": item.lat,
          "longitude": item.lng,
          "vehicleParameters": {
              "loadWeight": item?.package_weight ? Number(item?.package_weight) : 0
          }
      }
    }));

  

      setCreateRoute(true);

      setWithoutToll(null);
      setWithToll(null);
    axios.post(`${process.env.REACT_APP_PTV_URL}?vehicle[emptyWeight]=${selectedOption?.weight}&options[avoid]=TOLL&results=POLYLINE,TOLL_COSTS,SCHEDULE_EVENTS&options[trafficMode]=AVERAGE&apiKey=${api_key}`, {waypoints: waypointsArray, driver: {"workingHoursPreset": "EU_DRIVING_TIME_REGULATION_FOR_SINGLE_DAY"}})
      .then((response) => {
        if (response.data && response.status === 200 ) {
          setWithoutToll(response.data);

        }
      })
      .catch((error) => {
    }).finally(() => {
      handleRequestCompletion();
    });

    axios.post(`${process.env.REACT_APP_PTV_URL}?vehicle[emptyWeight]=${selectedOption?.weight}&results=POLYLINE,TOLL_COSTS,SCHEDULE_EVENTS&options[trafficMode]=AVERAGE&apiKey=${api_key}`, {waypoints: waypointsArray, driver: {"workingHoursPreset": "EU_DRIVING_TIME_REGULATION_FOR_SINGLE_DAY"}})
      .then((response) => {
        if (response.data && response.status === 200 && response?.data?.toll?.costs?.convertedPrice.price !== 0) {
          setWithToll(response.data);

        }
      })
      .catch((error) => {
    }).finally(() => {
      handleRequestCompletion();
    });


  }


  // Set table Data
  useEffect(() => {

    const checkIfIdExists = (id) => {
      return allRouteData.some((item) => item._id === id);
    };

    if (withToll) {


      const points = JSON.parse(withToll?.polyline);

      setWithTollPoints(points)
      setWithTollEvents(withToll?.events);

      if (!checkIfIdExists("2")) {
        setAllRouteData([...allRouteData, {
          _id: "2",
          check_box: true,
          route_color: "red",
          visibility: "Route 2",
          distance: withToll?.distance / 1000,
          departure: startTime ? startTime[0].date + " - " + startTime[0].time : "",
          // arrival: "",
          estimated_time: withToll?.travelTime / 3600,
          total_cost: String(Number(withToll?.toll?.costs?.convertedPrice?.price).toFixed(2) + " " + withToll?.toll?.costs?.convertedPrice?.currency),
          toll: true,
          events: withToll?.events ? withToll?.events : []
        }])

      }
    }

    if (withoutToll) {
      const points = JSON.parse(withoutToll?.polyline);

      setWithoutTollPoints(points)
      setWithoutTollEvents(withoutToll?.events);

      if (!checkIfIdExists("1")) {
        setAllRouteData([...allRouteData, {
          _id: "1",
          check_box: true,
          route_color: "green",
          visibility: "Route 1",
          distance: withoutToll?.distance / 1000,
          departure:  startTime ? startTime[0].date + " - " + startTime[0].time : "",
          // arrival: "",
          estimated_time: withoutToll?.travelTime / 3600,
          total_cost: String(Number(withoutToll?.toll?.costs?.convertedPrice?.price).toFixed(2) + " " + withoutToll?.toll?.costs?.convertedPrice?.currency),
          toll: false,
          events: withoutToll?.events ? withoutToll?.events : []
        }])

      }
    }

  }, [withToll, withoutToll])




  useEffect(() => {
    if (selectedOption?.value) {
      localStorage.setItem("selectedTruck", selectedOption?.value);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedRow?._id) {
      localStorage.setItem("routeAssingTo", selectedRow?._id);
    }
  }, [selectedRow]);

  const { data: allTrucks } = useQuery(["trucks"], () => fetchAllTrucks());
  const { data: allDrivers } = useQuery(["alldrivers"], () =>
    fetchAllDrivers()
  );

  const shipmentDetails = JSON.parse(localStorage.getItem("shipmentDetails"));

  const fetchAllTrucks = () => getTrucksFunc(token);

  const fetchAllDrivers = () => getRidersFunc(token);

  const handleRowSelected = (rowId) => {
    setSelectedRow(rowId);
  };

  const startTime = JSON.parse(localStorage.getItem("pickupDetails"))?.sort((a, b) => {
    const dateA = new Date(`${a.date} ${a.time}`);
    const dateB = new Date(`${b.date} ${b.time}`);
    
    return dateA - dateB;
});


function CorrToEncodedPolyline(inputArray) {
  const convertedArray =  inputArray.map(item => {return {'latitude': item.longitude, 'longitude': item.latitude}});
  return convertedArray
  // return polyline.encode(convertedArray)
}

  const assignContractor = () => {
    setDriverModal(false);
    const shipmentDetails = JSON.parse(
      localStorage.getItem("shipmentDetails")
    )?.[0];
    const pickupDetails = JSON.parse(localStorage.getItem("pickupDetails"));
    const receiverDetails = JSON.parse(localStorage.getItem("receiverDetails"));
    const packages = JSON.parse(localStorage.getItem("packages"));
    const routeAssingTo = localStorage.getItem("routeAssingTo");
    const selectedTruck = localStorage.getItem("selectedTruck");




    if (shipmentDetails?.contractor?.id && selectedTruck && routeAssingTo) {

      let customerObject = {
        name: shipmentDetails?.customerObj?.firstName ? shipmentDetails?.customerObj?.firstName + " " + shipmentDetails?.customerObj?.lastName : shipmentDetails?.customer,
        _id: shipmentDetails?.customerObj?._id ? shipmentDetails?.customerObj?._id : shipmentDetails?.customerId,
      }

      delete shipmentDetails?.customer;
      delete shipmentDetails?.customerId;

      createShipment({
        shipmentDetails: {
          ...shipmentDetails,
          assignTo:
            shipmentDetails?.assignTo?.value === "contractor" &&
            shipmentDetails?.contractor?.id,
          customerObj: {...customerObject},
          contractor: {
            name: shipmentDetails?.contractor?.name,
            _id: shipmentDetails?.contractor?.id,
          },
          direction:selectedRow?.route_color === "green" ? withoutTollpoints : withTollpoints,
          
        },
        estimationStartTime: startTime ? startTime[0].date + " - " + startTime[0].time : "",
        estimationTime: selectedRow?.estimated_time?.toFixed(2),
        totalDistance: selectedRow?.distance?.toFixed(2),
        estimationToll: selectedRow?.total_cost,
        pickupDetails,
        receiverDetails,
        packages,
        routeAssingTo,
        selectedTruck,
        events:selectedRow?.route_color === "green" ? withoutToll?.events : withToll?.events
      });
      addToast("Assign to Contractor in background", {
        appearance: "info",
        autoDismiss: true,
      });
    } else {
      !shipmentDetails?.contractor?._id &&
        addToast("please Provide a Contractor", {
          appearance: "error",
          autoDismiss: true,
        });
      !selectedTruck &&
        addToast("please select a Truck", {
          appearance: "error",
          autoDismiss: true,
        });
      !routeAssingTo &&
        addToast("please select a Route", {
          appearance: "error",
          autoDismiss: true,
        });
    }
  };

  const assignDriver = () => {
    setDriverModal(true);
  };

  const [visible, setVisible] = useState(true);

  const columns = [
    {
      name: "Select",
      selector: (row) => (
        <div>
          <input
            type="checkbox"
            checked={selectedRow?._id === row._id}
            onClick={() => handleRowSelected(row)}
          />
        </div>
      ),
    },
    {
      name: "Visibility",
      selector: (row) => (
        <div className="flex_justify">
          <div
            className="route_color"
            style={{
              backgroundColor: `${row?.route_color}`,
              marginRight: "5px",
            }}
          ></div>
          <div>{row.visibility}</div>
        </div>
      ),
    },
    // {
    //   name: "Toll Included",
    //   selector: "toll",
    //   selector: (row) => (


    //     <div>{row.toll ? 'true' : "false"}</div>

    //   ),
    // },
    {
      name: "Distance",
      selector: (row) => (
        <div>
          <div>{row.distance.toFixed(2)} kms</div>
        </div>
      ),
    },
    {
      name: "Departure",
      selector: (row) => row.departure,
    },
    // {
    //   name: "Arrival",
    //   selector: "arrival",
    //   selector: (row) => row.arrival,
    // },
    {
      name: "Estimated Time",
      selector: (row) => (
        <div>
          <div>{row.estimated_time.toFixed(2)} hours</div>
        </div>
      ),
    },
    {
      name: "Total Cost",
      selector: (row) => row.total_cost,
    },
    {
      name: "",
      selector: (row) =>
        selectedRow?._id === row?._id ? (
          <button
            onClick={() => {
              shipmentDetails[0]?.assignTo?.value === "contractor"
                ? assignContractor()
                : assignDriver();
            }}
            className="assign_btn_route"
          >
            Assign
          </button>
        ) : (
          ""
        ),
    },
  ];

  const customStyles = {
      rows: {
        style: {
          minHeight: "58px",
          fontFamily: "Avenir Next",
          fontStyle: "normal",
          fontSize: "11px",
          lineHeight: "106.68%",
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px",
          paddingRight: "8px",
          height: "58px",
          background: "#EFF8FF",
          color: "#1E3A52",
          fontFamily: "Avenir Next",
          fontStyle: "normal",
          fontSize: "14px",
        },
      },
      subHeader: {
        style: {
          background: "#FFFFFF",
          height: "auto",
        },
      },
      subHeaderComponent: {
        background: "#FFFFFF",
        border: "1px solid #A9C7BF",
        borderRadius: "11px",
        height: "42px",
      },
  
      cells: {
        style: {
          paddingLeft: "8px",
          paddingRight: "8px",
        },
    }
  };

  

  return (
    <div className="shipment__main">
      <div className="breadcrumb">
        <img src={breadcrumb_logo} alt="truck-logo" />
        <span>/</span>
        <p>Shipping</p>
      </div>
      {
        createRoute ? (
        <Alert color="info" isOpen={visible}>
            Creating Route, Please Wait.
        </Alert>
 
        ) : ""
      }
      <div className="routepage__container">
        <div className="routepage__header">
          <h4>Plan a Route</h4>
          <div className="routepage__header__right">
            <Link to={'/shipping'}>
              <button className="back__btn">Back</button>
            </Link>
            <div className="dropdown__wrap">
              <ImageSelect
                options={allTrucks?.data?.trucks}
                selectedOption={selectedOption?.value ? selectedOption : null}
                setSelectedOption={setSelectedOption}
              />
            </div>
            {
              selectedOption?.value ? (

                <button className="planroute__btn" onClick={calculateRoute} >Plan Route</button>
              ): ""
            }
          </div>
        </div>

        {
          !pickupLocations?.length &&  !center ? (
            <p>...isLoading</p>
          ) : <div className="routePage__map">
            <Map pickupLocations={pickupLocations} deliveryLocations={deliveryLocations} packages={packages} center={center} points1={withoutTollpoints ? withoutTollpoints : []} points2={withTollpoints ? withTollpoints : []} events1={withoutTollEvents ? withoutTollEvents : []} events2={withTollEvents ? withTollEvents : []} />
          </div>
        }

        {/* <div style={wrapperStyle}>

          <VectorMap apiKey={api_key} pickupLocations={pickupLocations} deliveryLocations={deliveryLocations} center={center}  points1={withoutTollpoints ? withoutTollpoints : {}} points2={withTollpoints ? withTollpoints : {}}/>

        </div> */}
      </div>

      <div className="border-table">
        <div className="table-heading-assign">Route Calculate Result</div>
        <DataTable columns={columns} data={allRouteData} customStyles={customStyles} />
      </div>

      {/* Assign Contratcor Modal */}
      {assignVehicleModal && (
        <AssignToModal
          assignVehicleModal={assignVehicleModal}
          setAssignVehicleModal={setAssignVehicleModal}
          truckSelectedRow={truckSelectedRow}
          setTruckSelectedRow={setTruckSelectedRow}
          setSelectedOption={setSelectedOption}
          data={allTrucks}
        />
      )}
      {/* Driver Modal  */}
      {diverModal && (
        <DriverModal
          DriverModal={diverModal}
          setDriverModal={setDriverModal}
          driverSelectedRow={driverSelectedRow}
          setDriverSelectedRow={setDriverSelectedRow}
          data={allDrivers?.data?.riders}
          directionData={selectedRow?.route_color === "green" ? withoutTollpoints : withTollpoints}
          events={selectedRow?.route_color === "green" ? withoutToll?.events : withToll?.events}
          startTime={startTime ?  startTime[0].date + " - " + startTime[0].time : ""}
          estimationTime = {selectedRow?.estimated_time?.toFixed(2)}
          totalDistance = {selectedRow?.distance?.toFixed(2)}
          estimationToll={selectedRow?.total_cost}
          setReportPopup={() => setReportPopup(true)}
          createShipment={createShipment}
        />
      )}
      {/* {reportPopup && (
        <div className="modalMask">
          <div
            className="modalWrapper-delete"
            style={{ background: "#1BCF85" }}
          >
            <div className="circle" style={{ background: "#10C179" }}>
              <BsCheck2 />
            </div>
            <div className="delete-modal-body">
              <h3 style={{ color: "#fff" }}>AWESOME</h3>
              <p style={{ width: "auto", color: "#fff", margin: "0" }}>
                This Package has been <br />
                assigned successfully.
              </p>
              <img src={generatePdf} alt="" className="pdf__btn" />
              <button
                className="success__btn"
                onClick={() => setReportPopup(false)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )} */}
      <ReportPopup reportPopup={reportPopup} setReportPopup={setReportPopup} />


    </div>
  );
};

export default RoutePage;
